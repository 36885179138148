import { useEffect, useState } from 'react';

import { DB } from '@/stores';

export function useIndexedDBState(key, defaultValue) {
  const [state, setState] = useState(defaultValue);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadState = async () => {
      try {
        const storedState = await DB.getPreference(key, defaultValue);
        setState(storedState);
        setLoading(false);
      } catch (error) {
        console.error('Error loading state from IndexedDB:', error);
        setLoading(false);
      }
    };

    loadState();
  }, [key, defaultValue]);

  const setIndexedDBState = async (value) => {
    try {
      await DB.setPreference(key, value);
      setState(value);
    } catch (error) {
      console.error('Error saving state to IndexedDB:', error);
    }
  };

  return [state, setIndexedDBState, loading];
}
