import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { isMobile } from 'mobile-device-detect';
import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import ActionSheet from '@/components/ActionSheet';
import ImgDecode from '@/components/BarcodeScanModal/ImgDecode/ImgDecode';
import VideoDecode from '@/components/BarcodeScanModal/VideoDecode/VideoDecode';
import BottomSheet from '@/components/BottomSheet';
import HumidorEntryEditor from '@/components/HumidorEntryEditor';
import Icon from '@/components/Icon';
import SessionEditor from '@/components/SmokeSessionEditor';

const submissionMessage = 'What would you like to do with this cigar?';

const BarcodeScanModal = ({
  open,
  closeModal,
  type = 'ean13',
  onResult = () => {},
}) => {
  const [submittedScan, setSubmittedScan] = useState(null);
  const [showHumidorEntryEditor, setShowHumidorEntryEditor] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const actions = [
    {
      content: 'Add to Humidor',
      value: 'add',
      onClick: () => setShowHumidorEntryEditor(true),
    },
    {
      content: 'Smoke Now',
      value: 'smoke',
      onClick: () => setShowSessionModal(true),
    },
  ];

  const renderSessionModal = () => {
    console.log('Rendering session modal for scan:');
    console.log(submittedScan);
    return (
      <SessionEditor
        scan={submittedScan}
        open={showSessionModal}
        toggle={() => setShowSessionModal(!showSessionModal)}
        onClose={() => {
          setSubmittedScan(null);
          setShowSessionModal(false);
          closeModal();
        }}
      />
    );
  };

  const renderHumidorEntryEditor = () => (
    <HumidorEntryEditor
      scan={submittedScan}
      open={showHumidorEntryEditor}
      toggle={() => setShowHumidorEntryEditor(!showHumidorEntryEditor)}
      onClose={() => {
        setSubmittedScan(null);
        setShowHumidorEntryEditor(false);
        closeModal();
      }}
    />
  );

  const renderActions = () => {
    if (isMobile) {
      return (
        <ActionSheet
          title={submissionMessage}
          items={actions}
          open={showActions}
          toggle={() => setShowActions(!showActions)}
        />
      );
    }
    return (
      <Modal
        isOpen={showActions}
        toggle={() => setShowActions(!showActions)}
        // Reset the cigar
        // onClosed={() => setCigar({})}
        style={{ maxWidth: 300 }}
        fade
      >
        <div className="modal-header">
          <Button
            className="close"
            color=""
            onClick={() => setShowActions(false)}
          >
            <Icon name="x" />
          </Button>
        </div>
        <ModalBody>
          <div>{submissionMessage}</div>
          <List>
            {actions.map((action) => (
              <ListItem onClick={action.onClick}>
                <ListItemText primary={action.content} />
              </ListItem>
            ))}
          </List>
        </ModalBody>
      </Modal>
    );
  };

  if (isMobile) {
    return (
      <BottomSheet open={open} showFrom="right" onClose={closeModal}>
        <VideoDecode onResult={onResult} />
        <div
          style={{
            position: 'absolute',
            zIndex: 1002,
            height: '100%',
            width: '100%',
          }}
        >
          <Fab
            size="small"
            color="secondary"
            aria-label="close"
            style={{
              position: 'fixed',
              top: 20,
              left: 20,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            }}
            onClick={closeModal}
          >
            <Icon name="x" style={{ color: 'white' }} />
          </Fab>
          <ImgDecode onResult={onResult} />
          {/* SEE https://stackoverflow.com/questions/14387690/how-can-i-show-only-corner-borders for corners only */}
          <div
            style={{
              position: 'absolute',
              zIndex: 1001,
              top: `calc(50% - ${type === 'qr' ? '45vw' : '10vw'})`,
              left: '5vw',
              width: '90vw',
              height: type === 'qr' ? '90vw' : '20vw',
              border: '4px white solid',
              borderRadius: '20px',
            }}
          />
        </div>
        {renderActions()}
        {renderSessionModal()}
        {renderHumidorEntryEditor()}
      </BottomSheet>
    );
  }
  return (
    <Modal isOpen={open} style={{ maxWidth: 600 }} fade>
      <div className="modal-header">
        <Button className="close" color="" onClick={closeModal}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {/* TODO Upload form or option for webcam? */}
        {
          'Coming soon! Please use a mobile device to use this feature in the meantime.'
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button color="primary">Submit</Button>
      </ModalFooter>
      {renderActions()}
      {renderSessionModal()}
      {renderHumidorEntryEditor()}
    </Modal>
  );
};

export default BarcodeScanModal;
