import './style.scss';

import { IonIcon, IonItem, IonLabel, IonList, IonModal } from '@ionic/react';
import { List, ListItem, ListItemText } from '@mui/material';
import { isIOS, isMobile } from 'mobile-device-detect';
import React, { useEffect } from 'react';
import { Button, Card, Modal, ModalBody, ModalFooter } from 'reactstrap';

import Icon from '@/components/Icon';

function BottomSheet(props) {
  const darkMode = false; // SettingsStore.useState((s) => s.darkMode);
  const iOSColor = darkMode ? '#212529' : '#efefef';
  const androidColor = darkMode ? '#17191d' : '#ffffff';

  useEffect(() => {
    if (props.open) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling
      document.body.style.overflow = '';
    }
  }, [props.open]);

  const renderList = () => (
    <IonList
      lines="full"
      style={{
        marginTop: 0,
        background: isIOS ? 'transparent' : androidColor,
        maxHeight: '80vh',
        overflow: 'scroll',
      }}
    >
      {props.items &&
        props.items.map((item, index) => (
          <IonItem
            key={index}
            onClick={() => {
              item.onClick();
              props.onClose();
            }}
            style={{
              // eslint-disable-next-line no-nested-ternary
              '--background': isIOS ? iOSColor : androidColor,
            }}
          >
            {item.icon && (
              <IonIcon
                aria-hidden="true"
                icon={item.icon}
                slot="start"
                style={{
                  color: item.role === 'danger' ? '#dc3545' : 'inherit',
                }}
              />
            )}
            <IonLabel
              style={{
                marginLeft: 10,
                color: item.role === 'danger' ? '#dc3545' : 'inherit',
              }}
            >
              {item.content}
            </IonLabel>
          </IonItem>
        ))}
    </IonList>
  );

  if (isMobile) {
    return (
      <IonModal
        {...props}
        isOpen={props.open}
        initialBreakpoint={props.initialBreakpoint || 1}
        breakpoints={props.breakpoints || [0, 1]}
        onDidDismiss={props.onClose}
      >
        {props.title && (
          <p style={{ textAlign: 'center', marginTop: 30 }}>{props.title}</p>
        )}
        {/* TODO pinnedItems - see IG more options cards, these are the 3 at the top */}
        {props.items && isIOS && (
          <Card
            style={{
              margin: 16,
              marginTop: props.title ? 0 : 30,
              overflow: 'hidden',
            }}
          >
            {renderList()}
          </Card>
        )}
        {props.items && !isIOS && renderList()}
        {!props.items && props.children}
      </IonModal>
    );
  }
  return (
    <Modal fade isOpen={props.open} toggle={props.toggle} style={props.style}>
      <div className="modal-header" style={{ padding: 12 }}>
        <h3>{props.title || ''}</h3>
        <Button
          className="close"
          color=""
          onClick={props.toggle || props.onClose}
        >
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody style={{ padding: 4 }}>
        {props.items && (
          <List>
            {props.items.map((item) => (
              <ListItem
                key={item.id}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (typeof item.onClick === 'function') {
                    item.onClick();
                  }
                  if (typeof props.toggle === 'function') {
                    props.toggle();
                  }
                }}
              >
                {item.icon && typeof item.icon === 'string' && (
                  <Icon name={item.icon} style={{ marginRight: 12 }} />
                )}
                {item.icon && typeof item.icon !== 'string' && item.icon}
                <ListItemText primary={item.content} />
              </ListItem>
            ))}
          </List>
        )}
        {!props.items && props.children}
      </ModalBody>
      {props.footer || (
        <ModalFooter style={{ padding: 12 }}>
          <Button color="secondary" onClick={props.toggle || props.onClose}>
            {'Close'}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}

export default BottomSheet;
