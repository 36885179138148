import '../../../dynamsoft.config';
import './ImgDecode.css';

import { Fab } from '@mui/material';
import type { BarcodeResultItem } from 'dynamsoft-barcode-reader';
import { CaptureVisionRouter } from 'dynamsoft-capture-vision-router';
import { EnumCapturedResultItemType } from 'dynamsoft-core';
import type { ChangeEvent } from 'react';
import React from 'react';

import Icon from '@/components/Icon';

class ImageCapture extends React.Component {
  pCvRouter: Promise<CaptureVisionRouter> | null = null;

  isDestroyed = false;

  elRef: React.RefObject<HTMLDivElement> = React.createRef();

  async captureImage(e: ChangeEvent<HTMLInputElement>) {
    const files = [...(e.target.files as any as File[])];
    e.target.value = ''; // reset input

    try {
      const cvRouter = await (this.pCvRouter =
        this.pCvRouter || CaptureVisionRouter.createInstance());
      if (this.isDestroyed) return;

      for (const file of files) {
        const result = await cvRouter.capture(file, 'ReadBarcodes_SpeedFirst');
        if (this.isDestroyed) return;

        for (const _item of result.items) {
          if (_item.type !== EnumCapturedResultItemType.CRIT_BARCODE) {
            continue; // check if captured result item is a barcode
          }
          const item = _item as BarcodeResultItem;
          console.log(item.text);
          if (typeof this.props.onResult === 'function') {
            this.props.onResult(item.text);
          }
        }
        if (!result.items.length) {
          alert('No barcode found');
        }
      }
    } catch (ex: any) {
      const errMsg = ex.message || ex;
      console.error(errMsg);
      alert(errMsg);
    }
  }

  async componentWillUnmount() {
    this.isDestroyed = true;
    if (this.pCvRouter) {
      try {
        (await this.pCvRouter).dispose();
      } catch (_) {}
    }
  }

  render() {
    return (
      <Fab
        color="primary"
        aria-label="add"
        className="fab dark"
        style={{
          position: 'fixed',
          bottom: 20,
          left: 40,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          border: '1px white solid',
        }}
        onClick={() => this.elRef.current!.click()}
      >
        <Icon name="image" style={{ height: 20, width: 20, color: 'white' }} />
        <input
          multiple={false}
          ref={this.elRef}
          style={{ display: 'none' }}
          type="file"
          onChange={this.captureImage.bind(this)}
          accept=".jpg,.jpeg,.icon,.gif,.svg,.webp,.png,.bmp"
        />
      </Fab>
    );
  }
}

export default ImageCapture;
