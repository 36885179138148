import './style.scss';

import classnames from 'classnames/dedupe';
import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Icon from '../Icon';

// FIXME Convert this to a functional component

class TouchSpin extends Component {
  onChange(newVal) {
    const { min, max, onChange = () => {} } = this.props;

    newVal = parseFloat(newVal);

    if (typeof min !== 'undefined') {
      newVal = Math.max(min, newVal);
    }
    if (typeof max !== 'undefined') {
      newVal = Math.min(max, newVal);
    }

    onChange(newVal);
  }

  render() {
    const {
      className,
      position,
      min,
      max,
      step = 1,
      value,
      verticalButtons,
      verticalButtonsOverlay,
    } = this.props;

    return (
      <div
        className={classnames(
          'rui-touchspin',
          position ? `rui-touchspin-${position}` : '',
          verticalButtons ? 'rui-touchspin-vertical' : '',
          verticalButtons && verticalButtonsOverlay
            ? 'rui-touchspin-overlay'
            : '',
          className
        )}
      >
        <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
          {!verticalButtons ? (
            <span className="input-group-btn input-group-prepend">
              <Button
                onClick={() => {
                  this.onChange(value - step);
                }}
              >
                <Icon name="minus" size={16} />
              </Button>
            </span>
          ) : (
            ''
          )}
          <input
            className="form-control"
            style={{ textAlign: 'center' }}
            type="number"
            value={value}
            min={min}
            max={max}
            step={step}
            data-touchspin-btn="btn-grey-2"
            onChange={(e) => {
              this.onChange(e.target.value);
            }}
          />
          {!verticalButtons ? (
            <span className="input-group-btn input-group-append">
              <Button
                onClick={() => {
                  this.onChange(value + step);
                }}
              >
                <Icon name="plus" size={16} />
              </Button>
            </span>
          ) : (
            ''
          )}
          {verticalButtons ? (
            <span className="input-group-btn-vertical">
              <Button
                onClick={() => {
                  this.onChange(value + step);
                }}
              >
                <Icon name="plus" size={16} />
              </Button>
              <Button
                onClick={() => {
                  this.onChange(value - step);
                }}
              >
                <Icon name="minus" size={16} />
              </Button>
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default TouchSpin;
