import axios from 'axios';

import type HumidorEntry from '@/models/HumidorEntry';
import { config } from '@/settings';
import { HumidorStore, Storage } from '@/stores';

export const requestHumidors = (userId) => {
  // FIXME If activeHumidor is stored in ls, loading = false?
  axios
    .get(`${config.apiEndPoint}/users/${userId}/humidors`)
    .then((response) => {
      if (response.data && response.data.length) {
        HumidorStore.update((s) => {
          s.humidors[userId] = response.data;
          s.humidors.activeHumidor = response.data[0];
        });
        // TODO Store activeHumidor to ls?
        // TODO Store humidors without contents to ls?
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      HumidorStore.update((s) => {
        s.loading = false;
      });
    });
};

export const mergeHumidorEntry = (entry) => {
  console.log('Merging entry...');
  console.log(entry);
  HumidorStore.update((s) => {
    const humidors = s.humidors[entry.scan.userId || entry.scan.user.id] || [];
    console.log('Existing humidors:');
    console.log(JSON.stringify(humidors));
    const humidorId = entry.humidor_id;
    for (let i = 0; i < humidors.length; i++) {
      const humidor = humidors[i];
      if (humidor.id === humidorId) {
        console.log('Found the correct humidor for the entry!');
        console.log(JSON.stringify(humidor));
        const { contents } = humidor;
        let found = false;
        for (let j = 0; j < contents.length; j++) {
          if (contents[j].id === entry.id) {
            found = true;
            if (entry.qty === 0) {
              // Remove the entry
              humidor.contents.splice(j, 1);
            } else {
              console.log('Found the entry! Replacing it now...');
              // Replace and finish
              contents[j] = entry;
            }
            Storage.setPreference('activeHumidor', humidor);
          }
        }
        if (!found) {
          console.log('Could not find the entry, adding it now...');
          // We reached the end and couldn't find it, add to the top of the list
          humidor.contents.unshift(entry);
        }
        // Updates the cached humidor
        Storage.setPreference('activeHumidor', humidor);
      }
    }
    // s.humidors = humidors;
  });
};

export const addOrUpdateHumidorEntry = async (entry, callback = () => {}) => {
  try {
    if (entry.id) {
      console.log('Updating entry...');
      const response = await axios.put(
        `${config.apiEndPoint}/humidors/${entry.humidor_id}/entries/${entry.id}`,
        entry
      );
      console.log(response);
      mergeHumidorEntry(response.data);
      callback(response.data);
    } else {
      console.log('Creating new entry...');
      const response = await axios.post(
        `${config.apiEndPoint}/humidors/${entry.humidor_id}/entries?return=entry`,
        entry
      );
      console.log(response);
      mergeHumidorEntry(response.data);
      callback(response.data);
    }
  } catch (err) {
    console.log(err);
    callback(entry, err);
  }
};

export const removeHumidorEntry = async (entry) => {
  await addOrUpdateHumidorEntry({
    ...entry,
    qty: 0,
  });
};

export function filterByDateRange(
  entries: HumidorEntry[],
  startDate: string,
  endDate: string
): HumidorEntry[] {
  const start = new Date(startDate);
  const end = new Date(endDate);

  return entries.filter((entry) => {
    const timestamp = new Date(entry.timestamp);
    return timestamp >= start && timestamp <= end;
  });
}

export function filterByBrand(entries: HumidorEntry[], brand: string): HumidorEntry[] {
  if (brand) {
    return entries.filter((entry) => entry.scan.cigar.brand === brand);
  }
  return entries;
}

export function filterByRating(
  entries: HumidorEntry[],
  rating: number
): HumidorEntry[] {
  return entries.filter((entry) => entry.scan.cigar.avg_rating >= rating);
}

export function filterByStrength(
  entries: HumidorEntry[],
  strength: number
): HumidorEntry[] {
  // INFO Strength of 0 indicates that this shouldn't be filtered
  if (strength) {
    console.log('Getting cigars with strength:', strength);
    return entries.filter((entry) => entry.scan.cigar.strength === strength);
  }
  return entries;
}

export function filterByUserId(
  entries: HumidorEntry[],
  userId: number
): HumidorEntry[] {
  if (userId) {
    return entries.filter((entry) => entry.gifted_from_id === userId);
  }
  return entries;
}
