import '../../../dynamsoft.config';
import './VideoDecode.css';

import { CameraEnhancer, CameraView } from 'dynamsoft-camera-enhancer';
import { CaptureVisionRouter } from 'dynamsoft-capture-vision-router';
import { MultiFrameResultCrossFilter } from 'dynamsoft-utility';
import React, { useEffect, useRef, useState } from 'react';

const componentDestroyedErrorMsg = 'VideoCapture Component Destroyed';

let cvRouter;
let cameraEnhancer;
let cameraView;
let initialized = false;

const VideoCapture = (props) => {
  const cameraViewContainer = useRef(null);
  const [isDestroyed, setIsDestroyed] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      if (!initialized) {
        console.log('Initializing Video Capture...');
        initialized = true;
        try {
          cameraView = await CameraView.createInstance(
            // @ts-ignore
            cameraViewContainer.current
          );
          if (isDestroyed) {
            throw Error(componentDestroyedErrorMsg);
          }

          cameraView.setVideoFit('cover');
          cameraView.setScanLaserVisible(false);

          cameraEnhancer = await CameraEnhancer.createInstance(cameraView);
          if (isDestroyed) {
            throw Error(componentDestroyedErrorMsg);
          }

          cvRouter = await CaptureVisionRouter.createInstance();
          if (isDestroyed) {
            throw Error(componentDestroyedErrorMsg);
          }
          cvRouter.setInput(cameraEnhancer);
          cvRouter.addResultReceiver({
            onDecodedBarcodesReceived: (result) => {
              if (!result.barcodeResultItems.length) return;
              console.log(result);
              for (const item of result.barcodeResultItems) {
                if (typeof props.onResult === 'function') {
                  props.onResult(item.text);
                }
              }
            },
          });

          const filter = new MultiFrameResultCrossFilter();
          filter.enableResultCrossVerification('barcode', true);
          filter.enableResultDeduplication('barcode', true);
          await cvRouter.addResultFilter(filter);

          if (isDestroyed) {
            throw Error(componentDestroyedErrorMsg);
          }

          await cameraEnhancer.open();
          if (isDestroyed) {
            throw Error(componentDestroyedErrorMsg);
          }
          await cvRouter.startCapturing('ReadSingleBarcode');
        } catch (ex) {
          // @ts-ignore
          if (ex?.message === componentDestroyedErrorMsg) {
            console.log(componentDestroyedErrorMsg);
          } else {
            // @ts-ignore
            const errMsg = ex.message || ex;
            console.error(errMsg);
            alert(errMsg);
          }
        }
      }
    };

    initialize();

    return () => {
      setIsDestroyed(true);
      try {
        console.log('Destroying VideoCapture Component...');
        cvRouter!.stopCapturing();
        console.log('Stopped capture on cvRouter');
        cameraEnhancer!.close();
        cvRouter!.dispose();
        console.log('Destroyed cvRouter');
        cameraEnhancer!.dispose();
        console.log('Destroyed cameraEnhancer');
        initialized = false;
      } catch (e) {
        console.error(e);
      }
    };
  }, []);

  return (
    <div
      className="component-barcode-scanner"
      style={{ backgroundColor: 'black' }}
    >
      <svg className="dce-bg-camera" viewBox="0 0 2048 1792">
        <path d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"></path>
      </svg>
      <div ref={cameraViewContainer} className="camera-view-container">
        <div className="dce-video-container"></div>
      </div>
    </div>
  );
};

export default VideoCapture;
