import { IonSearchbar as SearchBar } from '@ionic/react';
import { Add, Check } from '@mui/icons-material';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import ModalView from '@/components/ModalView';
import { config } from '@/settings';
import { UserStore } from '@/stores';
import { language, messages } from '@/utils/localeUtils';

const UserModal = ({
  open,
  onClose,
  title,
  multiSelect = false,
  listStyle = {},
  onSelect = () => {},
  rightButtons = [],
  allowCreate,
}) => {
  const user = UserStore.useState((s) => s.user);

  const [query, setQuery] = useState(null);
  const [userWrappers, setUserWrappers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [allSelectedUsers, setAllSelectedUsers] = useState([]);

  const updateResults = () => {
    if (query) {
      axios
        .get(`${config.apiEndPoint}/users/search`, {
          params: {
            q: query,
            limit: 30,
          },
        })
        .then((response) => {
          console.log(response.data);
          setUserWrappers(
            response.data.map((u) => ({
              user: u,
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${config.apiEndPoint}/users/${user?.id}/friends`, {
          params: {
            page: 1,
            limit: 30,
          },
        })
        .then((response) => {
          setUserWrappers(
            response.data.map((u) => ({
              user: u,
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    updateResults();
  }, [query]);

  const handleSelection = (selectedUser) => {
    if (multiSelect) {
      const isSelected = selectedUsers[selectedUser.id];
      const selections = selectedUsers;
      selections[selectedUser.id] = !isSelected;
      setSelectedUsers({ ...selections });
      if (!isSelected) {
        const users = [...allSelectedUsers, selectedUser];
        setAllSelectedUsers(users);
        onSelect(users);
      } else {
        const users = allSelectedUsers;
        let i = users.length;
        while (i--) {
          if (users[i].id === selectedUser.id) {
            users.splice(i, 1);
          }
        }
        setAllSelectedUsers(users);
      }
    } else {
      onSelect(selectedUser);
    }
  };

  return (
    <ModalView
      title={title}
      open={open}
      onClose={onClose}
      rightButtons={rightButtons}
    >
      <SearchBar
        mode="ios"
        placeholder={`${messages[language]?.search_users || 'Search Users'}…`}
        debounce={200}
        onIonInput={(e) => setQuery(e.target.value)}
      />
      {/* FIXME Not sure why the text isn't vertically centered*/}
      <List style={listStyle}>
        {userWrappers.map((userWrapper) => (
          <ListItem
            alignItems="flex-start"
            onClick={() => handleSelection(userWrapper.user)}
          >
            <ListItemAvatar>
              {selectedUsers[userWrapper.user.id] && (
                <Avatar sx={{ bgcolor: '#D3A966', color: '#2b2b2b' }}>
                  <Check />
                </Avatar>
              )}
              {!selectedUsers[userWrapper.user.id] && (
                <Avatar
                  alt={userWrapper.user.full_name}
                  src={userWrapper.user.image_url}
                />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <span style={{ fontSize: '1.2rem' }}>
                  {userWrapper.user.full_name}
                </span>
              }
            />
          </ListItem>
        ))}
        {query && query.length > 0 && allowCreate && (
          <ListItem
            alignItems="flex-start"
            onClick={() => alert('Coming soon!')}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: '#D3A966', color: '#2b2b2b' }}>
                <Add />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <span style={{ fontSize: '1.2rem' }}>{`Add "${query}"`}</span>
              }
            />
          </ListItem>
        )}
      </List>
    </ModalView>
  );
};

export default UserModal;
