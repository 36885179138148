import { IonSearchbar as SearchBar } from '@ionic/react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import ModalView from '@/components/ModalView';
import Drink from '@/models/Drink';
import { config } from '@/settings';
import { language, messages } from '@/utils/localeUtils';

const DrinkPairingModal = ({
  title,
  open,
  onClose,
  onSelect,
  listStyle = {},
}) => {
  const [query, setQuery] = useState(null);
  const [drinks, setDrinks] = useState([]);

  const updateResults = () => {
    if (query) {
      axios
        .get(`${config.apiEndPoint}/cigars/pairings/search`, {
          params: {
            q: query,
            limit: 30,
          },
        })
        .then((response) => {
          setDrinks(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } // TODO Popular drink pairings to start?
  };

  useEffect(() => {
    updateResults();
  }, [query]);

  return (
    <ModalView
      title={title}
      open={open}
      onClose={onClose}
      rightButtons={
        [
          // FIXME I may still consider allowing the user to add their own drink pairings
          // {
          //   component: <Icon name="plus" />,
          //   onClick: () => {},
          // },
        ]
      }
    >
      <SearchBar
        mode="ios"
        placeholder={`${
          messages[language]?.search_drinks || 'Search Drink Pairings'
        }…`}
        debounce={200}
        onIonInput={(e) => setQuery(e.target.value)}
      />
      <List style={listStyle}>
        {drinks.map((drink: Drink) => (
          <ListItem alignItems="flex-start" onClick={() => onSelect(drink)}>
            <ListItemAvatar>
              <Avatar alt={drink.name} src={Drink.getImage(drink)}>
                {drink.name.charAt(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={drink.name} />
          </ListItem>
        ))}
      </List>
    </ModalView>
  );
};

export default DrinkPairingModal;
