import { Store } from 'pullstate';

export const Storage = {
  setPreference: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getPreference: (key, defaultValue) => {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key) || '');
    }
    return defaultValue || null;
  },
  removePreference: (key) => {
    localStorage.removeItem(key);
  },
  clearPreferences: () => {
    localStorage.clear();
  },
};

export const DB = (() => {
  const DB_NAME = 'BoxpressdDB';
  const STORE_NAME = 'datastore';
  const DB_VERSION = 1;

  let db;

  const initDB = () => {
    return new Promise((resolve, reject) => {
      if (db) {
        resolve(db);
      } else {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onupgradeneeded = (event) => {
          db = event.target.result;
          if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME, { keyPath: 'key' });
          }
        };

        request.onsuccess = (event) => {
          db = event.target.result;
          resolve(db);
        };

        request.onerror = (event) => {
          reject(`IndexedDB error: ${event.target.errorCode}`);
        };
      }
    });
  };

  const setPreference = async (key, value) => {
    const database = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = database.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.put({ key, value });

      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(`IndexedDB error: ${event.target.errorCode}`);
    });
  };

  const getPreference = async (key, defaultValue) => {
    const database = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = database.transaction([STORE_NAME], 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.get(key);

      request.onsuccess = (event) => {
        resolve(event.target.result ? event.target.result.value : defaultValue);
      };
      request.onerror = (event) => reject(`IndexedDB error: ${event.target.errorCode}`);
    });
  };

  const removePreference = async (key) => {
    const database = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = database.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.delete(key);

      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(`IndexedDB error: ${event.target.errorCode}`);
    });
  };

  const clearPreferences = async () => {
    const database = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = database.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.clear();

      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(`IndexedDB error: ${event.target.errorCode}`);
    });
  };

  return {
    setPreference,
    getPreference,
    removePreference,
    clearPreferences,
  };
})();

const mq = window.matchMedia('(prefers-color-scheme: dark)');
const darkMode = Storage.getPreference('dark_mode', null);

export const UserStore = new Store({
  user: Storage.getPreference('user', null),
  firebaseUser: null,
  token: Storage.getPreference('boxpressd_token_id', null),
});

export const CigarStore = new Store({
  sessions: {
    loading: false,
    liked: [], // FIXME LocalStorage?
    users: {
      loading: false,
    },
    // INFO Arrays of sessions based on cigar.id
  },
  favorites: [],
});

export const AppStore = new Store({
  loading: true,
  lightbox: {
    slide: 0,
    media: [],
    type: 'image',
  },
  toggleLightbox: false,
  showSearchModal: false,
  searchNextAction: null,
  searchHideTabs: false,
  searchHideFilters: false,
  searchVisibleTabs: ['cigars', 'products'],
});

export const SettingsStore = new Store({
  darkMode: false, // FIXME Add this back later - just light mode for now
  // darkMode !== null ? darkMode === 'true' || darkMode === true : mq.matches,
  darkNavbar: false, // FIXME This is honestly just a desktop feature...
  showNavDrawer: false,
  theme: Storage.getPreference('theme', 'System'),
});

export const setDarkMode = (isDarkMode: boolean) => {
  SettingsStore.update((s) => {
    Storage.setPreference('dark_mode', isDarkMode);
    // toggleDarkCSS(isDarkMode);
    s.darkMode = isDarkMode;
  });
};

export const toggleDarkMode = () => {
  SettingsStore.update((s) => {
    setDarkMode(!s.darkMode);
  });
};

export const setGlobalTheme = (theme: string) => {
  SettingsStore.update((s) => {
    Storage.setPreference('theme', theme);
    s.theme = !s.theme;
  });
};

export const HumidorStore = new Store({
  loading: true,
  humidors: {
    activeHumidor: Storage.getPreference('activeHumidor', {}),
    // INFO Humidor should be based on the user ID
  },
});

export const MediaStore = new Store({
  mediaMap: {
    // INFO These are external URLs mapped to local media files to avoid network calls when possible
  },
});

export const SocialStore = new Store({
  profiles: {
    // INFO These are a map of profile IDs for each type
    venues: {},
    brands: {},
    // These are external social accounts linked to a given profile
    accounts: {},
  },
});

export const SearchStore = new Store({
  cigars: null,
  cigarsLoading: false,
  products: null,
  productsLoading: false,
});
