import { IonButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import { List } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import BottomSheet from '@/components/BottomSheet';
import Icon from '@/components/Icon';
import useDialogAlert from '@/components/ModalDialog';
import Placeholder from '@/config/placeholder.config';
import { config } from '@/settings';
import { HumidorStore, UserStore } from '@/stores';
import ErrorLogger from '@/utils/errorLogger';

function HumidorEditor(props) {
  const currentUser = UserStore.useState((s) => s.user);
  const { showDialogAlert } = useDialogAlert();
  const [humidorName, setHumidorName] = useState(null);
  const renderTitle = () =>
    props.humidor ? 'Edit Humidor Details' : 'Add New Humidor';

  useEffect(() => {
    setHumidorName(props.humidor ? props.humidor.name : null);
  }, [props.humidor]);

  const onSave = () => {
    console.log('Saving humidor...');
    if (props.humidor && props.humidor.id) {
      const updatedHumidor = { ...props.humidor };
      updatedHumidor.name = humidorName;
      axios
        .put(
          `${config.apiEndPoint}/humidors/${updatedHumidor.id}`,
          updatedHumidor
        )
        .then((humidor) => {
          console.log('Successfully updated humidor');
          console.log(humidor.data);
          HumidorStore.update((s) => {
            if (s.humidors && s.humidors.length > 0) {
              for (let i = 0; i < s.humidors.length; i++) {
                if (s.humidors[i].id === humidor.data.id) {
                  s.humidors[i] = humidor.data;
                }
              }
            }
          });
          if (typeof props.onClose === 'function') {
            props.onClose(humidor.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`${config.apiEndPoint}/humidors`, {
          name: humidorName,
          is_default: props.humidors ? props.humidors.length === 0 : true,
          user: currentUser,
        })
        .then((response) => {
          console.log('Successfully created humidor');
          console.log(response.data);
          HumidorStore.update((s) => {
            if (!s.humidors) {
              s.humidors = [];
            }
            s.humidors.unshift(response.data);
          });
          if (typeof props.onClose === 'function') {
            props.onClose(response.data);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 304) {
            showDialogAlert({
              title: 'Unable to create humidor',
              message:
                'A virtual humidor with this name already exists. Please enter a new name.',
              buttons: [
                {
                  label: 'Dismiss',
                  role: 'cancel',
                },
              ],
            });
          } else {
            console.log(err);
            ErrorLogger.captureException(err);
            showDialogAlert({
              title: 'Unable to create humidor',
              message:
                'A problem occurred while trying to create the humidor. Please try again later or contact support.',
              buttons: [
                {
                  label: 'Dismiss',
                  role: 'cancel',
                },
              ],
            });
          }
        });
    }
  };

  const onDelete = () => {
    axios
      .delete(`${config.apiEndPoint}/humidors/${props.humidor.id}`)
      .catch((err) => ErrorLogger.captureException(err));
  };

  const renderContent = () => (
    <div style={{ display: 'flex' }}>
      {/* TODO Overlay with + or edit icon depending on props.humidor - allow the image to be modified here */}
      <Avatar
        style={{ marginRight: 10 }}
        alt={props.humidor ? props.humidor.name : 'New Humidor'}
        src={
          props.humidor
            ? props.humidor.image_url || Placeholder.covers.humidor
            : Placeholder.covers.humidor
        }
      />
      <input
        placeholder="Humidor name"
        className="form-control"
        value={humidorName}
        onChange={(event) => setHumidorName(event.target.value)}
      />
    </div>
  );

  if (isMobile) {
    return (
      <BottomSheet
        open={props.show}
        onClose={props.onClose}
        initialBreakpoint={0.25}
        breakpoints={[0, 0.25, 0.5]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
          <IonToolbar mode="ios" className="modal-toolbar">
            <IonButtons slot="secondary">
              <IonButton onClick={props.onClose} color="dark">
                {'Cancel'}
              </IonButton>
            </IonButtons>
            <IonTitle>Humidors</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={props.onSave || onSave} color="brand">
                {'Save'}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <div style={{ padding: 20 }}>{renderContent()}</div>
          {props.humidor && !props.humidor.is_default && (
            <Button color="error" onClick={props.onDelete || onDelete}>
              {'Delete'}
            </Button>
          )}
        </div>
      </BottomSheet>
    );
  }

  return (
    <Modal
      isOpen={props.show}
      toggle={props.toggleOpen}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        {renderTitle()}
        <Button className="close" color="" onClick={props.onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>{renderContent()}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button color="brand" onClick={props.onSave || onSave}>
          Save
        </Button>
        {props.humidor && !props.humidor.is_default && (
          <Button color="danger" onClick={props.onDelete || onDelete}>
            {'Delete'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default HumidorEditor;
