import './style.scss';

import { IonButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import { List } from '@mui/material';
import { isMobile } from 'mobile-device-detect';
import React, { useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import BottomSheet from '@/components/BottomSheet';
import Icon from '@/components/Icon';
import { language, messages } from '@/utils/localeUtils';

export default function BoxCodeEntrySheet(props) {
  const [boxCode, setBoxCode] = useState(props.boxCode);

  const renderButtons = () => (
    <div>
      <Button
        color="secondary"
        onClick={props.onClose}
        style={{ marginRight: 10 }}
      >
        {messages[language]?.cancel || 'Cancel'}
      </Button>
      <Button color="primary" onClick={() => props.onApply(boxCode)}>
        {messages[language]?.apply || 'Apply'}
      </Button>
    </div>
  );

  const renderInnerContent = () => (
    <div>
      <div>
        <InputGroup>
          <Input
            autoFocus
            placeholder="Ex.: SOL ABR 11, EEO CCUA, etc"
            value={boxCode}
            onChange={(e) => setBoxCode(e.target.value)}
          />
        </InputGroup>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <BottomSheet
        className="auto-height-sheet"
        open={props.open}
        onClose={props.onClose}
      >
        <List>
          <IonToolbar mode="ios" className="modal-toolbar">
            <IonTitle>Enter Box Date Code</IonTitle>
            <IonButtons slot="secondary">
              <IonButton onClick={props.onClose}>
                {messages[language]?.cancel || 'cancel'}
              </IonButton>
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton onClick={() => props.onApply(boxCode)}>
                {messages[language]?.apply || 'Apply'}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <div className="block" style={{ padding: 24 }}>
            {renderInnerContent()}
          </div>
        </List>
      </BottomSheet>
    );
  }
  return (
    <Modal
      isOpen={props.open}
      toggle={props.onClose}
      style={{ maxWidth: 320 }}
      fade
    >
      <div className="modal-header" style={{ padding: 12 }}>
        <h3>{'Enter Box Date Code'}</h3>
        <Button className="close" color="" onClick={props.onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody style={{ padding: 16 }}>{renderInnerContent()}</ModalBody>
      <ModalFooter style={{ padding: 12 }}>{renderButtons()}</ModalFooter>
    </Modal>
  );
}
