export default class Venue {
  constructor(item) {

  }

  static getImage(venue) {
    let imageUrl = null; // Placeholder.venue;
    if (venue.profile_image_url) {
      if (venue.profile_image_url.indexOf('http') === -1) {
        imageUrl = `https://${venue.profile_image_url}`;
      } else {
        imageUrl = venue.profile_image_url;
      }
    } else if (venue.images) {
      for (let i = 0; i < venue.images.length; i++) {
        if (venue.images[i].external_id_type === 'google') {
          imageUrl = venue.images[i].url.replace('/venues/', '/100x100/venues/');
          break;
        }
      }
    }
    return imageUrl;
  }
}
