import './style.scss';

import { IonFooter } from '@ionic/react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isIOS, isMobileOnly } from 'mobile-device-detect';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from '@/components/Icon';
import { isStandalone } from '@/utils/useStandalone';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#d3a966',
      dark: '#d3a966',
    },
    secondary: {
      main: '#17191d',
      dark: '#17191d',
      contrastText: '#efefef',
    },
  },
});

const PageTabs = () => {
  console.log('Pathname: ', window.location.pathname.split('/')[1]);
  const navigate = useNavigate();
  const [value, setValue] = useState('humidor');

  useEffect(() => {
    setValue(
      window.location.pathname !== '/'
        ? window.location.pathname.split('/')[1]
        : 'humidor'
    );
  }, [window.location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <IonFooter
      style={{
        minHeight: 44,
        // FIXME Not sure why I still can't get safe area bottom inset working for the bottom padding, this is a temp fix
        paddingBottom: isStandalone && isMobileOnly && isIOS ? 24 : 0,
      }}
    >
      <ThemeProvider theme={theme}>
        <BottomNavigation showLabels value={value} onChange={handleChange}>
          <BottomNavigationAction
            label="Humidor"
            value="humidor"
            icon={<Icon name="inbox" />}
            onClick={() => navigate('/')}
          />
          <BottomNavigationAction
            label="Journal"
            value="journal"
            icon={<Icon name="file-text" />}
            onClick={() => navigate('/journal')}
          />
          <BottomNavigationAction
            label="Settings"
            value="settings"
            icon={<Icon name="settings" />}
            onClick={() => navigate('/settings')}
          />
        </BottomNavigation>
      </ThemeProvider>
    </IonFooter>
  );
};

export default PageTabs;
