import { config } from '@/settings';

function redirect(
  url = window.location.href,
  replace = false,
  authPath = 'login'
) {
  console.log(url);
  console.log(replace);
  console.log(authPath);
  const currentDomain = `${window.location.protocol}//${window.location.host}`;
  const path = url.replace(currentDomain, '');
  const redirectUrl = `${currentDomain}/sso?route=${path}`;
  const location = `${config.authEndPoint}/${authPath}?path=${encodeURI(
    redirectUrl
  )}`;
  // FIXME The scenario I see this useful is only on successful login and the user wants to go back - they'd land on the same page.
  //  More often, I would see users going back from login instead of after they authenticate, so that logic seems better
  //  If it redirects by default, the user will go to a different page or possibly no page at all if it was top of the stack
  if (replace) {
    window.location.replace(location);
  } else {
    window.location.href = location;
  }
}

export function redirectAuth(
  url: string | undefined = undefined,
  replace = false
) {
  redirect(url, replace, 'register');
}

export function login(url: string | undefined = undefined, replace = false) {
  redirect(url, replace, 'login');
}

export function register(url: string | undefined = undefined, replace = false) {
  redirect(url, replace, 'register');
}

export function redirectSignOut(replace = false) {
  console.log(replace);
  const location = `${config.authEndPoint}/login?action=sign_out`;
  console.log(location);
  if (replace) {
    window.location.replace(location);
  } else {
    window.location.href = location;
  }
}
