import axios from 'axios';

import type Session from '@/models/Session';
import { config } from '@/settings';
import { UserStore } from '@/stores';

export async function requestCigarSessions(
  cigarId: number,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  page = 1,
  callback: (s: Session[]) => void
) {
  // CigarStore.update((s) => { s.sessions.loading = true; });
  const response = await axios.get(
    `${config.apiEndPoint}/cigars/${cigarId}/sessions`,
    {
      params: {
        page,
      },
    }
  );

  if (callback) {
    callback(response.data);
  }
}

export function loadCigarSessions(cigarId, page = 1) {
  // // TODO Axios call
  // return {
  //   type: LOAD_CIGAR_SESSIONS.FETCH,
  //   cigarId,
  //   page,
  // };
}

export const addOrMergeFeedSession = (session, state) => {
  if (session.scan.cigar) {
    const feedItems = state.feed || [];
    // FIXME Better way to do this?
    for (let i = 0; i < feedItems.length; i++) {
      if (feedItems[i].session) {
        if (parseInt(feedItems[i].session.id) === parseInt(session.id)) {
          console.log('Found! Updating!');
          // eslint-disable-next-line no-param-reassign
          feedItems[i].session = session;
          return;
        }
      }
    }
    console.log('Adding to beginning');
    feedItems.unshift({
      ...session,
      id: -1,
      type: 'session',
      session,
      user: UserStore.getRawState().user,
    });
  }
};

export const addOrMergeSession = (session, state) => {
  console.log('Session to merge:');
  console.log(session);
  if (session.scan.cigar) {
    const cigarId = session.scan.cigar_id;
    const sessions = state.sessions[cigarId];
    if (sessions) {
      // FIXME Better way to do this?
      for (let i = 0; i < sessions.length; i++) {
        if (parseInt(sessions[i].id) === parseInt(session.id)) {
          console.log('Found! Updating!');
          // eslint-disable-next-line no-param-reassign
          sessions[i] = session;
          console.log(sessions);
          return;
        }
      }
      console.log('Adding to beginning of session list');
      sessions.unshift(session);
    } else {
      state.sessions[cigarId] = [session];
    }
  }
};

export const mergeSessions = (sessions, state) => {
  // if (sessions[0].scan.cigar) {
  //   const cigarId = sessions[0].scan.cigar_id;
  //   const existingSessions = state.sessions[cigarId] || [];
  //   state.sessions[cigarId] = array.unionBy(existingSessions, sessions, 'id');
  // }
};

export const removeSession = (session, state) => {
  console.log('removeSession');
  console.log(session);
  const cigarId = session.scan.cigar_id;
  const sessions = state.sessions[cigarId] || [];
  // FIXME Something like sessions = sessions.filter((s) => parseInt(s.id) !== parseInt(session.id))); ?
  for (let i = 0; i < sessions.length; i++) {
    if (parseInt(sessions[i].id) === parseInt(session.id)) {
      console.log('Found! Removing!');
      sessions.splice(i, 1);
      return;
    }
  }
};

export const removeFromFeed = (session, state) => {
  console.log('removeFromFeed');
  console.log(session);
  const feedItems = state.feed || [];
  for (let i = 0; i < feedItems.length; i++) {
    if (feedItems[i].type === 'session') {
      if (parseInt(feedItems[i].session.id) === parseInt(session.id)) {
        console.log('Found! Removing!');
        feedItems.splice(i, 1);
        return;
      }
    }
  }
};
