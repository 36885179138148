import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import PrivateRoute from '@/components/PrivateRoute';
import InstallPage from '@/pages/install';

const routes = [
  {
    path: '/',
    component: React.lazy(() => import('./pages')),
  },
  {
    path: '/install',
    component: React.lazy(() => import('./pages/install')),
  },
  {
    path: '/journal',
    component: React.lazy(() => import('./pages/journal')),
  },
  {
    path: '/journal/:id',
    component: React.lazy(() => import('./pages/journal/[id]')),
  },
  {
    path: '/journal/details',
    component: React.lazy(() => import('./pages/journal/details')),
  },
  {
    path: '/journal/final',
    component: React.lazy(() => import('./pages/journal/final')),
  },
  {
    path: '/journal/notes',
    component: React.lazy(() => import('./pages/journal/notes')),
  },
  {
    path: '/settings',
    component: React.lazy(() => import('./pages/settings')),
  },
  {
    path: '/upgrade',
    component: React.lazy(() => import('./pages/upgrade')),
  },
];

const AppRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="/install" element={<InstallPage />} />
      {routes.map(({ path, component: Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <PrivateRoute>
              <Component />
            </PrivateRoute>
          }
        />
      ))}
    </Routes>
  </Suspense>
);

export default AppRoutes;
