import { Checkbox } from '@mui/material';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from 'reactstrap';

import ActionSheet from '@/components/ActionSheet';
import Icon from '@/components/Icon';
import useDialogAlert from '@/components/ModalDialog';
import ModalView from '@/components/ModalView';
import { config } from '@/settings';

const shapes = [
  'Churchill',
  'Cigarillo',
  'Corona',
  'Culebra',
  'Double Corona',
  'Double Toro',
  'Double Robusto',
  'Gordo',
  'Lancero',
  'Perfecto',
  'Petite Corona',
  'Robusto',
  'Toro',
  'Toro Gordo',
  'Torpedo',
];
const lengths = [];
const gauges = [];

for (let l = 4.0; l < 8.0; l += 0.5) {
  lengths.push(l);
}
lengths.push('Other');

for (let g = 34; g < 71; g++) {
  gauges.push(g);
}
gauges.push('Other');

const VitolaEditor = forwardRef(({ open, onClose, onSave }, ref) => {
  const { showDialogAlert } = useDialogAlert();

  const [alias, setAlias] = useState('');
  const [shape, setShape] = useState(null);
  const [length, setLength] = useState('');
  const [otherLength, setOtherLength] = useState('');
  const [gauge, setGauge] = useState('');
  const [otherGauge, setOtherGauge] = useState('');
  const [boxPressed, setBoxPressed] = useState(false);
  const [tubo, setTubo] = useState(false);
  const [showShapePicker, setShowShapePicker] = useState(false);

  const [invalidShape, setInvalidShape] = useState(false);
  const [invalidLength, setInvalidLength] = useState(false);
  const [invalidGauge, setInvalidGauge] = useState(false);

  useImperativeHandle(ref, () => ({
    validate(callback) {
      let valid = true;
      if (!shape) {
        setInvalidShape(true);
        valid = false;
      } else {
        setInvalidShape(false);
      }
      if (!length || !length.length) {
        setInvalidLength(true);
        valid = false;
      } else {
        setInvalidLength(false);
      }
      if (!gauge || !gauge.length) {
        setInvalidGauge(true);
        valid = false;
      } else {
        setInvalidGauge(false);
      }
      if (valid) {
        let aliasDisplay = alias;
        if (boxPressed) {
          aliasDisplay += ' Box-Pressed';
        }
        if (tubo) {
          aliasDisplay += ' Tubo';
        }
        aliasDisplay = aliasDisplay.trim();

        let displayLength = length;
        // console.log('Length:');
        // console.log(`'${length}'`);
        // FIXME Why can't we use === here? Also, can this whole thing be simplified?
        if (length === 'Other') {
          // console.log('Length is other');
          if (otherLength.indexOf('/') !== -1) {
            // console.log('Other length is:');
            // console.log(otherLength);
            const value = otherLength.replace('-', ' ');
            const parts = value.split(' ');
            const int = parts[0];
            const decimalParts = parts[1].split('/');
            const decimal =
              parseInt(decimalParts[0]) / parseInt(decimalParts[1]);
            // console.log(decimal);
            const finalValue = parseInt(int) + parseFloat(decimal);
            // console.log(finalValue);
            displayLength = finalValue.toFixed(1);
          } else {
            displayLength = otherLength;
          }
        }

        let displayGauge = gauge;
        if (gauge === 'Other') {
          displayGauge = otherGauge;
        }

        const size = `${displayLength}"x${displayGauge}`;
        console.log('Vitola size:');
        console.log(size);

        callback({
          alias: aliasDisplay && aliasDisplay.length > 0 ? aliasDisplay : null,
          shape: shape.value,
          size,
        });
      } else {
        callback(false);
      }
    },
  }));

  const loadShapes = (searchTerm, callback) => {
    console.log(`${config.apiEndPoint}/cigars/shapes?q=${searchTerm}`);
    return axios
      .get(`${config.apiEndPoint}/cigars/shapes?q=${searchTerm}`)
      .then((res) => {
        console.log(res.data);
        callback(
          res.data.map((s) => ({
            value: s,
            label: s,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createChip = (id, label, selected, handleSelect) => (
    <Chip
      id={id}
      label={label}
      color={selected ? 'secondary' : 'default'}
      style={{ margin: 8 }}
      onClick={handleSelect}
    />
  );

  const createLengthChipSet = () => (
    <div>
      {lengths.map((l) => {
        if (l !== 'Other') {
          return createChip(l, `${l}"`, length === l, () => setLength(l));
        }
        return createChip(l, l, length === l, () => setLength(l));
      })}
    </div>
  );

  const createGaugeChipSet = () => (
    <div>
      {gauges.map((g) => {
        if (g !== 'Other') {
          return createChip(g, `${g}`, gauge === g, () => setGauge(g));
        }
        return createChip(g, g, gauge === g, () => setGauge(g));
      })}
    </div>
  );

  const showAliasHelpModal = () => {
    showDialogAlert({
      message: (
        <div>
          {/* TODO Link a "learn more" button to an article on our blog */}
          <div>
            The "alias" is a nickname brands often give to their shape and size
            combinations.
          </div>
          <div style={{ marginTop: 10, marginBottom: 10 }}>For example:</div>
          <ul>
            <li>
              Montecristo{' '}
              <span style={{ fontWeight: 700, color: '#28a745' }}>No. 2</span>{' '}
              (Torpedo - 6 1/8"x52)
            </li>
            <li>
              Diesel Unlimited{' '}
              <span style={{ fontWeight: 700, color: '#28a745' }}>d.5</span>{' '}
              (Robusto - 5.5"x54)
            </li>
            <li>
              Ave Maria Divinia{' '}
              <span style={{ fontWeight: 700, color: '#28a745' }}>
                Morning Star
              </span>{' '}
              (Perfecto - 5.0”x58)
            </li>
            <li>
              CAO Flathead{' '}
              <span style={{ fontWeight: 700, color: '#28a745' }}>
                V660 Carb
              </span>{' '}
              (Gordo - 6.0"x60)
            </li>
          </ul>
        </div>
      ),
    });
  };

  return (
    <ModalView
      title={'Add Vitola'}
      open={open}
      onClose={onClose}
      rightButtons={[
        {
          title: 'Save',
          onClick: () => {
            alert('Coming back soon!');
            // FIXME Code from main app
            if (typeof onSave === 'function') {
              onSave();
            }
          },
        },
      ]}
    >
      <div style={{ margin: 16 }}>
        <div style={{ paddingTop: 10 }}>
          <div style={{ marginBottom: 10 }}>
            <span>Alias (Optional)</span>
            <Icon
              name="help-circle"
              onClick={showAliasHelpModal}
              style={{ marginLeft: 10 }}
            />
          </div>
          <input
            name="alias"
            className="form-input form-control"
            type="text"
            value={alias}
            style={{ textTransform: 'capitalize' }}
            placeholder="Examples: No. 2, d.5, Morning Star"
            onChange={(e) => setAlias(e.target.value)}
          />
        </div>
        <div style={{ paddingTop: 10 }}>
          <div style={{ marginBottom: 10 }}>
            <span style={{ color: invalidShape ? '#ef5164' : 'inherit' }}>
              Shape
            </span>
          </div>
          <Button
            outline
            className="form-control"
            onClick={() => setShowShapePicker(true)}
          >
            {shape || 'Select Shape'}
          </Button>
        </div>
        <div style={{ paddingTop: 10 }}>
          <span
            style={{
              color: invalidLength ? '#ef5164' : 'inherit',
              marginBottom: 10,
            }}
          >
            Length
          </span>
          {createLengthChipSet()}
          {length === 'Other' && (
            <input
              value={otherLength}
              className="form-control"
              placeholder="Example: 5.4 or 5 3/8"
              onChange={(e) => setOtherLength(e.target.value)}
            />
          )}
        </div>
        <div style={{ paddingTop: 10 }}>
          <span
            style={{
              color: invalidGauge ? '#ef5164' : 'inherit',
              marginBottom: 10,
            }}
          >
            Gauge
          </span>
          {createGaugeChipSet()}
          {gauge === 'Other' && (
            <input
              value={otherGauge}
              className="form-control"
              placeholder="Example: 80"
              onChange={(e) => setOtherGauge(e.target.value)}
            />
          )}
        </div>

        <div>
          <FormControlLabel
            control={<Checkbox checked={boxPressed} />}
            onChange={(e) => setBoxPressed(e.target.checked)}
            label="Box-Pressed"
          />
        </div>

        <div>
          <FormControlLabel
            control={<Checkbox checked={tubo} />}
            onChange={(e) => setTubo(e.target.checked)}
            label="Tubo"
          />
        </div>
      </div>
      <ActionSheet
        title="Shape"
        items={shapes.map((value) => ({
          content: value,
          onClick: () => {
            setShape(value);
            setShowShapePicker(false);
          },
        }))}
        open={showShapePicker}
        toggle={() => setShowShapePicker(false)}
      />
    </ModalView>
  );
});

export default VitolaEditor;
