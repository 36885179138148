import './marketplace-listing/style.css';

import React, { useEffect, useRef } from 'react';

import { useExternalScript } from '@/utils/useExternalScript';

function InstallPrompt() {
  const externalScript =
    'https://cdn.boxpressd.com/lib/boxpressd-marketplace.js';
  const state = useExternalScript(externalScript);
  const ref = useRef();
  useEffect(() => {
    if (state === 'ready' && typeof window.MarketPlaceListing === 'function') {
      window.MarketPlaceListing(ref.current);
    }
  }, [state]);
  return <div ref={ref} id="boxpressd-listing" />;
}

export default InstallPrompt;
