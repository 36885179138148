import FsLightbox from 'fslightbox-react';
import React from 'react';

import { AppStore } from '@/stores';

const Lightbox = () => {
  const lightbox = AppStore.useState((s) => s.lightbox);
  const { media, slide, type } = lightbox;
  const toggleLightbox = AppStore.useState((s) => s.toggleLightbox);

  console.log('Lightbox media:');
  console.log(media);

  if (!media || !media.length) {
    return null;
  }

  return (
    <FsLightbox
      toggler={toggleLightbox}
      sources={media.map((m) => m.src)}
      // sources={['https://dev-cdn.boxpressd.io/sessions/xYRdGz7KbDzO-dc6b01c9-5dab-420d-a3a5-cbb1ab11d986.jpeg']}
      // FIXME Getting errors here again - get this working
      slide={slide}
      // type={type || 'image'}
      type={'image'}
      // types={media.map((m) => m.type || 'image')}
      types={['image']}
    />
  );
};

export const openLightboxOnSlide = (
  media,
  type: 'image' | 'video',
  number: number
) => {
  console.log('Showing lightbox on slide', media, type, number);
  AppStore.update((s) => {
    s.lightbox = {
      slide: number,
      media,
      type,
    };
    s.toggleLightbox = !s.toggleLightbox;
  });
};

export default Lightbox;
