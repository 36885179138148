import { IonSearchbar as SearchBar } from '@ionic/react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';

import ModalView from '@/components/ModalView';
import Venue from '@/models/Venue';
import { config } from '@/settings';
import { useLocation } from '@/utils/hooks/useLocation';
import { language, messages } from '@/utils/localeUtils';

const VenueModal = ({
  open,
  onClose,
  title,
  smokeOnly = null,
  buyOnly = null,
  listStyle = {},
  onSelect = () => {},
}) => {
  const [query, setQuery] = useState(null);
  const coordinates = useLocation();
  const [venues, setVenues] = useState([]);
  const [showVenueModal, setShowVenueModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const updateResults = () => {
    if (query && query.length > 0) {
      axios
        .get(`${config.apiEndPoint}/venues/search`, {
          params: {
            q: query,
            limit: 30,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
            smokeShopsOnly: true, // TODO Use a prop to determine this
          },
        })
        .then((response) => {
          setLoading(false);
          setVenues(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${config.apiEndPoint}/venues/nearby`, {
          params: {
            limit: 30,
            sort: 'distance',
            radius: 40000, // Can probably decrease this - this is really meant for if they're in the place
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
            buy: smokeOnly ? null : buyOnly,
            smoke: buyOnly ? null : smokeOnly,
          },
        })
        .then((response) => {
          setLoading(false);
          setVenues(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if ((coordinates.latitude && coordinates.longitude) || query) {
      updateResults();
    }
  }, [query, coordinates]);

  return (
    <ModalView title={title} open={open} onClose={onClose}>
      <SearchBar
        mode="ios"
        placeholder={
          buyOnly
            ? `${messages[language]?.search_shops || 'Search Shops'}…`
            : `${messages[language]?.search_venues || 'Search Venues'}…`
        }
        debounce={200}
        onIonInput={(e) => setQuery(e.target.value)}
      />
      <List style={{ listStyle }}>
        {loading && (
          <ListItem>
            <Skeleton variant="circular" style={{ height: 40, width: 40 }} />
            <div style={{ width: '100%', marginLeft: 12 }}>
              <Skeleton />
            </div>
          </ListItem>
        )}
        {venues.map((venue) => (
          <ListItem alignItems="flex-start" onClick={() => onSelect(venue)}>
            <ListItemAvatar>
              <Avatar alt={venue.name} src={Venue.getImage(venue)}>
                {venue.name.charAt(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={venue.name}
              secondary={
                <>
                  {venue.location && venue.location.formatted_address
                    ? venue.location.formatted_address
                    : venue.website}
                </>
              }
            />
          </ListItem>
        ))}
        {(!venues || venues.length === 0) && !loading && (
          <ListItem
            key="venue-item-not-found"
            onClick={() => {
              setShowVenueModal(true);
            }}
          >
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                padding: 12,
                cursor: 'pointer',
              }}
            >
              <span>
                {"Can't find the venue?"}
                {isMobile ? (
                  <button
                    style={{ marginLeft: 10 }}
                    className="btn btn-outline-grey"
                  >
                    Add New Venue
                  </button>
                ) : (
                  <span style={{ color: '#d6c290', marginLeft: 8 }}>
                    Click here to add
                  </span>
                )}
              </span>
            </div>
          </ListItem>
        )}
      </List>
      {/* <VenueEditor */}
      {/*  // venue={selectedVenue} */}
      {/*  open={showVenueModal} */}
      {/*  toggle={() => setShowVenueModal(!showVenueModal)} */}
      {/*  onClose={() => setShowVenueModal(false)} */}
      {/*  onSaved={(venue) => onSelect(venue)} */}
      {/* /> */}
    </ModalView>
  );
};

export default VenueModal;
