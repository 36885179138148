import { Add } from '@mui/icons-material';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import React from 'react';

import ModalView from '@/components/ModalView';
import Cigar from '@/models/Cigar';

const CigarModal = ({
  open,
  onClose,
  title,
  cigarProducts = [],
  listStyle = {},
  onSelect = (i) => {},
  allowAddAll = false,
}) => {
  return (
    <ModalView title={title} open={open} onClose={onClose}>
      <List style={{ listStyle }}>
        {cigarProducts.map((content) => (
          <ListItem alignItems="flex-start" onClick={() => onSelect(content)}>
            <ListItemAvatar>
              <Avatar
                alt={content.cigar.full_name}
                src={Cigar.getBandImage(content.cigar)}
              >
                {content.cigar.full_name.charAt(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={content.cigar.full_name}
              secondary={<>{content.vitola?.formatted_name}</>}
            />
          </ListItem>
        ))}
        {allowAddAll && (
          <ListItem alignItems="center" onClick={() => alert('Coming soon!')}>
            <ListItemAvatar>
              <Avatar>
                <Add />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Add All ${cigarProducts.length} Cigars`} />
          </ListItem>
        )}
      </List>
    </ModalView>
  );
};

export default CigarModal;
