import Placeholder from '@/config/placeholder.config';

export default class Drink {
  constructor(item) {

  }
}

Drink.getImage = (drink) => {
  if (drink) {
    let imageUrl;
    if (drink.image_url) {
      imageUrl = drink.image_url;
    } else if (drink.image) {
      imageUrl = drink.image;
    }
    if (imageUrl) {
      if (imageUrl.indexOf('ei.isnooth.com') !== -1) {
        imageUrl = imageUrl.replace('ei.isnooth.com/multimedia', 'snooth.boxpressd.io');
      }
      return imageUrl;
    }
  }
  return Placeholder.drink;
};
