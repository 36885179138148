import './style.scss';

import { IonDatetime, IonModal } from '@ionic/react';
import { Chip, InputBase, MenuItem, Select } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { withStyles } from '@mui/styles';
import axios from 'axios';
import Hashids from 'hashids';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import BarcodeEntrySheet from '@/components/BarcodeEntrySheet';
import BarcodeScanModal from '@/components/BarcodeScanModal';
import BoxCodeEntrySheet from '@/components/BoxCodeEntrySheet';
import useEditorDiscardConfirmation from '@/components/EditorDiscardConfirmation';
import FileUploader from '@/components/FileUploader';
import Icon from '@/components/Icon';
import MediaCropper from '@/components/MediaCropper';
import useDialogAlert from '@/components/ModalDialog';
import ModalView from '@/components/ModalView';
import PurchasePriceSheet from '@/components/PurchasePriceSheet';
import TouchSpin from '@/components/TouchSpin';
import UserSearch from '@/components/UserModal';
import LocationSearch from '@/components/VenueModal';
import VitolaEditor from '@/components/VitolaEditor';
import Venue from '@/models/Venue';
import { config } from '@/settings';
import { HumidorStore, UserStore } from '@/stores';
import {
  addOrUpdateHumidorEntry,
  requestHumidors,
} from '@/utils/actions/humidor.actions';
import ErrorLogger from '@/utils/errorLogger';
import { parseDate, renderEditorTimestamp } from '@/utils/formatting';
import { Resize } from '@/utils/imageUtils';
// import LoadingIndicator from '@/components/LoadingIndicator';
import { language, messages } from '@/utils/localeUtils';

import ActionSheet from '../ActionSheet';
import HumidorEditor from '../HumidorEditor';

const hashids = new Hashids('', 12);

const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

const quantityOptions = [
  { value: 1, label: '1' },
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: -1, label: 'Other' },
];

const BootstrapInput = withStyles(() => ({
  root: {
    'label + &': {
      // marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    // backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '10px 26px 10px 12px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#D3A966',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const defaults = {
  selectedMediaFiles: [],
  vitolas: [],
  selectedQuantityOption: 1,
  selectedQuantity: 1,
  selectedHumidor: null,
  selectedVitola: null,
  selectedDate: new Date(),
  selectedPackagePrice: '',
  selectedPackageType: 'Single',
  selectedPackageQuantity: 1,
  selectedPurchaseLocation: null,
  selectedBarcode: null,
  selectedBoxCode: null,
  selectedGiftedFromUser: null,
  selectedFile: null,
  selectedImageUrl: null,
  selectedNotes: null,
};

let menuAnchor;

export function HumidorEntryEditor(props) {
  const user = UserStore.useState((s) => s.user);
  const { showDialogAlert } = useDialogAlert();
  const { showDiscardConfirmation } = useEditorDiscardConfirmation();
  const humidors = HumidorStore.useState((s) => s.humidors[user?.id] || []);
  const activeHumidor = HumidorStore.useState((s) => s.humidors.activeHumidor);
  // TODO The "selected" items here should be part of one `state` object so we can call setState to reset to defaults
  const [vitolas, setVitolas] = useState(defaults.vitolas);
  const [selectedQuantityOption, setSelectedQuantityOption] = useState(
    defaults.selectedQuantityOption
  );
  const [selectedQuantity, setSelectedQuantity] = useState(
    defaults.selectedQuantity
  );
  const [selectedHumidor, setSelectedHumidor] = useState(
    defaults.selectedHumidor
  );
  const [selectedMediaFiles, setSelectedMediaFiles] = useState(
    defaults.selectedMediaFiles
  );
  const [selectedVitola, setSelectedVitola] = useState(defaults.selectedVitola);
  const [showVitolaEditorModal, setShowVitolaEditorModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaults.selectedDate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showPurchasePriceSheet, setShowPurchasePriceSheet] = useState(false);
  const [selectedPackagePrice, setSelectedPackagePrice] = useState(
    defaults.selectedPackagePrice
  );
  const [selectedPackageType, setSelectedPackageType] = useState(
    defaults.selectedPackageType
  );
  const [selectedPackageQuantity, setSelectedPackageQuantity] = useState(
    defaults.selectedPackageQuantity
  );
  const [showPurchaseLocationModal, setShowPurchaseLocationModal] =
    useState(false);
  const [selectedPurchaseLocation, setSelectedPurchaseLocation] = useState(
    defaults.selectedPurchaseLocation
  );
  const [showGiftedFromModal, setShowGiftedFromModal] = useState(false);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  const [showBarcodeOptionsSheet, setShowBarcodeOptionsSheet] = useState(false);
  const [selectedBarcode, setSelectedBarcode] = useState(
    defaults.selectedBarcode
  );
  const [selectedBoxCode, setSelectedBoxCode] = useState(
    defaults.selectedBoxCode
  );
  const [selectedGiftedFromUser, setSelectedGiftedFromUser] = useState(
    defaults.selectedGiftedFromUser
  );
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [showMediaCropper, setShowMediaCropper] = useState(false);
  const [showHumidorEditor, setShowHumidorEditor] = useState(false);
  const [entryImageThumbnail, setEntryImageThumbnail] = useState(null);
  const [selectedFile, setSelectedFile] = useState(defaults.selectedFile);
  const [selectedImageUrl, setSelectedImageUrl] = useState(
    defaults.selectedImageUrl
  );
  const [selectedNotes, setSelectedNotes] = useState(defaults.selectedNotes);
  const [submitting, setSubmitting] = useState(false);
  const [needsSubmitted, setNeedsSubmitted] = useState(false);
  const [showExtra, setShowExtra] = useState(false);

  const [showBarcodeManualEntry, setShowBarcodeManualEntry] = useState(false);
  const [showBoxCodeEntry, setShowBoxCodeEntry] = useState(false);

  const addMedia = useRef(null);
  let vitolaEditor;

  const parentMessageHandler = ({ data }) => {
    if (data.type === 'on_scan_result' && data.result) {
      onBarcodeDetected(data.result);
    }
  };

  const addNativeAppListeners = () => {
    window.addEventListener('message', parentMessageHandler);
  };

  const removeNativeAppListeners = () => {
    window.removeEventListener('message', parentMessageHandler);
  };

  const barcodeOptions = [
    {
      content: 'Enter Barcode Manually',
      value: 'manual',
      onClick: () => {
        setShowBarcodeOptionsSheet(false);
        setShowBarcodeManualEntry(true);
      },
    },
    {
      content: 'Scan Barcode',
      value: 'barcode',
      onClick: () => {
        setShowBarcodeOptionsSheet(false);
        setShowBarcodeScanner(true);
      },
    },
  ];

  useEffect(() => {
    let cigar = { ...props.cigar };
    const { entry } = props;

    // INFO Algolia doesn't return the id - we may want to index it in there
    if (!cigar.id) {
      cigar.id = cigar.objectID;
    }

    addNativeAppListeners();

    if (!cigar) {
      if (entry && entry.scan && entry.scan.cigar) {
        cigar = entry.scan.cigar;
      }
    }

    console.log('Cigar details:');
    console.log(cigar);

    if (cigar) {
      if (cigar.vitolas) {
        setVitolas(cigar.vitolas);
      } else if (cigar.id) {
        axios
          .get(`${config.apiEndPoint}/cigars/${cigar.id}/vitolas`)
          .then((response) => {
            const vitolaList = response.data;
            if (vitolaList) {
              setVitolas(vitolaList);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    return () => {
      removeNativeAppListeners();
    };
  }, []);

  useEffect(() => {
    if (user && user.id) {
      if (activeHumidor) {
        console.log('Setting active humidor...');
        setSelectedHumidor(activeHumidor.id);
      }
      if (!humidors.length) {
        requestHumidors(user.id);
      } else if (!activeHumidor) {
        setSelectedHumidor(humidors[0].id);
      }
    }
  }, [user]);

  useEffect(() => {
    const { entry } = props;
    if (entry) {
      console.log('Loaded humidor entry. Populating fields...');
      console.log(entry);
      setSelectedHumidor(
        entry.humidor_id ||
          (entry.humidor && entry.humidor.id ? entry.humidor.id : null)
      );
      setSelectedVitola(
        entry.vitola_id ||
          (entry.vitola && entry.vitola.id ? entry.vitola.id : null)
      );
      if (
        quantityOptions.map((quantity) => quantity.value).indexOf(entry.qty) !==
        -1
      ) {
        setSelectedQuantityOption(entry.qty);
      } else {
        setSelectedQuantityOption(-1);
      }
      if (entry.image_url) {
        setEntryImageThumbnail(Resize.thumbnail(entry.image_url));
      }
      setSelectedQuantity(entry.qty);
      setSelectedPackagePrice(entry.price);
      setSelectedPackageType(entry.price_type || defaults.selectedPackageType);
      setSelectedPackageQuantity(
        entry.price_qty || defaults.selectedPackageQuantity
      );
      // setSelectedPurchaseLocation(entry.venue);
      setSelectedPurchaseLocation(entry.bought_from);
      setSelectedBarcode(entry.barcode);
      setSelectedBoxCode(entry.box_code);
      setSelectedGiftedFromUser(entry.gifted_from);
      setSelectedImageUrl(entry.image_url);
      setSelectedNotes(entry.notes);
      setSelectedDate(parseDate(entry.timestamp));
    }
  }, [props.entry]);

  useEffect(() => {
    if (user && user.id) {
      if (activeHumidor) {
        setSelectedHumidor(activeHumidor.id);
      } else if (humidors.length) {
        setSelectedHumidor(humidors[0].id);
      }
    }
  }, [humidors]);

  useEffect(() => {
    console.log(`Uploading is ${uploadingMedia ? 'true' : 'false'}`);
    console.log(`Needs submitted is ${needsSubmitted ? 'true' : 'false'}`);
    if (needsSubmitted && !uploadingMedia) {
      console.log('Finished uploading');
      setUploadingMedia(false);
      console.log('Auto saving session from media callback');
      onSave();
    }
  }, [uploadingMedia, needsSubmitted]);

  const formatPackageType = () => {
    if (selectedPackageType === 'Single') {
      return selectedPackageType;
    }
    return `${selectedPackageType} of ${selectedPackageQuantity}`;
  };

  // TODO make this a helper function and take locale into account when formatting
  const formatPrice = (price) => `$${parseFloat(price).toFixed(2)}`;

  const addPurchaseLocation = () => {
    setShowPurchaseLocationModal(true);
    setShowVitolaEditorModal(false);
    setShowGiftedFromModal(false);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  const handleDateChange = (date) => {
    console.log('Selected date:', date);
    setSelectedDate(new Date(date));
    setShowDatePicker(false);
  };

  const addGiftNote = () => {
    setShowGiftedFromModal(true);
    setShowVitolaEditorModal(false);
    setShowPurchaseLocationModal(false);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  const toggleOpen = () => {
    const { toggle } = props;
    if (toggle && typeof toggle === 'function') {
      toggle();
    }
  };

  const resetAndClose = () => {
    const { onClose } = props;
    // FIXME Create a setState like the session editor so we can reset all back to default
    defaults.selectedDate = new Date();
    setSelectedVitola(null);
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const closeModal = (prompt = true) => {
    if (isMobile && prompt) {
      showDiscardConfirmation({
        title: 'Discard Humidor Entry',
        message:
          'Are you sure you want to discard this Humidor Entry? Your changes will be lost.',
        onClick: resetAndClose,
      });
    } else {
      resetAndClose();
    }
  };

  const validateVitola = () => {
    vitolaEditor.validate((results) => {
      if (results) {
        let { cigar } = props;
        const { entry } = props;
        if (!cigar) {
          if (entry && entry.scan && entry.scan.cigar) {
            cigar = entry.scan.cigar;
          }
        }
        // setPendingVitola(results);
        axios
          .post(
            `${config.apiEndPoint}/cigars/${cigar.id}/vitolas?force=false`,
            results
          )
          .then((response) => {
            const { vitola, suggestions } = response.data;

            if (vitola) {
              vitolas.push(vitola); // FIXME This should work more in a Reselect / Redux fashion so it updates globally in case the user goes back and comes here again
              setSelectedVitola(vitola.id);
            }
            setShowVitolaEditorModal(false);
            setShowExtra(false);

            if (suggestions) {
              // FIXME Should let the user choose from here - right now, they have to tap the dropdown again, which is ok but not ideal
              console.log(suggestions);
              // setSuggestedVitolas(suggestions);
              // setShowSuggestedVitolas(true);
            }
          })
          .catch((err) => {
            console.log(err);
            showDialogAlert({
              title: 'Unable to add vitola',
              message:
                'There was a problem adding this vitola. If the problem persists, contact support through the main menu of this app.',
            });
          });
      } else {
        console.log('Invalid vitola options');
      }
    });
  };

  const renderVitolaEditorModal = () => (
    <VitolaEditor
      ref={(ref) => (vitolaEditor = ref)}
      open={showVitolaEditorModal}
      onClose={() => setShowVitolaEditorModal(false)}
    />
  );

  const renderHumidorEditor = () => (
    <HumidorEditor
      show={showHumidorEditor}
      toggle={() => setShowHumidorEditor(!showHumidorEditor)}
      onClose={() => setShowHumidorEditor(false)}
      // FIXME Callback for onCreated? Update the list of humidors and the selection
    />
  );

  const renderEditorContent = () => (
    <div>
      <h5 style={{ marginTop: 10, marginBottom: 10 }}>Humidor</h5>
      <Select
        variant="outlined"
        // className="form-control"
        style={{ width: '100%' }}
        value={selectedHumidor}
        onChange={(event) => {
          if (event.target.value !== 'add-new-humidor') {
            setSelectedHumidor(event.target.value);
          } else {
            setShowHumidorEditor(true);
          }
        }}
        selectedMenuItemStyle={{ color: '#D3A966' }}
        input={<BootstrapInput />}
      >
        {humidors.map((humidor) => (
          <MenuItem key={`humidor-${humidor.id}`} value={humidor.id}>
            {humidor.name}
          </MenuItem>
        ))}
        <MenuItem key="humidor-new" value="add-new-humidor">
          New Humidor...
        </MenuItem>
      </Select>

      {props.cigar && props.cigar.id && (
        <>
          <h5 style={{ marginTop: 10, marginBottom: 10 }}>
            Vitola (Shape & Size)
          </h5>
          <Select
            variant="outlined"
            // className="form-control"
            displayEmpty
            style={{ width: '100%' }}
            value={selectedVitola}
            onChange={(event) => {
              console.log('Selected vitola:');
              console.log(event.target.value);
              if (event.target.value !== 'add-new-vitola') {
                setSelectedVitola(event.target.value);
              } else {
                setShowVitolaEditorModal(true);
                setShowPurchaseLocationModal(false);
                setShowGiftedFromModal(false);
              }
            }}
            // FIXME This broke the handling - any other way to get a placeholder?
            // renderValue={(selected) => {
            //   if (!selected || selected.length === 0) {
            //     return <em>Choose size / shape...</em>;
            //   }
            //   return selected.formatted_name;
            // }}
            selectedMenuItemStyle={{ color: '#D3A966' }}
            input={<BootstrapInput />}
          >
            {/* <MenuItem disabled value=""> */}
            {/*  <em>Choose size / shape...</em> */}
            {/* </MenuItem> */}
            {vitolas.map((vitola) => (
              <MenuItem key={`vitola-${vitola.id}`} value={vitola.id}>
                {vitola.formatted_name}
              </MenuItem>
            ))}
            <MenuItem key="vitola-new" value="add-new-vitola">
              Create New Vitola...
            </MenuItem>
          </Select>
        </>
      )}

      <h5 style={{ marginTop: 30, marginBottom: 10 }}>Quantity</h5>
      <div style={{ textAlign: 'center', marginBottom: 30 }}>
        <ToggleButtonGroup
          value={selectedQuantityOption}
          exclusive
          onChange={(event, value) => {
            console.log(value);
            setSelectedQuantityOption(value);
            if (value !== -1) {
              setSelectedQuantity(value);
            }
          }}
          aria-label="Select Quantity"
          style={{ display: 'block' }}
        >
          {quantityOptions.map((item) => (
            <ToggleButton
              key={`quantity-${item.value}`}
              value={item.value}
              aria-label={item.label}
            >
              {item.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {selectedQuantityOption && selectedQuantityOption === -1 ? (
          <div style={{ marginTop: 20 }}>
            <TouchSpin
              max={100}
              min={1}
              step={1}
              value={selectedQuantity}
              onChange={(value) => {
                setSelectedQuantity(value);
                // this.forceUpdate();
              }}
            />
          </div>
        ) : null}
      </div>

      <h5 style={{ marginTop: 10, marginBottom: 10 }}>
        Date Purchased / Added To Humidor
      </h5>
      {renderDatePicker()}

      <div
        className="humidor-entry-options"
        style={{
          marginTop: 30,
          marginBottom: 10,
          paddingBottom: 10,
          borderBottom: '1px solid #efefef',
        }}
      >
        {/* TODO Add remaining choices from options below */}
        {selectedPurchaseLocation && renderPurchaseLocation()}
        {!selectedPurchaseLocation && selectedPackagePrice && (
          <div>
            <div style={{ display: 'flex', marginTop: 5 }}>
              {`$${selectedPackagePrice} / ${formatPackageType()}`}
            </div>
          </div>
        )}
      </div>

      {entryImageThumbnail && (
        <div
          className="selected-session-media"
          style={{
            marginBottom: 10,
            paddingBottom: 10,
            borderBottom: '1px solid #efefef',
          }}
        >
          <Row>
            <Col style={{ maxWidth: 110 }}>
              <Avatar
                src={entryImageThumbnail}
                style={{ width: 80, height: 80 }}
              />
            </Col>
            <Col style={{ paddingTop: 26 }}>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => addMedia.current.click()}
              >
                Replace
              </Button>
              {/* TODO Enable this as an option - needs to be able to handle clearing the reference on the server, tho */}
              {/* <MaterialButton>Remove</MaterialButton> */}
            </Col>
          </Row>
        </div>
      )}

      {selectedGiftedFromUser && (
        <div
          style={{
            display: 'flex',
            marginTop: 5,
          }}
        >
          <Avatar
            src={selectedGiftedFromUser.image_url}
            style={{
              height: 20,
              width: 20,
              marginRight: 10,
            }}
          >
            {selectedGiftedFromUser.full_name.charAt(0)}
          </Avatar>
          <div style={{ flex: 'auto' }}>
            {`Gift from ${selectedGiftedFromUser.full_name}`}
          </div>
        </div>
      )}

      {selectedBarcode && (
        <div style={{ display: 'flex', marginTop: 5 }}>
          <Icon name="barcode" vendor="fa" />
          <div style={{ flex: 'auto' }}>{`Barcode: ${selectedBarcode}`}</div>
        </div>
      )}

      {selectedBoxCode && (
        <div style={{ display: 'flex', marginTop: 5 }}>
          <Icon name="barcode" vendor="fa" />
          <div style={{ flex: 'auto' }}>
            {`Box Date Code: ${selectedBoxCode}`}
          </div>
        </div>
      )}

      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <FileUploader
          id="image-picker"
          multiple={false}
          accept="image/*"
          menuAnchor={menuAnchor}
          forwardedRef={addMedia}
          files={selectedMediaFiles}
          folder="humidors/entries"
          filenameTemplate={() => {
            const userHash = hashids.encode(user.id);
            const cigarHash = hashids.encode(
              props.cigar ? props.cigar.id : new Date().getTime()
            );
            return `${userHash}-${cigarHash}-uuid`;
          }}
          uploading={(isUploading) => {
            // INFO Since we never use the uncropped version, the uploading state depends on the mediacropper result - not this one
            // setUploadingMedia(isUploading);
          }}
          onUploading={(uploadingFile, files) => {
            console.log('Uploading file:');
            console.log(uploadingFile);
            console.log('Uploading files:');
            console.log(files);
            const file = files[files.length - 1];
            setUploadingMedia(uploadingFile);
            setSelectedMediaFiles([file]);
            setSelectedFile(file);
            setShowMediaCropper(true);
          }}
          onUploaded={(uploadedFile, files) => {
            console.log('Uploaded files:');
            console.log(files);
            const file = files[files.length - 1];
            setSelectedMediaFiles([file]);
            setSelectedFile(file);
            setSelectedImageUrl(file.media_url);
            // setUploadingMedia(false);
          }}
        />
        <div>Additional Details</div>
        <Chip
          color="default"
          onClick={(event) => {
            menuAnchor = event.target;
            addMedia.current.click();
          }}
          style={styles.chip}
          icon={<Icon name="image" />}
          label={<span>Photo</span>}
        />
        <Chip
          color="default"
          onClick={addPurchaseLocation}
          style={styles.chip}
          icon={<Icon name="shopping-cart" />}
          label={<span>Purchase Location</span>}
        />
        <Chip
          color="default"
          onClick={() => setShowPurchasePriceSheet(true)}
          style={styles.chip}
          icon={<Icon name="dollar-sign" />}
          label={<span>Price</span>}
        />
        <Chip
          color="default"
          onClick={() => {
            if (isMobile) {
              setShowBarcodeOptionsSheet(true);
            } else {
              showBarcodeManualEntry();
            }
          }}
          style={styles.chip}
          icon={<Icon name={['fas', 'barcode']} vendor="fa" />}
          label={<span>Barcode</span>}
        />
        <Chip
          color="default"
          onClick={addGiftNote}
          style={styles.chip}
          icon={<Icon name="gift" />}
          label={<span>Gift From Friend</span>}
        />
        <Chip
          color="default"
          onClick={() => setShowBoxCodeEntry(true)}
          style={styles.chip}
          icon={<Icon name={['far', 'calendar']} vendor="fa" />}
          label={<span style={{ marginLeft: 8 }}>Box Date Code</span>}
        />
      </div>
      <textarea
        value={selectedNotes}
        placeholder="Additional notes (optional)"
        className="form-control"
        onChange={(event) => setSelectedNotes(event.target.value)}
        style={{ height: 120, backgroundColor: 'white' }}
      />
    </div>
  );

  const renderBarcodeOptionsSheet = () => (
    <ActionSheet
      items={barcodeOptions}
      open={showBarcodeOptionsSheet}
      toggle={() => setShowBarcodeOptionsSheet(false)}
    />
  );

  const renderBarcodeSheet = () => (
    <BarcodeEntrySheet
      open={showBarcodeManualEntry}
      onClose={() => setShowBarcodeManualEntry(false)}
      onChange={(value) => setSelectedBarcode(value)}
    />
  );

  const renderBoxCodeSheet = () => (
    <BoxCodeEntrySheet
      open={showBoxCodeEntry}
      onClose={() => setShowBoxCodeEntry(false)}
      onChange={(value) => setSelectedBoxCode(value)}
    />
  );

  const renderPurchasePriceSheet = () => (
    <PurchasePriceSheet
      open={showPurchasePriceSheet}
      onClose={() => setShowPurchasePriceSheet(false)}
      price={selectedPackagePrice}
      onChangePrice={(e) => setSelectedPackagePrice(e.target.value)}
      packageType={selectedPackageType}
      onChangePackageType={(event, value) => setSelectedPackageType(value)}
      quantity={selectedPackageQuantity}
      onChangeQuantity={(value) => setSelectedPackageQuantity(value)}
      onReset={() => {
        // TODO Clear the package state items to their defaults
        setShowPurchasePriceSheet(false);
      }}
      onApply={() => setShowPurchasePriceSheet(false)}
    />
  );

  const renderPurchaseLocationModal = () => (
    <LocationSearch
      title="Purchase Location"
      open={showPurchaseLocationModal}
      buyOnly
      onClick={() => setShowPurchaseLocationModal(false)}
      onClose={() => setShowPurchaseLocationModal(false)}
      onSelect={(venue) => {
        console.log('Selected venue:');
        console.log(venue);
        setSelectedPurchaseLocation(venue);
        setShowPurchaseLocationModal(false);
        setShowExtra(false);
      }}
    />
  );

  const renderPurchaseLocation = () => (
    <div>
      <div style={{ display: 'flex', marginTop: 5 }}>
        <Avatar
          src={Venue.getImage(selectedPurchaseLocation)}
          style={{ height: 20, width: 20, marginRight: 10 }}
        >
          {selectedPurchaseLocation.name.charAt(0)}
        </Avatar>
        <div style={{ flex: 'auto', maxWidth: '60%', marginRight: 5 }}>
          {`Bought at ${selectedPurchaseLocation.name}`}
        </div>
        <Button
          style={{ marginTop: -5, maxHeight: 35 }}
          onClick={() => setShowPurchasePriceSheet(true)}
        >
          {selectedPackagePrice ? 'Edit Price' : '+ Price'}
        </Button>
      </div>
      {selectedPackagePrice ? (
        <div>
          <span style={{ fontSize: 12, color: '#aeaeae', marginLeft: 30 }}>
            {`for ${formatPrice(
              selectedPackagePrice
            )} / ${formatPackageType()}`}
          </span>
        </div>
      ) : null}
    </div>
  );

  const renderGiftedFromModal = () => (
    <UserSearch
      allowCreate
      title="Who was this from?"
      open={showGiftedFromModal}
      hideAppBarShadow
      onClose={() => setShowGiftedFromModal(false)}
      onSelect={(u) => {
        console.log('Selected gifted from user:');
        console.log(u);
        setSelectedGiftedFromUser(u);
        setShowGiftedFromModal(false);
        setShowExtra(false);
      }}
    />
  );

  // TODO Add the gifted from user
  // const renderGiftedFromUser = () => (
  //
  // );

  const renderMediaCropper = () => (
    <MediaCropper
      circle
      upload
      uploadFolder="humidors/entries"
      imageFiles={selectedMediaFiles}
      file={selectedFile}
      src={selectedFile && selectedFile.src}
      filenameTemplate={() => {
        const userHash = hashids.encode(user.id);
        const cigarHash = hashids.encode(
          props.cigar ? props.cigar.id : new Date().getTime()
        );
        return `${userHash}-${cigarHash}-uuid`;
      }}
      aspectRatio={1}
      open={showMediaCropper}
      toggle={() => setShowMediaCropper(!showMediaCropper)}
      onClose={() => setShowMediaCropper(false)}
      onSave={(croppedCanvas, files) => {
        // console.log('Cropping image...');
        setShowMediaCropper(false);
        console.log('Images after:');
        console.log(files);
        const file = files[files.length - 1];
        setSelectedMediaFiles([file]);
        setEntryImageThumbnail(file.src);
      }}
      onUploading={(filename) => {
        setUploadingMedia(true);
      }}
      onUploaded={(filename, files) => {
        console.log('Uploaded file from cropper!');
        console.log(files);
        const file = files[files.length - 1];
        console.log('File by itself:');
        console.log(file);
        setSelectedMediaFiles([file]);
        setEntryImageThumbnail(file.src);
        setSelectedImageUrl(files[0].media_url);
        console.log('Changing uploading media state to false...');
        setUploadingMedia(false);
      }}
    />
  );

  const onBarcodeDetected = (code, result) => {
    setShowBarcodeScanner(false);
    setSelectedBarcode(code);
  };

  const showErrorModal = () => {
    showDialogAlert({
      title: 'Unable to save humidor entry',
      message:
        'An error occurred while saving your humidor entry. If the problem persists, please contact Help & Support.',
      buttons: [
        {
          label: 'Get Help',
          onClick: () => {
            // ModalDialog.close();
            // document.querySelector('#fc_frame').style.display = 'block';
            // if (window.fcWidget) {
            //   window.fcWidget.open({ name: 'Get Help' });
            // }
            window.open('https://bxpr.sd/contact', '_blank');
          },
        },
        {
          label: 'Dismiss',
          role: 'cancel',
        },
      ],
    });
  };

  const submitHumidorEntry = () => {
    let entry = props.entry || {
      scan: props.scan || {
        user,
        cigar: {
          ...props.cigar,
          id: props.cigar.id || props.cigar.objectID,
        },
      },
    };
    // FIXME Better way to handle this? We don't want to update the entry in PullState, we want a copy of it
    entry = { ...entry };

    console.log('Selected image:');
    console.log(selectedImageUrl);

    entry.image_url = selectedImageUrl;
    entry.humidor_id = selectedHumidor ? parseInt(selectedHumidor) : null;
    // entry.humidor = ...; // TODO If creating one
    entry.vitola_id = selectedVitola;
    // entry.vitola: ...; // TODO If creating one
    entry.qty = selectedQuantity;
    entry.bought_from_id = selectedPurchaseLocation
      ? parseInt(selectedPurchaseLocation.id)
      : null;
    entry.gifted_from_id = selectedGiftedFromUser
      ? parseInt(selectedGiftedFromUser.id)
      : null;
    entry.price = selectedPackagePrice
      ? parseFloat(selectedPackagePrice)
      : null;
    entry.price_type = selectedPackagePrice ? selectedPackageType : null;
    entry.price_qty = selectedPackagePrice ? selectedPackageQuantity : null;
    entry.notes = selectedNotes;
    entry.barcode = selectedBarcode;
    entry.box_code = selectedBoxCode;
    entry.timestamp = selectedDate.toISOString();
    // TODO If this editor was staged from a product and/or order, fill in below
    // entry.product_id = ...;
    // entry.order_id = ...;

    console.log('Saving entry...');
    console.log(entry);

    addOrUpdateHumidorEntry(entry, (result, err) => {
      console.log('Entry callback:');
      console.log(result);
      if (result && !err) {
        console.log(result);
        setTimeout(() => {
          setSubmitting(false);
          if (typeof props.onSave === 'function') {
            props.onSave(result);
          }
          closeModal(false);
        }, 10);
      } else {
        console.log(err);
        ErrorLogger.captureException(err);
        setTimeout(() => {
          setSubmitting(false);
          showErrorModal();
        }, 10);
      }
    });
  };

  const onSave = () => {
    if (!selectedHumidor) {
      setShowHumidorEditor(true);
      return;
    }

    if (!selectedQuantity) {
      showDialogAlert({
        title: 'Missing Information',
        message:
          'Please select how many sticks you are adding under "Quantity".',
        buttons: [
          {
            label: 'Go Back',
            role: 'cancel',
          },
        ],
      });
      return;
    }

    setSubmitting(true);
    if (!uploadingMedia) {
      console.log('Uploading entry...');
      submitHumidorEntry();
    } else {
      console.log('Media needs submitted...');
      // Otherwise it will submit as soon as the image is uploaded
      setNeedsSubmitted(true);
    }
  };

  const renderDatePicker = () => {
    if (isMobile) {
      return (
        <div>
          <Chip
            color="default"
            label={renderEditorTimestamp(selectedDate.toISOString())}
            onClick={() => {
              setShowDatePicker(true);
            }}
          />
          <IonModal
            className="date-picker-modal"
            isOpen={showDatePicker}
            initialBreakpoint={1}
            breakpoints={[0, 1]}
            onDidDismiss={() => setShowDatePicker(false)}
          >
            <IonDatetime
              presentation="date"
              value={selectedDate.toISOString().split('T')[0]}
              max={new Date().toISOString().split('T')[0]}
              size="cover"
              showDefaultButtons={true}
              onIonChange={(e) => handleDateChange(e.detail.value)}
            />
          </IonModal>
        </div>
      );
    }
    return (
      <input
        type="date"
        className="date-picker form-control"
        value={selectedDate.toISOString().split('T')[0]}
        max={new Date().toISOString().split('T')[0]}
        onChange={(e) => handleDateChange(e.target.value)}
      />
    );
  };

  const renderWrapper = () => {
    const { open } = props;
    if (isMobile) {
      return (
        <ModalView
          title={props.entry && props.entry.id ? 'Edit Entry' : 'Add Entry'}
          open={open}
          showFrom="right"
          onClick={toggleOpen}
          onClose={closeModal}
          rightButtons={[
            {
              title: messages[language]?.save || 'Save',
              onClick: onSave,
            },
          ]}
        >
          <div style={{ margin: 15 }}>{renderEditorContent()}</div>
          {renderPurchasePriceSheet()}
          {renderBarcodeOptionsSheet()}
          {renderBarcodeSheet()}
          {renderBoxCodeSheet()}
          {renderPurchaseLocationModal()}
          {renderGiftedFromModal()}
          {renderMediaCropper()}
          {renderVitolaEditorModal()}
          {renderHumidorEditor()}
          {/* {submitting && <LoadingIndicator overlay />} */}
          <BarcodeScanModal
            type={'ean13'}
            open={showBarcodeScanner}
            closeModal={() => setShowBarcodeScanner(false)}
            onResult={onBarcodeDetected}
          />
        </ModalView>
      );
    }
    return (
      <Modal
        isOpen={open}
        toggle={toggleOpen}
        style={{ maxWidth: showExtra ? 1024 : 600 }}
        fade
      >
        <div className="modal-header">
          <Button className="close" color="" onClick={closeModal}>
            <Icon name="x" />
          </Button>
        </div>
        <ModalBody>
          <Row>
            <Col md={showExtra ? 7 : 12}>{renderEditorContent()}</Col>
            {showExtra && (
              <Col md={5}>
                {showVitolaEditorModal && (
                  <div>
                    {/* <VitolaEditor ref={(ref) => (vitolaEditor = ref)} /> */}
                    <div
                      style={{
                        marginTop: 10,
                        textAlign: 'center',
                      }}
                    >
                      <Button onClick={validateVitola}>Add Vitola</Button>
                    </div>
                  </div>
                )}
                {/* FIXME The content should be exported from the modal files for desktop? */}
                {/* {showPurchaseLocationModal && renderPurchaseLocationSearch()} */}
                {/* {showGiftedFromModal && renderGiftedFromSearch()} */}
              </Col>
            )}
          </Row>
          {renderPurchasePriceSheet()}
          {renderBarcodeSheet()}
          {renderBoxCodeSheet()}
          {renderHumidorEditor()}
          {/* {renderMediaCropper()} */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button color="primary" onClick={onSave}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return renderWrapper();
}

export default HumidorEntryEditor;
