import SvgIcon from '@mui/material/SvgIcon';
import { pascalCase } from 'change-case';
import React from 'react';

// FIXME This is a great helper class but we need to be able to do dynamic imports instead of importing all above
//  see https://stackoverflow.com/questions/56369444/how-can-i-dynamically-load-an-icon-using-its-snake-case-name-react-material-ui
//  as of right now, this is causing the huge bundles from the looks of things - ideally, we should probably create our
//  own bundle of icons

/**
 * Component
 *
 * @param {Object} props component props.
 * @return {Object} component.
 */
const Icon = (props: {
  className?: string;
  vendor?: any;
  name?: any;
  size?: number;
  style?: any;
}) => {
  let { name } = props;
  let result: any = null;
  const { vendor = 'feather' } = props;

  const opts = { ...props };
  opts.className = `${opts.className || ''} rui-icon`;

  // Feather.
  if (vendor === 'feather') {
    const FeatherIcons = require('react-feather');
    name = pascalCase(name);

    if (!FeatherIcons[name]) {
      name = 'AlertTriangle';
    }

    const RenderIcon = FeatherIcons[name];

    result = <RenderIcon {...opts} strokeWidth="1.5" />;
  }

  // if (vendor === 'ion') {
  //   name = pascalCase(name);
  //
  //   const IonIcons = require(`ionicons/icons`);
  //   result = <span className="filled"><IonIcon icon={IonIcons[name]} {...opts} /></span>;
  // }

  // FontAwesome.
  if (vendor === 'fa') {
    const far = require('@fortawesome/free-regular-svg-icons');
    const fas = require('@fortawesome/free-solid-svg-icons');
    const fab = require('@fortawesome/free-brands-svg-icons');
    const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');

    const faLib = {
      far,
      fas,
      fab,
    };

    let icon = '';
    let lib = 'far';

    if (Array.isArray(name)) {
      lib = name[0];
      name = name[1];
    }

    name = `fa${pascalCase(name)}`;

    if (faLib[lib] && faLib[lib][name]) {
      icon = faLib[lib][name];
    }

    result = icon ? <FontAwesomeIcon {...opts} icon={icon} /> : '';
  }

  if (vendor === 'svg') {
    return (
      <SvgIcon {...opts} component={props.component} viewBox={props.viewBox} />
    );
  }

  if (vendor === 'material') {
    const MaterialIcons = require('@mui/icons-material');
    name = pascalCase(name);

    if (!MaterialIcons[name]) {
      name = 'Warning';
    }

    const RenderIcon = MaterialIcons[name];
    result = <RenderIcon {...opts} strokeWidth="1.5" />;
  }

  return result;
};

export default Icon;
