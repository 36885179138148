import { IonProgressBar, IonToast } from '@ionic/react';
import { useEffect, useState } from 'react';

import { useUploadStatus } from '@/utils/hooks/useUploadStatus';

const UploadStatus = () => {
  const { uploadStatus } = useUploadStatus();
  const [showToast, setShowToast] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (uploadStatus.status === 'uploading') {
        const message =
          'Your upload is still in progress. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    setProgress(uploadStatus.progress / 100);
    setShowToast(uploadStatus.progress !== 0 && uploadStatus.progress < 100);

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadStatus]);

  // TODO Maybe use something like react-toastify on desktop
  return (
    <>
      <IonToast
        isOpen={showToast}
        message="Uploading media... Do not close app."
        position="bottom"
      />
      {showToast && (
        <IonProgressBar
          value={progress}
          style={{ position: 'fixed', bottom: '60px', left: 0, right: 0 }}
        />
      )}
    </>
  );
};

export default UploadStatus;
