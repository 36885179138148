import Placeholder from '@/config/placeholder.config';
import PlaceholderDark from '@/config/placeholder-dark.config';
import { config } from '@/settings';
import { SettingsStore } from '@/stores';
import { Resize } from '@/utils/imageUtils';

export const strengths = [
  'Mellow',
  'Mellow-Medium',
  'Medium',
  'Medium-Full',
  'Full',
];

export default class Cigar {
  constructor(item) {
    this.brand = item.brand;
    this.name = item.name;
    this.fullName = item.full_name;
  }

  public static getImage = (cigar) => {
    const { darkMode } = SettingsStore.getRawState();
    if (cigar) {
      if (cigar.images && cigar.images.length) {
        for (let i = 0; i < cigar.images.length; i++) {
          const image = cigar.images[i];
          if (image.image_type === 6 && image.approved) {
            // 'Display' image type
            return `${
              config.mediaEndPoint
            }/transforms/background/transparent?key=${image.image_uri.substring(
              1
            )}`;
          }
        }
      }
      if (cigar.image_uri && cigar.image_uri.indexOf('.jpg') === -1) {
        // INFO For now, these legacy images aren't on the new CDN, use the old one
        // They should be handled on the Lambda server for resizing - if the base size doesn't exist,
        // pull from Sigaro and push to Boxpressd
        return `${
          config.mediaEndPoint
        }/transforms/background/transparent?key=${cigar.image_uri
          .replace('boxpressd', 'sigaro')
          .substring(1)}`;
      }
      if (cigar.image_uri) {
        return `${
          config.mediaEndPoint
        }/transforms/background/transparent?key=${cigar.image_uri.substring(
          1
        )}`;
      }
    }
    return darkMode ? PlaceholderDark.cigar : Placeholder.cigar;
  };

  public static getDisplayImage = (cigar, transparent = true) => {
    const { darkMode } = SettingsStore.getRawState();
    if (cigar) {
      if (cigar.images && cigar.images.length) {
        for (let i = 0; i < cigar.images.length; i++) {
          const image = cigar.images[i];
          if (image.image_type === 6 && image.approved) {
            // 'Display' image type
            if (transparent) {
              return `${
                config.mediaEndPoint
              }/transforms/background/transparent?key=${image.image_uri.substring(
                1
              )}`;
            }
            return image.image_url;
          }
        }
      }
    }
    return darkMode ? PlaceholderDark.cigar : Placeholder.cigar;
  };

  public static getBandImage = (cigar: Cigar, resizeOptions = null) => {
    const { darkMode } = SettingsStore.getRawState();
    if (cigar) {
      if (cigar.images && cigar.images.length) {
        for (let i = 0; i < cigar.images.length; i++) {
          const image = cigar.images[i];
          if (image.image_type === 0 && image.image_url) {
            // 'Band' image type
            if (resizeOptions) {
              return Resize.size(image.image_url, resizeOptions);
            }
            return image.image_url;
          }
        }
      }
    }
    return darkMode ? PlaceholderDark.band : Placeholder.band;
  };

  // FIXME Why is this 0-based? The strengths are 1-based
  public static formattedStrength = (strengthInt) => {
    if (strengths[strengthInt]) {
      return strengths[strengthInt];
    }
    return 'Unknown';
  };
}
