import { Divider } from '@mui/material';
import React from 'react';

import Icon from '@/components/Icon';
import ModalView from '@/components/ModalView';
import VitolaEditor from '@/components/VitolaEditor';
import type Vitola from '@/models/Vitola';

const getLength = (size: string) => {
  if (size && size.length > 0) {
    return parseFloat(size.split('"x')[0]);
  }
  return 0;
};

const getMaxLength = (vitolas: Vitola[]) => {
  let max = 0;
  vitolas.forEach((vitola) => {
    const length = getLength(vitola.size);
    if (length > max) {
      max = length;
    }
  });
  return max;
};

const getAspectPercentage = (vitola: Vitola, maxLength) => {
  if (vitola.size) {
    return (getLength(vitola.size) / maxLength) * 100;
  }
  return 0;
};

const VitolaModal = ({
  title,
  vitolas,
  open,
  onClose,
  onSelected = () => {},
}) => {
  const [showVitolaEditorModal, setShowVitolaEditorModal] =
    React.useState(false);
  return (
    <ModalView
      title={title}
      open={open}
      onClose={onClose}
      rightButtons={[
        {
          component: <Icon name="plus" style={{ color: '#2b2b2b' }} />,
          onClick: () => {
            setShowVitolaEditorModal(true);
          },
        },
      ]}
    >
      <div>
        {/* TODO Allow Filters? Shape, etc */}
        {Array.isArray(vitolas)
          ? [...vitolas]
              .sort((a, b) => {
                if (a.size < b.size) {
                  return -1;
                }
                if (a.size > b.size) {
                  return 1;
                }
                return 0;
              })
              .map((vitola) => (
                <div
                  key={vitola.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '12px 0 0',
                  }}
                  onClick={() => onSelected(vitola)}
                >
                  {vitola.image_url && (
                    <img
                      src={vitola.image_url}
                      alt={vitola.formatted_name}
                      style={{
                        width: `${getAspectPercentage(
                          vitola,
                          getMaxLength(vitolas)
                        )}%`,
                        padding: 10,
                      }}
                    />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 12px',
                      }}
                    >
                      <div>{vitola.formatted_name}</div>
                      <div>{/* TODO Icons depicting awards won, if any */}</div>
                    </div>
                    <span style={{ fontSize: 22, fontWeight: 600 }}>
                      {vitola.rating || 'N/A'}
                    </span>
                    <Icon name="chevron-right" style={{ marginLeft: 10 }} />
                  </div>
                  <Divider style={{ marginTop: 12 }} />
                </div>
              ))
          : null}
      </div>
      <VitolaEditor
        open={showVitolaEditorModal}
        onClose={() => setShowVitolaEditorModal(false)}
      />
    </ModalView>
  );
};

export default VitolaModal;
