import './style.scss';

import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonToolbar,
} from '@ionic/react';
import Chip from '@mui/material/Chip';
import { isMobile } from 'mobile-device-detect';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import BottomSheet from '@/components/BottomSheet';
import Icon from '@/components/Icon';
import { SettingsStore } from '@/stores';
import { useUploadStatus } from '@/utils/hooks/useUploadStatus';
import { uploadToS3 } from '@/utils/imageUtils';

export const maxResolution = 3000000;
export const resizedImage = (height, width) => {
  let x = width;
  let y = height;
  const ratio = height / width;

  if (width * height > maxResolution) {
    x = Math.sqrt(maxResolution / ratio);
    y = maxResolution / x;
  }
  return { height: y, width: x };
};

// INFO Have a look at https://codesandbox.io/p/sandbox/loving-benz-lw5z9?file=%2Fsrc%2FApp.js%3A41%2C17-41%2C69 for example filters
//  Also https://github.com/ljaviertovar/react-instagram-filters and

export default function MediaCropper(props) {
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const cropperRef = useRef(null);
  const { setUploadStatus } = useUploadStatus();

  const getCroppedCanvas = (width, height) =>
    cropperRef.current?.cropper.getCroppedCanvas({ width, height });

  const mergeImages = (file) => {
    const imageFiles = [...props.imageFiles];
    for (let i = 0; i < imageFiles.length; i++) {
      if (imageFiles[i].uuid === file.uuid) {
        // TODO Show loading indicator? Set `props.loading(true)`?
        imageFiles[i] = file;
      }
    }
    return imageFiles;
  };

  const onSave = (canvas) => {
    if (typeof props.onSave === 'function') {
      const imageFiles = mergeImages({
        ...props.file,
        src: canvas.toDataURL(),
      });
      console.log('onSave', imageFiles);
      props.onSave(canvas, imageFiles);
    }
  };

  const crop = () => {
    console.log(cropperRef.current);
    if (typeof cropperRef.current?.cropper.getCroppedCanvas === 'function') {
      // console.log(cropperRef.current.getCroppedCanvas());
      const canvas = cropperRef.current!.cropper.getCroppedCanvas();
      const { width, height } = canvas;
      const resized = resizedImage(height, width);
      const croppedCanvas = getCroppedCanvas(
        Math.floor(resized.width),
        Math.floor(resized.height)
      );
      onSave(croppedCanvas);
      if (props.upload) {
        croppedCanvas.toBlob(
          (blob) => {
            const base64 = canvas.toDataURL();
            const extension = base64
              ? base64.split(';')[0].split('/')[1]
              : blob.name.split('.').pop();
            const uuid = props.file.uuid || uuidv4();
            const filename = `${props
              .filenameTemplate()
              .replace('uuid', uuid)}.${extension}`;
            if (typeof props.onUploading === 'function') {
              props.onUploading(filename);
            }
            uploadToS3(
              blob,
              props.uploadFolder,
              filename,
              uuid,
              null,
              setUploadStatus
            )
              .then((res) => {
                console.log('Uploaded to media server!');
                console.log(res);
                const file = {
                  ...res.data,
                  uuid,
                  original_file: base64, // TODO Rename to original_src?
                  src: res.data.media_url,
                  type: 'image',
                };
                console.log(file);
                if (typeof props.onUploaded === 'function') {
                  // console.log('Merging files...');
                  const imageFiles = mergeImages(file);
                  // console.log('Calling props.onUploaded...');
                  props.onUploaded(filename, imageFiles);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } /* , 'image/png' */
        );
      }
    }
  };
  console.log('Cropper file:');
  console.log(props.file);
  const renderCropper = () => (
    <Cropper
      ref={cropperRef}
      src={props.file?.original_file || props.file?.src || props.src}
      style={{
        height: '100%',
        width: '100%',
        maxHeight: '100vh',
        maxWidth: '100vw',
      }}
      className={props.circle ? 'cropper-circle-preview' : ''}
      // autoCropArea={1} // Inset from 0-1 (0-100% - default is 80%)
      // Cropper.js options
      viewMode={1}
      dragMode="none"
      aspectRatio={props.aspectRatio} // TODO Use state and app bar to allow user to change this between common types and freeform and pass in from props
      guides // FIXME Add prop to allow this to be hidden
    />
  );

  if (isMobile) {
    return (
      <BottomSheet open={props.open} onClose={props.onClose}>
        <Chip
          style={{
            position: 'absolute',
            top: 12,
            left: 12,
            backgroundColor: 'rgba(239, 239, 239, 0.8)',
            color: '#2b2b2b',
            zIndex: 1,
          }}
          label="Cancel"
          onClick={props.onClose}
        />
        <Chip
          style={{
            position: 'absolute',
            top: 12,
            right: 12,
            backgroundColor: 'rgba(255, 193, 7, 0.8)',
            color: '#2b2b2b',
            zIndex: 1,
          }}
          label="Done"
          onClick={crop}
        />
        <IonContent className="no-scroll" fullscreen={true}>
          {renderCropper()}
        </IonContent>
        <IonFooter
          mode="ios"
          translucent={true}
          style={{ position: 'fixed', bottom: 0 }}
        >
          <IonToolbar color={darkMode ? undefined : 'dark'}>
            <IonButtons slot="primary">
              {props.showFilters && (
                <IonButton onClick={() => alert('Coming soon!')}>
                  {'Filters'}
                </IonButton>
              )}
              <IonButton
                onClick={() => cropperRef!.current!.cropper.rotate(-90)}
              >
                <Icon
                  name="rotate-ccw"
                  style={{ height: 22, width: 22, color: '#efefef' }}
                />
              </IonButton>
              <IonButton
                onClick={() => cropperRef!.current!.cropper.rotate(90)}
              >
                <Icon
                  name="rotate-cw"
                  style={{ height: 22, width: 22, color: '#efefef' }}
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </BottomSheet>
    );
  }

  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={props.onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>{renderCropper()}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button color="primary" onClick={crop}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
}

MediaCropper.propTypes = {
  src: PropTypes.any, // FIXME Start phasing this out and use file, which should include the src prop
  file: PropTypes.object, // .isRequired, TODO Should be required once src is phased out
  imageFiles: PropTypes.array,
  upload: PropTypes.bool,
  uploadFolder: PropTypes.string,
  filenameTemplate: PropTypes.func,
  aspectRatio: PropTypes.number,
  circle: PropTypes.bool,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onUploading: PropTypes.func,
  onUploaded: PropTypes.func,
};

MediaCropper.defaultProps = {
  imageFiles: [],
  upload: false,
  uploadFolder: null,
  filenameTemplate: () => {},
  circle: false,
  open: false,
  aspectRatio: null,
  toggle: () => {},
  onClose: () => {},
};
