import './style.scss';

import { IonDatetime, IonModal } from '@ionic/react';
import { Button as MaterialButton, Chip, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { parse } from 'date-fns';
import Hashids from 'hashids';
import { isMobile } from 'mobile-device-detect';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Rating from 'react-rating';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import * as Swarm from '@/assets/images/swarm.svg';
import ActionSheet from '@/components/ActionSheet';
import DrinkPairingModal from '@/components/DrinkPairingModal';
import useEditorDiscardConfirmation from '@/components/EditorDiscardConfirmation';
import FileUploader from '@/components/FileUploader';
import FlavorNotesModal from '@/components/FlavorNotesModal';
import Icon from '@/components/Icon';
import MediaCropper from '@/components/MediaCropper';
// import LoadingIndicator from '@/components/LoadingIndicator';
import MediaGrid from '@/components/MediaGrid';
import useDialogAlert from '@/components/ModalDialog';
import ModalView from '@/components/ModalView';
import PurchasePriceSheet from '@/components/PurchasePriceSheet';
import UserModal from '@/components/UserModal';
import VenueModal from '@/components/VenueModal';
import VitolaModal from '@/components/VitolaModal';
import Drink from '@/models/Drink';
import type Session from '@/models/Session';
import type User from '@/models/User';
import Venue from '@/models/Venue';
import { config } from '@/settings';
import { CigarStore, UserStore } from '@/stores';
import {
  addOrMergeFeedSession,
  addOrMergeSession,
} from '@/utils/actions/session.actions';
import ErrorLogger from '@/utils/errorLogger';
import {
  dateFromDuration,
  formatDuration,
  renderEditorTimestamp,
} from '@/utils/formatting';
import { useIndexedDBState } from '@/utils/hooks/useIndexedDB';
import { usePersistedState } from '@/utils/hooks/usePersistedState';
import { language, messages } from '@/utils/localeUtils';
import ShareIntent from '@/utils/shareUtils';
import SocialManager from '@/utils/socialManager';

const hashids = new Hashids('', 12);

const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

const defaults = {
  submittedId: null,
  selectedVitola: null,
  selectedSmokeLocation: null,
  selectedPurchaseLocation: null,
  selectedPackagePrice: '',
  selectedPackageType: 'Single',
  selectedPackageQuantity: 1,
  selectedGiftedFromUser: null,
  selectedDrinkPairing: null,
  selectedTaggedUsers: null,
  selectedDuration: 0,
  selectedImageIndex: null,
  selectedImageFile: null,
  selectedFlavorNotes: {},
  sessionMediaThumbnails: [],
  selectedMedia: [],
  sessionAvgRating: 0,
  sessionBurnRating: 0,
  sessionDrawRating: 0,
  sessionFlavorRating: 0,
  sessionAppearanceRating: 0,
  sessionComment: null,
};

let saveDisabled = false;
let reset = false;
const tempDuration = '00:00';
let facebookUrl;

// FIXME Move to helper class
const parseDate = (timestamp) => {
  let strFormat = 'yyyy-MM-dd HH:mm:ss.SSSxxx';
  if (timestamp && timestamp.indexOf('T') !== -1) {
    strFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
  }

  let date;
  if (timestamp && timestamp.indexOf('Z') === -1) {
    // console.log(`${timestamp}.000Z`);
    date = parse(`${timestamp}.000Z`, strFormat, new Date());
  } else {
    date = parse(timestamp, strFormat, new Date());
  }
  return date;
};

const useStyles = makeStyles((theme) => ({
  textFieldSelect: {
    width: '100%',
    // FIXME What do these styles have to be to remove the background from the vitola select on desktop?
    '.MuiOutlinedInput-root': {
      '.MuiSelect-select': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

let editingMinutes = false;
let anchorEl = null;

export function SessionEditor(props) {
  const { showDialogAlert } = useDialogAlert();
  const { showDiscardConfirmation } = useEditorDiscardConfirmation();

  const classes = useStyles();
  const [session, setSession] = useIndexedDBState('activeSmokeSession', null);
  const [timer, setTimer] = usePersistedState('durationTimer', null);

  const user = UserStore.useState((s) => s.user);

  const maybeUpdateSession = () => {
    const values = {};
    let existingSession;
    if (
      props.location &&
      props.location.state &&
      props.location.state.session
    ) {
      existingSession = props.location.state.session;
    } else if (session) {
      existingSession = session;
    }

    if (existingSession) {
      values.selectedVitola = existingSession.vitola;
      values.selectedSmokeDate =
        existingSession.smoked_timestamp || existingSession.timestamp
          ? parseDate(
              existingSession.smoked_timestamp || existingSession.timestamp
            )
          : null;
      values.selectedDuration = existingSession.duration || 0;
      if (existingSession.duration) {
        values.selectedDurationDate = dateFromDuration(
          existingSession.duration
        );
      } else {
        values.selectedDurationDate = new Date().setHours(0, 0, 0, 0);
      }
      values.selectedPurchaseLocation = existingSession.bought_from;
      values.selectedSmokeLocation = existingSession.smoke_venue;
      values.selectedGiftedFromUser = existingSession.gifted_from;
      values.selectedDrinkPairing = existingSession.drink_pairing;
      values.selectedTaggedUsers = existingSession.tagged_users;
      values.buyAgain = existingSession.buy_again !== false;
      if (existingSession.note) {
        console.log(existingSession.note);
        values.selectedFlavorNotes = existingSession.note;
      }
      if (existingSession.flavor_notes) {
        console.log(existingSession.flavor_notes);
        values.selectedFlavorNotes = existingSession.flavor_notes;
      }
      if (existingSession.advance_rating) {
        values.sessionAvgRating = existingSession.advance_rating.rating;
        values.sessionBurnRating = existingSession.advance_rating.burn;
        values.sessionDrawRating = existingSession.advance_rating.draw;
        values.sessionFlavorRating = existingSession.advance_rating.flavor;
        values.sessionAppearanceRating =
          existingSession.advance_rating.appearance;
      }
      values.selectedPackagePrice = existingSession.price;
      values.selectedPackageType =
        existingSession.price_type || defaults.selectedPackageType;
      values.selectedPackageQuantity =
        existingSession.price_qty || defaults.selectedPackageQuantity;
      values.sessionComment = existingSession.comment;
      values.sessionMediaThumbnails =
        existingSession.media && Array.isArray(existingSession.media)
          ? existingSession.media.map((file) => ({
              ...file,
              src: file.media_url,
              type: file.media_type,
            }))
          : [];
      // INFO Only restore this from local storage - don't populate when editing
      if (!existingSession.id) {
        console.log('Populating selected media');
        values.selectedMedia =
          existingSession.media && Array.isArray(existingSession.media)
            ? existingSession.media.map((file) => ({
                ...file,
                src: file.media_url,
                type: file.media_type,
              }))
            : [];
      }
    }

    if (props.venue) {
      values.selectedSmokeLocation = props.venue;
      values.selectedPurchaseLocation = props.venue;
    }
    if (props.vitola) {
      values.selectedVitola = props.vitola;
    }
    if (props.purchaseLocation) {
      values.selectedPurchaseLocation = props.purchaseLocation;
    }
    if (props.purchasePrice) {
      console.log('Setting purchase price...');
      values.selectedPackagePrice = props.purchasePrice;
    }
    if (props.purchasePriceType) {
      values.selectedPackageType = props.purchasePriceType;
    }
    if (props.purchasePriceQty) {
      values.selectedPackageQuantity = props.purchasePriceQty;
    }
    if (props.giftedFrom) {
      values.selectedGiftedFromUser = props.giftedFrom;
    }

    if (timer) {
      console.log('Got existing timer:');
      console.log(timer);
      if (timer.showTimer) {
        console.log('Showing duration timer');
        values.showDurationTimer = true;
      }
      if (timer.timerRunning) {
        values.timerRunning = true;
      }
    }

    return values;
  };
  const initialValues = {
    ...defaults,
    ...maybeUpdateSession(),
  };
  const [
    {
      selectedVitola,
      selectedSmokeLocation,
      selectedPurchaseLocation,
      selectedPackagePrice,
      selectedPackageType,
      selectedPackageQuantity,
      selectedGiftedFromUser,
      selectedDrinkPairing,
      selectedDuration,
      selectedImageFile,
      selectedMedia,
      sessionAvgRating,
      sessionBurnRating,
      sessionDrawRating,
      sessionFlavorRating,
      sessionAppearanceRating,
      sessionComment,
      selectedTaggedUsers,
      timerRunning,
      showTimer,
      selectedDurationMillis,
      selectedDurationDate,
    },
    setState,
  ] = useState(initialValues);

  const [submittedId, setSubmittedId] = useState(initialValues.submittedId);
  const [selectedScanId, setSelectedScanId] = useState(null);
  const [selectedSmokeDate, setSelectedSmokeDate] = useState(
    initialValues.selectedSmokeDate || new Date()
  );
  const [showMediaCropper, setShowMediaCropper] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [needsSubmitted, setNeedsSubmitted] = useState(false);
  const [timedOut, setTimedOut] = useState(false);
  const [sessionMediaThumbnails, setSessionMediaThumbnails] = useState(
    initialValues.sessionMediaThumbnails
  );
  const [removeSessionMedia, setRemoveSessionMedia] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showAdvancedRatingModal, setShowAdvancedRatingModal] = useState(false);
  const [showVitolaEditorModal, setShowVitolaEditorModal] = useState(false);
  const [showSmokeLocationModal, setShowSmokeLocationModal] = useState(false);
  const [showPurchasePriceSheet, setShowPurchasePriceSheet] = useState(false);
  const [showPurchaseLocationModal, setShowPurchaseLocationModal] =
    useState(false);
  const [showDrinkPairingModal, setShowDrinkPairingModal] = useState(false);
  const [showFlavorNotesModal, setShowFlavorNotesModal] = useState(false);
  const [showFlavorNotesChart, setShowFlavorNotesChart] = useState(
    Object.keys(initialValues.selectedFlavorNotes).length > 0
  );
  const [showGiftedFromModal, setShowGiftedFromModal] = useState(false);
  const [showTaggedUsersModal, setShowTaggedUsersModal] = useState(false);
  const [showVitolaSheet, setShowVitolaSheet] = useState(false);
  const [showDurationTimer, setShowDurationTimer] = useState(
    initialValues.showDurationTimer === true
  );
  const [showDurationOptions, setShowDurationOptions] = useState(false);
  const [showDurationPicker, setShowDurationPicker] = useState(false);
  const [durationAnchor, setDurationAnchor] = useState(null);
  const [buyAgain, setBuyAgain] = useState(initialValues.buyAgain !== false);
  const [vitolas, setVitolas] = useState([]);
  const [suggestedVitolas, setSuggestedVitolas] = useState([]);
  const [showSuggestedVitolas, setShowSuggestedVitolas] = useState(false);
  const [pendingVitola, setPendingVitola] = useState(null);
  const [selectedFlavorNotes, setSelectedFlavorNotes] = useState(
    initialValues.selectedFlavorNotes
  );
  const [submitting, setSubmitting] = useState(false);
  const [showExtra, setShowExtra] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState(
    initialValues.selectedTaggedUsers
  );

  const connectedFacebook = UserStore.useState(
    (s) => s.user && !!s.user.connected_facebook
  );
  const enabledFacebook = UserStore.useState((s) => !!s.autopost_facebook);
  const [autoPostFacebook, setAutoPostFacebook] = useState(enabledFacebook);
  const connectedTwitter = UserStore.useState(
    (s) => s.user && !!s.user.connected_twitter
  );
  const enabledTwitter = UserStore.useState((s) => !!s.autopost_twitter);
  const [autoPostTwitter, setAutoPostTwitter] = useState(enabledTwitter);
  const connectedFoursquare = UserStore.useState(
    (s) => s.user && !!s.user.connected_foursquare
  );
  const enabledFoursquare = UserStore.useState((s) => !!s.autopost_foursquare);
  const [autoPostFoursquare, setAutoPostFoursquare] =
    useState(enabledFoursquare);
  const connectedUntappd = UserStore.useState(
    (s) => s.user && !!s.user.connected_untappd
  );
  const enabledUntappd = UserStore.useState((s) => !!s.autopost_untappd);
  const [autoPostUntappd, setAutoPostUntappd] = useState(enabledUntappd);

  const addPhotos = useRef(null);
  const addVideo = useRef(null);
  let vitolaEditor;

  // useBeforeunload((event) => {
  //   // TODO Store the session details to be restored or prompt before leaving for BXPR-355
  //   // FIXME I think a better option might be to store the session info as the user makes changes and then clear the storage
  //   //  after they hit submit
  //   event.preventDefault();
  //   return '';
  // });

  useEffect(() => {
    let existingSession = {};
    if (
      props.location &&
      props.location.state &&
      props.location.state.session
    ) {
      existingSession = props.location.state.session;
    }
    if (!saveDisabled && !reset) {
      console.log(existingSession.id);
      console.log(submittedId);
      let existingId = null;
      if (existingSession.id) {
        setSubmittedId(existingSession.id);
        existingId = existingSession.id;
        if (existingSession.scan && existingSession.scan.id) {
          setSelectedScanId(existingSession.scan.id);
        }
      } else if (submittedId) {
        existingId = submittedId;
      }
      setSession({
        id: existingId, // FIXME Ensure that if the data submitted, but there was an error, this ID gets set no matter what
        scan: props.scan ||
          existingSession.scan || {
            // TODO How do we handle authentication? Save the session in global state then redirect to auth?
            id: selectedScanId || undefined,
            user,
            cigar: props.cigar || null,
          },
        vitola: selectedVitola,
        // timestamp: new Date().toISOString(), // This is optional, it will update on the server to 'now'
        smoked_timestamp: selectedSmokeDate.toISOString(),
        duration: selectedDurationMillis,
        buy_again: buyAgain,
        bought_from: selectedPurchaseLocation,
        smoke_venue: selectedSmokeLocation,
        gifted_from: selectedGiftedFromUser,
        tagged_users: taggedUsers,
        drink_pairing: selectedDrinkPairing,
        flavor_notes:
          Object.keys(selectedFlavorNotes).length > 0
            ? selectedFlavorNotes
            : null,
        advance_rating: sessionAvgRating
          ? {
              rating: sessionAvgRating,
              flavor: sessionFlavorRating || sessionAvgRating,
              burn: sessionBurnRating || sessionAvgRating,
              draw: sessionDrawRating || sessionAvgRating,
              appearance: sessionAppearanceRating || sessionAvgRating,
            }
          : null,
        price:
          selectedPackagePrice &&
          (selectedPackagePrice.length || selectedPackagePrice > 0)
            ? selectedPackagePrice
            : null,
        price_type:
          selectedPackagePrice &&
          (selectedPackagePrice.length || selectedPackagePrice > 0)
            ? selectedPackageType
            : null,
        price_qty:
          selectedPackagePrice &&
          (selectedPackagePrice.length || selectedPackagePrice > 0)
            ? selectedPackageQuantity
            : null,
        comment: sessionComment,
        media: sessionMediaThumbnails.map((m) => ({
          ...m,
          original_file: true, // INFO Need to wipe the base64 src first otherwise the JSON will exceed the quota
          src: m.media_url,
        })),
      });

      // console.log('Storing session...');
      // console.log(session);
    }
  }, [
    props.cigar,
    props.location && props.location.state && props.location.state.session,
    selectedVitola,
    selectedSmokeDate,
    selectedPurchaseLocation,
    selectedSmokeLocation,
    selectedGiftedFromUser,
    taggedUsers,
    buyAgain,
    selectedDrinkPairing,
    selectedFlavorNotes,
    sessionAvgRating,
    sessionFlavorRating,
    sessionBurnRating,
    sessionDrawRating,
    sessionAppearanceRating,
    selectedPackagePrice,
    selectedPackageType,
    selectedPackageQuantity,
    sessionComment,
    sessionMediaThumbnails,
    selectedDuration,
    selectedDurationMillis,
    submittedId,
  ]);

  useEffect(() => {
    setTimer({
      showTimer,
      timerRunning,
    });
  }, [showTimer, timerRunning]);

  useEffect(() => {
    if (!isMobile) {
      calculateAverageRating();
    }
  }, [
    sessionBurnRating,
    sessionDrawRating,
    sessionFlavorRating,
    sessionAppearanceRating,
  ]);

  useEffect(() => {
    if (props.cigar) {
      if (props.cigar.vitolas && props.cigar.vitolas.length) {
        setVitolas(props.cigar.vitolas);
      } else if (props.cigar.id) {
        axios
          .get(`${config.apiEndPoint}/cigars/${props.cigar.id}/vitolas`)
          .then((response) => {
            const vitolaList = response.data;
            if (vitolaList) {
              setVitolas(vitolaList);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    return () => {
      // FIXME Somehow need to get rid of the query params for cigars/:uid route since they kick off showing this?
    };
  }, [props.cigar]);

  useEffect(() => {
    console.log(`Uploading is ${uploadingMedia ? 'true' : 'false'}`);
    console.log(`Needs submitted is ${needsSubmitted ? 'true' : 'false'}`);
    if (needsSubmitted && (!uploadingMedia || timedOut)) {
      console.log('Finished uploading');
      setUploadingMedia(false);
      console.log('Auto saving session from media callback');
      onSave();
    }
  }, [uploadingMedia, needsSubmitted, timedOut]);

  // const mediaChoiceOptions = [{
  //   content: 'Add Photo',
  //   value: 'photo',
  //   onClick: () => {
  //     setShowMediaOptionsSheet(false);
  //     addPhotos.click();
  //   },
  // }, {
  //   content: 'Add Video',
  //   value: 'video',
  //   onClick: () => {
  //     setShowMediaOptionsSheet(false);
  //     // TODO Add this back - they need trimmed before submitted
  //     // addVideo.click();
  //     // https://www.npmjs.com/package/react-video-trimmer
  //     // https://www.npmjs.com/package/ffmpeg-webworker
  //     console.log('Video uploads are coming soon! Please add photos until then.');
  //   },
  // }];

  const durationOptions = [
    {
      content: 'Add Manually',
      value: 'add',
      onClick: () => {
        setShowDurationOptions(false);
        setShowDurationPicker(true);
      },
    },
    {
      content: 'Start Timer',
      value: 'time',
      onClick: () => {
        setShowDurationTimer(true);
      },
    },
  ];

  const toggleOpen = () => {
    const { toggle } = props;
    console.log('Toggling inside modal...');
    if (toggle && typeof toggle === 'function') {
      setSelectedSmokeDate(new Date());
      toggle();
    }
  };

  const resetAndClose = () => {
    const { onClose } = props;
    reset = true;
    defaults.selectedMedia = [];
    defaults.sessionMediaThumbnails = [];
    console.log('Reset cached session details...');
    setSessionMediaThumbnails([]);
    setTaggedUsers(null);
    setSubmittedId(null);
    setState({ ...defaults });
    localStorage.setItem('activeSmokeSession', JSON.stringify({}));
    localStorage.setItem('durationTimer', JSON.stringify({}));
    localStorage.setItem('shouldResetTimer', JSON.stringify(true));
    // FIXME Why is this not working? We have to manually update it above
    setSession({});
    setTimer({});
    if (facebookUrl) {
      console.log('Opening in FB...');
      const shareWindow = window.open(
        facebookUrl,
        'share-popup',
        'height=350,width=600'
      );
      // const shareWindow = window.open(facebookUrl, 'share-popup');
      if (shareWindow && shareWindow.focus) {
        shareWindow.focus();
      }
    }
    if (typeof onClose === 'function') {
      onClose();
      setTimeout(() => {
        reset = false;
      }, 250);
    }
  };

  const closeModal = (prompt = true) => {
    if (isMobile && prompt) {
      showDiscardConfirmation({
        title: 'Discard Smoke Session',
        message:
          'Are you sure you want to discard this Smoke Session? Your changes will be lost.',
        onClick: resetAndClose,
      });
    } else {
      resetAndClose();
    }
  };

  const handleDateChange = (date: string) => {
    console.log(date);
    if (date) {
      setSelectedSmokeDate(new Date(`${date}T00:00:00.000Z`));
      setShowDatePicker(false);
    }
  };

  const handleDurationChange = (date) => {
    console.log('Date changed:');
    console.log(date);
    const hours = date.getHours();
    const mins = date.getMinutes();
    const duration = hours * 3.6e6 + mins * 60000;
    setState((prevState) => ({
      ...prevState,
      selectedDurationDate: date,
      selectedDurationMillis: duration,
      selectedDuration: `${hours}:${mins}`,
    }));
    if (editingMinutes) {
      editingMinutes = false;
      setShowDurationPicker(false);
    } else {
      editingMinutes = true;
    }
  };

  const addFriends = () => {
    setShowDrinkPairingModal(false);
    setShowSmokeLocationModal(false);
    setShowPurchaseLocationModal(false);
    setShowFlavorNotesModal(false);
    setShowVitolaEditorModal(false);
    setShowGiftedFromModal(false);
    setShowTaggedUsersModal(true);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  const addGiftNote = () => {
    setShowGiftedFromModal(true);
    setShowDrinkPairingModal(false);
    setShowSmokeLocationModal(false);
    setShowPurchaseLocationModal(false);
    setShowFlavorNotesModal(false);
    setShowVitolaEditorModal(false);
    setShowTaggedUsersModal(false);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  // FIXME Create a "reset" function to set these all back to false before setting the one that needs set
  const addSmokeLocation = () => {
    setShowDrinkPairingModal(false);
    setShowSmokeLocationModal(true);
    setShowPurchaseLocationModal(false);
    setShowFlavorNotesModal(false);
    setShowVitolaEditorModal(false);
    setShowGiftedFromModal(false);
    setShowTaggedUsersModal(false);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  const addPurchaseLocation = () => {
    setShowDrinkPairingModal(false);
    setShowPurchaseLocationModal(true);
    setShowSmokeLocationModal(false);
    setShowFlavorNotesModal(false);
    setShowVitolaEditorModal(false);
    setShowGiftedFromModal(false);
    setShowTaggedUsersModal(false);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  const addDrinkPairing = () => {
    setShowDrinkPairingModal(true);
    setShowSmokeLocationModal(false);
    setShowPurchaseLocationModal(false);
    setShowFlavorNotesModal(false);
    setShowVitolaEditorModal(false);
    setShowGiftedFromModal(false);
    setShowTaggedUsersModal(false);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  const addFlavorNotes = () => {
    setShowDrinkPairingModal(false);
    setShowSmokeLocationModal(false);
    setShowPurchaseLocationModal(false);
    setShowFlavorNotesModal(true);
    setShowVitolaEditorModal(false);
    setShowGiftedFromModal(false);
    setShowTaggedUsersModal(false);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  const addDuration = (e) => {
    setShowDurationOptions(true);
    if (!isMobile) {
      setDurationAnchor(e.currentTarget);
    }
  };

  const calculateAverageRating = () => {
    let totalRating = 0;

    console.debug('Ratings:');
    console.debug(sessionFlavorRating);
    console.debug(sessionDrawRating);
    console.debug(sessionBurnRating);
    console.debug(sessionAppearanceRating);

    totalRating += sessionFlavorRating;
    totalRating += sessionDrawRating;
    totalRating += sessionBurnRating;
    totalRating += sessionAppearanceRating;

    // FIXME What if on desktop they don't enter all 4? It treats the rest as 0
    console.debug(totalRating / 4);

    setState((prevState) => ({
      ...prevState,
      sessionAvgRating: totalRating / 4,
    }));
  };

  const renderAdvancedRatingEntry = () => (
    <Row style={{ marginBottom: 10 }}>
      <Col lg={3} className="text-label text-center">
        <div>Flavor</div>
        <Rating
          initialRating={sessionFlavorRating}
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              sessionFlavorRating: value,
            }));
          }}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          fractions={4}
          style={{
            color: '#D3A966',
            fontSize: isMobile ? 28 : 20,
          }}
        />
      </Col>
      <Col lg={3} className="text-label text-center">
        <div>Draw</div>
        <Rating
          initialRating={sessionDrawRating}
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              sessionDrawRating: value,
            }));
          }}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          fractions={4}
          style={{
            color: '#D3A966',
            fontSize: isMobile ? 28 : 20,
          }}
        />
      </Col>
      <Col lg={3} className="text-label text-center">
        <div>Burn</div>
        <Rating
          initialRating={sessionBurnRating}
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              sessionBurnRating: value,
            }));
          }}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          fractions={4}
          style={{
            color: '#D3A966',
            fontSize: isMobile ? 28 : 20,
          }}
        />
      </Col>
      <Col lg={3} className="text-label text-center">
        <div>Appearance</div>
        <Rating
          initialRating={sessionAppearanceRating}
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              sessionAppearanceRating: value,
            }));
          }}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          fractions={4}
          style={{
            color: '#D3A966',
            fontSize: isMobile ? 28 : 20,
          }}
        />
      </Col>
    </Row>
  );

  const formatSelectedSmokeDate = () => {
    console.log('Formatting selected smoke date:');
    console.log(selectedSmokeDate.toISOString());
    return `${renderEditorTimestamp(
      selectedSmokeDate.toISOString()
    )}${formatDuration(selectedDuration)}`;
  };

  const renderMediaCropper = () => (
    <MediaCropper
      upload
      uploadFolder="sessions"
      imageFiles={sessionMediaThumbnails}
      file={selectedImageFile}
      src={selectedImageFile && selectedImageFile.src}
      filenameTemplate={() => {
        const userHash = hashids.encode(user.id);
        return `${userHash}-uuid`;
      }}
      open={showMediaCropper}
      thumbnailAspectRatio={0.8}
      toggle={() => setShowMediaCropper(!showMediaCropper)}
      onClose={() => setShowMediaCropper(false)}
      onSave={(croppedCanvas, files) => {
        // console.log('Cropping image...');
        setShowMediaCropper(false);
        console.log('Images after:');
        console.log(files);
        setSessionMediaThumbnails(files);
      }}
      onUploading={(filename) => {
        setUploadingMedia(true);
      }}
      onUploaded={(filename, files) => {
        console.log('Uploaded file!');
        console.log(files);
        setSessionMediaThumbnails(files);
        setState((prevState) => ({
          ...prevState,
          selectedMedia: files,
        }));
        setUploadingMedia(false);
      }}
    />
  );

  const renderDatePicker = () => {
    if (isMobile) {
      return (
        <div>
          <Chip
            color="default"
            label={formatSelectedSmokeDate()}
            onClick={() => {
              setShowDatePicker(true);
            }}
          />
          <IonModal
            className="date-picker-modal"
            isOpen={showDatePicker}
            initialBreakpoint={1}
            breakpoints={[0, 1]}
            onDidDismiss={() => setShowDatePicker(false)}
          >
            <IonDatetime
              presentation="date"
              value={selectedSmokeDate.toISOString().split('T')[0]}
              max={new Date().toISOString().split('T')[0]}
              size="cover"
              showDefaultButtons={true}
              onIonChange={(e) => handleDateChange(e.detail.value)}
            />
          </IonModal>
        </div>
      );
    }
    return (
      <input
        type="date"
        className="date-picker form-control"
        value={selectedSmokeDate.toISOString().split('T')[0]}
        max={new Date().toISOString().split('T')[0]}
        onChange={(e) => handleDateChange(e.target.value)}
      />
    );
  };

  const renderDurationOptions = () => (
    <ActionSheet
      isMobile={isMobile}
      items={durationOptions}
      open={showDurationOptions}
      toggle={() => setShowDurationOptions(!showDurationOptions)}
    />
  );

  // TODO I think stick with this radial time picker - it still seems to be the easiest way to do this
  // const renderDurationPicker = () => {
  //   const dialogStyles = isMobile
  //     ? {}
  //     : {
  //         maxWidth: 384,
  //         margin: 'auto',
  //       };
  //   return (
  //     // FIXME How to get rid of the "edit" button in top right corner?
  //     <Dialog
  //       style={dialogStyles}
  //       open={showDurationPicker}
  //       onClose={() => setShowDurationPicker(false)}
  //     >
  //       <ThemeProvider theme={mobileDatePickerTheme}>
  //         <LocalizationProvider dateAdapter={AdapterDateFns}>
  //           <MobileTimePicker
  //             ampm={false}
  //             autoOk
  //             title="Set Duration"
  //             open={showDurationPicker}
  //             value={selectedDurationDate}
  //             renderInput={(params) => (
  //               <TextField {...params} margin="normal" variant="standard" />
  //             )}
  //             onChange={handleDurationChange}
  //             onClose={() => setShowDurationPicker(false)}
  //           />
  //         </LocalizationProvider>
  //       </ThemeProvider>
  //     </Dialog>
  //   );
  // };

  const renderVitolaOptions = (allowAdding = false) => {
    if (isMobile) {
      return (
        <Chip
          color="default"
          label={
            selectedVitola && selectedVitola.formatted_name
              ? selectedVitola.formatted_name
              : 'Vitola (Shape & Size)'
          }
          onClick={() => setShowVitolaSheet(true)}
        />
      );
    }
    return (
      <Col className="vitola-select">
        <h4 style={{ marginBottom: 5 }}>Select Vitola</h4>
        <TextField
          select
          variant="outlined"
          className={classes.textFieldSelect}
          value={selectedVitola && selectedVitola.id}
          onChange={(e) => {
            if (e.target.value !== -1) {
              console.log(e);
              let vitola = null;
              for (const current of vitolas) {
                if (parseInt(current.id) === parseInt(e.target.value)) {
                  vitola = current;
                  console.log('Got vitola:');
                  break;
                }
              }
              vitola.value = vitola.id;
              vitola.label = vitola.formatted_name;
              console.log(vitola);
              setState((prevState) => ({
                ...prevState,
                selectedVitola: vitola,
              }));
            } else {
              setShowVitolaEditorModal(true);
              setShowExtra(true);
            }
          }}
          helperText="Select cigar size/shape"
        >
          {vitolas.map((vitola) => (
            <MenuItem key={`vitola-${vitola.id}`} value={vitola.id}>
              {vitola.formatted_name}
            </MenuItem>
          ))}
          {allowAdding && <MenuItem value={-1}>Add Size / Shape</MenuItem>}
        </TextField>
      </Col>
    );
  };

  const renderSmokeLocation = () => (
    <div
      style={{
        display: 'flex',
        marginTop: 5,
      }}
    >
      <Avatar
        src={Venue.getImage(selectedSmokeLocation)}
        style={{
          height: 20,
          width: 20,
          marginRight: 10,
        }}
      >
        {selectedSmokeLocation?.name?.charAt(0)}
      </Avatar>
      <div style={{ flex: 'auto' }}>
        {`Smoked at ${selectedSmokeLocation?.name}`}
      </div>
    </div>
  );

  const formatPackageType = () => {
    if (selectedPackageType === 'Single') {
      return selectedPackageType;
    }
    return `${selectedPackageType} of ${selectedPackageQuantity}`;
  };

  const renderPurchaseLocation = () => (
    <div>
      <div
        style={{
          display: 'flex',
          marginTop: 5,
        }}
      >
        <Avatar
          src={Venue.getImage(selectedPurchaseLocation)}
          style={{
            height: 20,
            width: 20,
            marginRight: 10,
          }}
        >
          {selectedPurchaseLocation.name.charAt(0)}
        </Avatar>
        <div style={{ flex: 'auto' }}>
          {`Bought at ${selectedPurchaseLocation.name}`}
        </div>
        <MaterialButton
          variant="contained"
          color="primary"
          style={{ marginTop: -5 }}
          onClick={() => setShowPurchasePriceSheet(true)}
        >
          {selectedPackagePrice && parseFloat(selectedPackagePrice) > 0
            ? 'Edit Price'
            : '+ Price'}
        </MaterialButton>
      </div>
      {selectedPackagePrice && parseFloat(selectedPackagePrice) > 0 ? (
        <div>
          <span
            style={{
              fontSize: 10,
              color: '#aeaeae',
              marginLeft: 30,
            }}
          >
            {`for $${selectedPackagePrice} / ${formatPackageType()}`}
          </span>
        </div>
      ) : null}
    </div>
  );

  const renderGiftedFrom = () => (
    <div
      style={{
        display: 'flex',
        marginTop: 5,
      }}
    >
      <Avatar
        src={selectedGiftedFromUser.image_url}
        style={{
          height: 20,
          width: 20,
          marginRight: 10,
        }}
      >
        {selectedGiftedFromUser.full_name.charAt(0)}
      </Avatar>
      <div style={{ flex: 'auto' }}>
        {`Gift from ${selectedGiftedFromUser.full_name}`}
      </div>
    </div>
  );

  const renderDrinkPairing = () => (
    <div
      style={{
        display: 'flex',
        marginTop: 5,
      }}
    >
      <Avatar
        src={Drink.getImage(selectedDrinkPairing)}
        style={{
          height: 20,
          width: 20,
          marginRight: 10,
        }}
      >
        {selectedDrinkPairing.name.charAt(0)}
      </Avatar>
      <div style={{ flex: 'auto' }}>
        {`Paired with ${selectedDrinkPairing.name}`}
      </div>
    </div>
  );

  const renderTaggedUsers = () => (
    <AvatarGroup>
      {taggedUsers.slice(0, 6).map((u) => (
        <Avatar
          key={u.id}
          src={u.image_url}
          alt={u.full_name}
          style={{ height: 30, width: 30 }}
        />
      ))}
      {taggedUsers.length > 6 && (
        <Avatar key="more-users" style={{ height: 30, width: 30 }}>
          <Icon name="more-horizontal" />
        </Avatar>
      )}
    </AvatarGroup>
  );

  const renderPostToOptions = () => (
    <>
      <Icon
        name={['fab', 'facebook']}
        vendor="fa"
        className={`social ${autoPostFacebook ? 'filled facebook' : 'outline'}`}
        onClick={() => {
          if (!connectedFacebook) {
            SocialManager.login('facebook');
          } else {
            setAutoPostFacebook((toggled) => !toggled);
          }
        }}
      />
      <Icon
        name={['fab', 'x-twitter']}
        vendor="fa"
        className={`social ${autoPostTwitter ? 'filled twitter' : 'outline'}`}
        onClick={() => {
          if (!connectedTwitter) {
            SocialManager.login('twitter');
          } else {
            setAutoPostTwitter((toggled) => !toggled);
          }
        }}
      />
      {selectedSmokeLocation && selectedSmokeLocation.foursquare_id && (
        <Icon
          vendor="svg"
          component={Swarm}
          className={`social ${
            autoPostFoursquare ? 'filled swarm' : 'outline'
          }`}
          onClick={() => {
            if (!connectedFoursquare) {
              SocialManager.login('foursquare');
            } else {
              setAutoPostFoursquare((toggled) => !toggled);
            }
          }}
        />
      )}
      {/* FIXME For some reason, the Untappd items are missing the ID in the database, so this won't work until we find a way to link them again... */}
      {selectedDrinkPairing && selectedDrinkPairing.untapped_id && (
        <Icon
          name={['fab', 'untappd']}
          vendor="fa"
          className={`social ${autoPostUntappd ? 'filled untappd' : 'outline'}`}
          onClick={() => {
            if (!connectedUntappd) {
              SocialManager.login('untappd');
            } else {
              setAutoPostUntappd((toggled) => !toggled);
            }
          }}
        />
      )}
      {/* <Icon */}
      {/*  name={['fab', 'instagram']} */}
      {/*  vendor="fa" */}
      {/*  className={`social ${autoPostInstagram ? 'filled instagram' : 'outline'}`} */}
      {/*  onClick={() => { */}
      {/*    if (!connectedInstagram) { */}
      {/*      SocialManager.login('instagram'); */}
      {/*    } else { */}
      {/*      setAutoPostInstagram((toggled) => !toggled); */}
      {/*    } */}
      {/*  }} */}
      {/* /> */}
    </>
  );

  const renderSessionModalContent = () => (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col xs={5}>
          <h4 style={{ marginBottom: 5 }}>Date Smoked</h4>
          {renderDatePicker()}
        </Col>
        {isMobile && (
          <Col xs={7}>
            <h4 style={{ marginBottom: 5 }}>Add Media</h4>
            <Chip
              color="default"
              onClick={async (event) => {
                anchorEl = event.currentTarget;
                addPhotos.current.click();
              }}
              style={styles.chip}
              icon={<Icon name="image" size={16} />}
              label={<span>Photo</span>}
            />
            <Chip
              color="default"
              onClick={async (event) => {
                anchorEl = event.currentTarget;
                addVideo.current.click();
              }}
              style={styles.chip}
              icon={<Icon name="video" />}
              label={<span>Video</span>}
            />
          </Col>
        )}
        {!isMobile &&
          props.cigar &&
          props.cigar.id &&
          renderVitolaOptions(true)}
      </Row>
      {/* TODO Consider just making this a dropzone w/ no grid preview or preview to the right of the dropzone - I think FB still handles it like this, but may be better as a dropzone */}
      {!isMobile && (
        <Col>
          <h4 style={{ marginBottom: 5 }}>Add Media</h4>
          <Chip
            color="default"
            onClick={() => {
              addPhotos.current.click();
            }}
            style={styles.chip}
            icon={<Icon name="image" />}
            label={<span>Photo</span>}
          />
          <Chip
            color="default"
            onClick={() => {
              addVideo.current.click();
            }}
            style={styles.chip}
            icon={<Icon name="video" />}
            label={<span>Video</span>}
          />
        </Col>
      )}
      {/* FIXME Include the flavor note chart inline with the images / video? Add it as a thumbnail image? */}
      {/* FIXME On desktop, this should be to the left or right of the rest of the details */}
      {sessionMediaThumbnails && sessionMediaThumbnails.length > 0 && (
        <div
          className="bottom-divider selected-session-media"
          style={{
            marginBottom: 10,
            paddingBottom: 10,
          }}
        >
          <div>Image preview</div>
          <div>
            <Icon name="info" /> {'Tap an image to see full, uncropped version'}
          </div>
          <MediaGrid
            media={sessionMediaThumbnails}
            thumbnailMedia={sessionMediaThumbnails.map((media) => ({
              ...media,
              // INFO src can be either base64 or a URL
              // FIXME Only resize images? How to handle video...?
              src:
                media.type === 'image'
                  ? media.src.replace('/sessions/', '/sessions/500x500/')
                  : media.src,
            }))}
            direction="vertical"
            fancyBox
            withEditor
            onChange={(images, index) => {
              // console.log('Updating images...');
              // console.log(images);
              setState((prevState) => ({
                ...prevState,
                selectedMedia: images,
              }));
              setSessionMediaThumbnails(images);
            }}
            onEdit={(index, image) => {
              // console.log(`Editing image at index: ${index}`);
              // console.log(image);
              setState((prevState) => ({
                ...prevState,
                selectedImageFile: image,
                selectedImageIndex: index || 0, // INFO Patch for rare case when the first index won't return a value
              }));
              setShowMediaCropper(true);
            }}
            onRemoved={(file) => {
              console.log('Removed file:');
              console.log(file);
              if (file && file.id) {
                setRemoveSessionMedia([...removeSessionMedia, file]);
              }
            }}
          />
        </div>
      )}
      <h4 style={{ marginTop: isMobile ? 0 : 20 }}>How was your experience?</h4>
      {isMobile ? (
        <div
          style={{
            textAlign: 'center',
            marginBottom: 10,
          }}
        >
          <Rating
            initialRating={sessionAvgRating}
            emptySymbol="far fa-star"
            fullSymbol="fas fa-star"
            fractions={2}
            onChange={(value) => {
              // console.log('Selected rating:');
              // console.log(value);
              setState((prevState) => ({
                ...prevState,
                sessionAvgRating: value,
                sessionBurnRating: value,
                sessionDrawRating: value,
                sessionFlavorRating: value,
                sessionAppearanceRating: value,
              }));
              setShowAdvancedRatingModal(true);
            }}
            style={{
              color: '#D3A966',
              fontSize: 28,
            }}
          />
        </div>
      ) : (
        renderAdvancedRatingEntry()
      )}
      {/* TODO Include emojione from messenger component */}
      <textarea
        value={sessionComment || ''}
        onChange={(event) => {
          event.persist();
          setState((prevState) => ({
            ...prevState,
            sessionComment: event.target.value,
          }));
        }}
        placeholder="What were your thoughts about this cigar?"
        className="form-control"
        style={{ height: 120, backgroundColor: 'white' }}
      />
      {/* <FormControlLabel */}
      {/* style={{ margin: 6 }} */}
      {/* control={ */}
      {/*   <Switch */}
      {/*     id="buy-again-switch" */}
      {/*     isChecked={buyAgain} */}
      {/*     onChange={(checked) => setBuyAgain(checked)} */}
      {/*   /> */}
      {/* } */}
      {/* label="Would you buy it again?" */}
      {/* /> */}
      <div
        className="bottom-divider selected-session-options"
        style={{
          marginTop: 20,
          marginBottom: 10,
          paddingBottom: 10,
        }}
      >
        {taggedUsers && renderTaggedUsers()}
        {selectedSmokeLocation && renderSmokeLocation()}
        {selectedPurchaseLocation && renderPurchaseLocation()}
        {selectedGiftedFromUser && renderGiftedFrom()}
        {selectedDrinkPairing && renderDrinkPairing()}
      </div>
      {/* FIXME Add this as one of the image grid items */}
      {showFlavorNotesChart && (
        <div
          style={{
            width: '100%',
            height: 225,
          }}
        >
          {/* <FlavorNotesChart notes={selectedFlavorNotes} legend="right" /> */}
        </div>
      )}
      <div>
        <FileUploader
          id="image-picker"
          multiple
          accept="image/*"
          menuAnchor={anchorEl}
          forwardedRef={addPhotos}
          files={sessionMediaThumbnails}
          folder="sessions"
          filenameTemplate={() => {
            const userHash = hashids.encode(user.id);
            return `${userHash}-uuid`;
          }}
          uploading={(isUploading) => {
            console.log(
              isUploading ? 'Still uploading media...' : 'Done uploading media!'
            );
            setUploadingMedia(isUploading);
          }}
          onUploading={(uploadingFile, files) => {
            // console.log('Uploading file:');
            // console.log(uploadingFile);
            // console.log('Uploading files:');
            // console.log(files);
            setState((prevState) => ({
              ...prevState,
              selectedMedia: files,
            }));
            setSessionMediaThumbnails(files);
          }}
          onUploaded={(uploadedFile, files) => {
            // console.log('Uploaded files:');
            // console.log(files);
            setState((prevState) => ({
              ...prevState,
              selectedMedia: files,
            }));
            setSessionMediaThumbnails(files);
          }}
        />
        <FileUploader
          id="video-picker"
          multiple
          accept="video/*"
          menuAnchor={anchorEl}
          forwardedRef={addVideo}
          files={sessionMediaThumbnails}
          folder="sessions"
          filenameTemplate={() => {
            const userHash = hashids.encode(user.id);
            return `${userHash}-uuid`;
          }}
          uploading={(isUploading) => {
            setUploadingMedia(isUploading);
          }}
          onUploading={(uploadingFile, files) => {
            // console.log('Uploading file:');
            // console.log(uploadingFile);
            // console.log('Uploading files:');
            // console.log(allFiles);
            if (files.length > 0) {
              // TODO Set so the media grid can use them - would be nice to show uploading animation
              setState((prevState) => ({
                ...prevState,
                selectedMedia: files,
              }));
              setSessionMediaThumbnails(files);
            }
          }}
          onUploaded={(uploadedFile, files) => {
            console.log('Uploaded files:');
            console.log(JSON.stringify(files));
            // INFO Just stick with local files
            // if (files.length > 0) {
            //   console.log(files[0].media_url);
            //   setState((prevState) => ({
            //     ...prevState,
            //     selectedMedia: files,
            //   }));
            //   setSessionMediaThumbnails(files);
            // }
          }}
        />
        <div>Add To Smoke Session:</div>
        {isMobile && props.cigar && props.cigar.id && renderVitolaOptions(true)}
        <Chip
          color="default"
          onClick={addSmokeLocation}
          style={styles.chip}
          icon={<Icon name="map-pin" />}
          label={<span>Smoke Location</span>}
        />
        <Chip
          color="default"
          onClick={addDrinkPairing}
          style={styles.chip}
          icon={<Icon name="coffee" />}
          label={<span>Drink Pairing</span>}
        />
        <Chip
          color="default"
          onClick={addPurchaseLocation}
          style={styles.chip}
          icon={<Icon name="shopping-cart" />}
          label={<span>Purchase Location</span>}
        />
        <Chip
          color="default"
          onClick={addGiftNote}
          style={styles.chip}
          icon={<Icon name="gift" />}
          label={<span>Gift From Friend</span>}
        />
        <Chip
          color="default"
          onClick={addFlavorNotes}
          style={styles.chip}
          icon={<Icon name="pie-chart" />}
          label={<span>Flavor Notes</span>}
        />
        <Chip
          color="default"
          onClick={addFriends}
          style={styles.chip}
          icon={<Icon name="users" />}
          label={<span>Tag Friends</span>}
        />
      </div>

      {/* {submitting && <LoadingIndicator overlay />} */}
    </div>
  );

  const renderVitolaSheet = () => (
    <VitolaModal
      title="Vitolas"
      vitolas={vitolas}
      open={showVitolaSheet}
      onClose={() => setShowVitolaSheet(!showVitolaSheet)}
    />
  );

  const formatVitola = (vitola) => {
    if (vitola) {
      const { alias, shape, size } = vitola;
      let name = '';
      if (alias !== null && shape !== null) {
        // Alias (Shape - #.#"x#)
        name = `${alias} (${shape} - ${size})`;
      } else if (alias !== null && shape === null) {
        // Alias (#.#"x#)
        name = `${alias} (${size})`;
      } else if (alias === null && shape !== null) {
        // Shape (#.#"x#)
        name = `${shape} (${size})`;
      } else {
        // #.#"x#
        name = size;
      }
      return name;
    }
    return '';
  };

  const validateVitola = () => {
    vitolaEditor.validate((results) => {
      if (results) {
        setPendingVitola(results);
        axios
          .post(
            `${config.apiEndPoint}/cigars/${props.cigar.id}/vitolas?force=false`,
            results
          )
          .then((response) => {
            const { vitola, suggestions } = response.data;

            if (vitola) {
              vitolas.push(vitola); // FIXME This should work more in a Reselect / Redux fashion so it updates globally in case the user goes back and comes here again
              setState((prevState) => ({
                ...prevState,
                selectedVitola: vitola,
              }));
            }
            setShowVitolaEditorModal(false);

            if (suggestions) {
              setSuggestedVitolas(suggestions);
              setShowSuggestedVitolas(true);
            }
          })
          .catch((err) => {
            console.log(err);
            showDialogAlert({
              title: 'Unable to add vitola',
              message:
                'There was a problem adding this vitola. If the problem persists, contact support through the main menu of this app.',
            });
          });
      } else {
        console.log('Invalid vitola options');
      }
    });
  };

  // const renderVitolaEditorModal = () => (
  //   <VitolaEditor
  //     open={showVitolaEditorModal}
  //     onClose={() => setShowVitolaEditorModal(false)}
  //   />
  // );

  const renderVitolaSuggestions = () => (
    <VitolaModal
      vitolas={suggestedVitolas}
      open={showSuggestedVitolas}
      onClose={() => setShowSuggestedVitolas(false)}
    />
  );

  const renderAdvancedRatingModal = () => (
    <ModalView
      open={showAdvancedRatingModal}
      onClose={() => setShowAdvancedRatingModal(false)}
      rightButtons={[
        {
          onClick: () => {
            calculateAverageRating();
            setShowAdvancedRatingModal(false);
          },
          title: 'Next',
        },
      ]}
    >
      <div style={{ padding: 20 }}>{renderAdvancedRatingEntry()}</div>
    </ModalView>
  );

  const renderSmokeLocationModal = () => (
    <VenueModal
      title="Smoke Location"
      open={showSmokeLocationModal}
      onClose={() => setShowSmokeLocationModal(false)}
      smokeOnly
      onSelect={(venue) => {
        console.log('Selected venue:');
        console.log(venue);
        setState((prevState) => ({
          ...prevState,
          selectedSmokeLocation: venue,
        }));
        setShowSmokeLocationModal(false);
        setShowExtra(false);
      }}
      listStyle={
        isMobile ? {} : { maxHeight: 'calc(100vh - 300px)', overflow: 'scroll' }
      }
    />
  );

  const renderPurchasePriceSheet = () => (
    <PurchasePriceSheet
      open={showPurchasePriceSheet}
      onClose={() => setShowPurchasePriceSheet(false)}
      price={selectedPackagePrice}
      onChangePrice={(e) => {
        e.persist();
        console.log('Price change:');
        console.log(e);
        console.log(e.target);
        console.log(e.target.value);
        if (e.target.value) {
          setState((prevState) => ({
            ...prevState,
            selectedPackagePrice: e.target.value,
          }));
        }
      }}
      packageType={selectedPackageType}
      onChangePackageType={(event, value) => {
        setState((prevState) => ({
          ...prevState,
          selectedPackageType: value,
        }));
      }}
      quantity={selectedPackageQuantity}
      onChangeQuantity={(value) => {
        setState((prevState) => ({
          ...prevState,
          selectedPackageQuantity: value,
        }));
      }}
      onReset={() => {
        // TODO Clear the package state items to their defaults
        setShowPurchasePriceSheet(false);
      }}
      onApply={() => setShowPurchasePriceSheet(false)}
    />
  );

  const renderPurchaseLocationModal = () => (
    <VenueModal
      title="Purchase Location"
      open={showPurchaseLocationModal}
      onClose={() => setShowPurchaseLocationModal(false)}
      buyOnly
      onSelect={(venue) => {
        console.log('Selected venue:');
        console.log(venue);
        setState((prevState) => ({
          ...prevState,
          selectedPurchaseLocation: venue,
        }));
        setShowPurchaseLocationModal(false);
        setShowExtra(false);
      }}
      listStyle={
        isMobile ? {} : { maxHeight: 'calc(100vh - 300px)', overflow: 'scroll' }
      }
    />
  );

  const renderDrinkPairingModal = () => (
    <DrinkPairingModal
      title="Drink Pairing"
      open={showDrinkPairingModal}
      onClose={() => setShowDrinkPairingModal(false)}
      onSelect={(drink) => {
        console.log('Selected drink:');
        console.log(drink);
        setState((prevState) => ({
          ...prevState,
          selectedDrinkPairing: drink,
        }));
        setShowDrinkPairingModal(false);
        setShowExtra(false);
      }}
      listStyle={
        isMobile ? {} : { maxHeight: 'calc(100vh - 300px)', overflow: 'scroll' }
      }
    />
  );

  const renderFlavorNotesModal = () => (
    <FlavorNotesModal
      title={'Flavor Notes'}
      open={showFlavorNotesModal}
      onClose={() => setShowFlavorNotesModal(false)}
      // TODO On selection etc
    />
  );

  const renderGiftedFromModal = () => (
    <UserModal
      title="Who was this from?"
      open={showGiftedFromModal}
      onClose={() => setShowGiftedFromModal(false)}
      onSelect={(u: User) => {
        console.log('Selected gifted from user:');
        console.log(u);
        setShowGiftedFromModal(false);
        setState((prevState) => ({
          ...prevState,
          selectedGiftedFromUser: u,
        }));
      }}
      listStyle={
        isMobile ? {} : { maxHeight: 'calc(100vh - 300px)', overflow: 'scroll' }
      }
    />
  );

  const renderTaggedUsersModal = () => (
    <UserModal
      title="Select users"
      open={showTaggedUsersModal}
      // hideAppBarShadow
      onClose={() => setShowTaggedUsersModal(false)}
      rightButtons={[{ title: 'Next', onClick: () => {} }]}
      multiSelect
      onSelect={(users) => {
        console.log('Selected tagged users:');
        console.log(users);
        setState((prevState) => ({
          ...prevState,
          selectedTaggedUsers: users, // This will always be an array
        }));
      }}
      listStyle={
        isMobile ? {} : { maxHeight: 'calc(100vh - 300px)', overflow: 'scroll' }
      }
    />
  );

  function base64ToFile(base64String, filename) {
    // Decode Base64 string
    const byteString = atob(base64String.split(',')[1]);

    // Create a Uint8Array from the decoded string
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([intArray], { type: 'image/jpg' }); // Replace 'mime/type' with the actual MIME type

    return new File([blob], filename, { type: 'image/jpg' });
  }

  const mergeSession = (updatedSession: Session) => {
    console.log('Merging session...');
    try {
      CigarStore.update((s) => {
        addOrMergeSession(updatedSession, s);
      });
    } catch (err) {
      console.error(err);
      ErrorLogger.captureException(err);
    }
  };

  // FIXME Rename this
  const delayCloseModal = () => {
    setTimeout(() => {
      saveDisabled = false;
      closeModal(false);
    }, 250);
  };

  const generateLink = async (data) => {
    const platforms: string[] = [];
    let params = {};
    try {
      console.log('Generating share link...');
      const title = `${cigar.full_name} on Boxpressd`;
      let message = cigar.name
        ? `Now smoking ${cigar.name} by ${cigar.brand}`
        : `Smoking ${cigar.brand}`;

      if (selectedSmokeLocation && selectedSmokeLocation.name) {
        message += ` at ${selectedSmokeLocation.name}`;
      }

      if (selectedPurchaseLocation && selectedPurchaseLocation.name) {
        message += ` purchased from ${selectedPurchaseLocation.name}`;
      }
      const linkData = {
        path: 'cigar',
        route: `/cigars/${hashids.encode(
          session.scan.cigar.id
        )}?sessionId=${hashids.encode(data.id)}`,
        title,
        text: message,
        image:
          selectedMedia &&
          selectedMedia.length > 0 &&
          selectedMedia[0].media_url,
      };
      console.log('linkData:');
      console.log(linkData);
      const link = await ShareIntent.generateLink(linkData);
      params = {
        title,
        message,
        cigarId: cigar && cigar.id,
        smokeLocationId: selectedSmokeLocation && selectedSmokeLocation.id,
        purchaseLocationId:
          selectedPurchaseLocation && selectedPurchaseLocation.id,
        // FIXME Do we have to wait until they resolve?
        imageUrls:
          selectedMedia && selectedMedia > 1
            ? selectedMedia.slice(1).map((m) => m.media_url)
            : [],
        linkUrl: link,
        untappdId: selectedDrinkPairing && selectedDrinkPairing.untappd_id,
        foursquareId:
          selectedSmokeLocation && selectedSmokeLocation.foursquare_id,
      };

      console.log('Social params:');
      console.log(params);

      if (autoPostFacebook) {
        // platforms.push('facebook'); // INFO We can't auto-post to a FB news feed - possibly a page at some point...
        // let url = config.appUrl;
        // if (cigar && cigar.id) {
        //   url = `${config.appUrl}/cigars/${hashids.encode(cigar.id)}`;
        // }
        // facebookUrl = `https://www.facebook.com/sharer.php?u=${link.trim()}&display=popup&redirect_uri=${url}`;
        facebookUrl = `https://www.facebook.com/sharer.php?u=${link.trim()}&display=popup`;
        console.log(facebookUrl);

        // FIXME May be able to achieve it with web intents
        // https://medium.com/@ibalajisankar/the-simplest-way-to-share-messages-on-social-network-from-web-app-e349f5701e7f
        // const intent = `intent:#Intent;package=com.facebook.katana,com.facebook.lite;action=send;S.browser_fallback_url=${facebookUrl};end;`;
      }

      if (autoPostTwitter) {
        platforms.push('twitter');
      }

      if (autoPostFoursquare) {
        platforms.push('foursquare');
      }

      if (autoPostUntappd) {
        platforms.push('untappd');
      }
      return { params, platforms };
    } catch (err) {
      console.log(err);
      return { params, platforms };
    }
  };

  const handleSubmitSuccess = async (data) => {
    console.log('Updating store');
    console.log(data);
    console.log(selectedMedia);
    // FIXME Once submitted, update the local reference to the session so upon errors, trying to save updates instead of creates
    setSubmittedId(data.id);
    // TODO Once submission uses the Saga, this will get triggered there, so no need to have it in this file.
    // try {
    //   CigarStore.update((s) => {
    //     addOrMergeSession(data, s);
    //   });
    // } catch (err) {
    //   ErrorLogger.captureException(err);
    // }

    if (!localStorage.getItem('shouldShowRatingPrompt')) {
      localStorage.setItem('shouldShowRatingPrompt', 'true');
    }

    if (selectedMedia && selectedMedia.length) {
      console.log('Media to submit:');
      console.log(JSON.stringify(selectedMedia));
      const media = [];
      selectedMedia.forEach((mediaFile) => {
        if (
          mediaFile &&
          mediaFile.media_url &&
          !mediaFile.id &&
          (!mediaFile.src ||
            mediaFile.src.indexOf('base64') !== -1 ||
            typeof mediaFile.original_file !== 'undefined')
        ) {
          console.log('Attaching media...');
          media.push({
            uuid: mediaFile.uuid,
            session_id: data.id,
            media_type: mediaFile.type,
            // FIXME This should really be handled server-side
            media_uri: mediaFile.media_url
              .replace(`https://${config.cdnUrl}`, '')
              .replace(`https://${config.altCdnUrl}`, ''),
            media_url: mediaFile.media_url,
          });
        }
      });

      Sentry.setExtra('selectedMedia', JSON.stringify(selectedMedia));
      Sentry.setExtra('media', JSON.stringify(media));
      console.log('Attached media:');
      console.log(media);

      data.media = media;
      mergeSession(data);

      if (media.length > 0) {
        axios
          .post(`${config.apiEndPoint}/sessions/${data.id}/media`, media)
          .then(async (mediaRes) => {
            console.log('Got media response');
            console.log(mediaRes);
            if (typeof props.onSave === 'function') {
              console.log('Saving 1');
              props.onSave();
            }
            // if (mediaRes.data && mediaRes.data.length) {
            //   data.media = mediaRes.data;
            //   mergeSession(data);
            // }
            // FIXME Make this whole block reusable - it's in at least 4 places here
            const { params, platforms } = await generateLink(data);
            try {
              // await SocialManager.createPost(platforms, params);
            } catch (e) {
              console.error(e);
              ErrorLogger.captureException(e);
            }
            setSubmitting(false);
            delayCloseModal();
          })
          .catch(async (err) => {
            console.log(err);
            ErrorLogger.captureException(err);
            const { params, platforms } = await generateLink(data);
            try {
              // await SocialManager.createPost(platforms, params);
            } catch (e) {
              console.error(e);
              ErrorLogger.captureException(e);
            }
            setSubmitting(false);
            delayCloseModal();
          });
      } else {
        if (typeof props.onSave === 'function') {
          console.log('Saving 2');
          props.onSave();
        }
        const { params, platforms } = await generateLink(data);
        try {
          // await SocialManager.createPost(platforms, params);
        } catch (e) {
          console.error(e);
          ErrorLogger.captureException(e);
        }
        setSubmitting(false);
        delayCloseModal();
      }
    } else {
      mergeSession(data);
      if (typeof props.onSave === 'function') {
        console.log('Saving 3');
        props.onSave();
      }
      const { params, platforms } = await generateLink(data);
      try {
        // await SocialManager.createPost(platforms, params);
      } catch (e) {
        console.error(e);
        ErrorLogger.captureException(e);
      }
      setSubmitting(false);
      delayCloseModal();
    }
  };

  const handleSubmitError = (err) => {
    console.log('Session error:');
    console.log(err);
    ErrorLogger.captureException(err);
    setSubmitting(false);
    saveDisabled = false;
    showDialogAlert({
      title: 'Unable to save smoke session',
      message:
        'An error occurred while saving your smoke session. If the problem persists, please contact Help & Support.',
      buttons: [
        {
          label: 'Get Help',
          onClick: () => {
            // ModalDialog.close();
            // FIXME Include Freshchat again
            // document.querySelector('#fc_frame').style.display = 'block';
            // if (window.fcWidget) {
            //   window.fcWidget.open({ name: 'Get Help' });
            // }
          },
        },
        {
          label: 'Dismiss',
          role: 'cancel',
        },
      ],
    });
  };

  const submitSession = () => {
    console.log('Submitting session...');
    console.log(session);
    if (!saveDisabled) {
      saveDisabled = true;

      // FIXME Use Saga for this (actions.saveSession()) once we have a way to handle the success state
      if (session.id) {
        console.log('Updating existing session...');
        axios
          .put(`${config.apiEndPoint}/sessions/${session.id}`, session)
          .then((res) => {
            handleSubmitSuccess(res.data);
          })
          .catch((err) => {
            handleSubmitError(err);
          });

        if (removeSessionMedia.length > 0) {
          console.log('Submitting media to be unlinked from session...');
          axios.delete(`${config.apiEndPoint}/sessions/${session.id}/media`, {
            data: removeSessionMedia,
          });
        }
      } else {
        console.log('Creating new session...');
        axios
          .post(`${config.apiEndPoint}/sessions`, session)
          .then((res) => {
            handleSubmitSuccess(res.data);
          })
          .catch((err) => {
            handleSubmitError(err);
          });
      }
    }
  };

  const onSave = () => {
    // FIXME With the new handling, we should get a request fairly quickly with the media URL - the only thing we need to
    //  ensure is that the signed URL to S3 finishes in the background and triggers the session to update when finished
    setSubmitting(true);
    if (!uploadingMedia || timedOut) {
      console.log('Media already uploaded...');
      // Just submit
      submitSession();
    } else {
      console.log('Media still uploading...');
      setTimeout(() => {
        if (uploadingMedia) {
          console.log('Cancelled upload...');
          // FIXME Do something else? The media failed to upload
          // setUploadingMedia(false);
          setTimedOut(true);
        }
      }, 6000);
      // Otherwise it will submit as soon as the last image is uploaded
      setNeedsSubmitted(true);
    }
  };

  const { open, scan } = props;
  let { cigar } = props;
  if (!cigar && scan) {
    cigar = scan.cigar;
  }
  let title = cigar ? cigar.full_name : 'Pending...';
  if (selectedVitola) {
    title += ` ${selectedVitola.formatted_name}`;
  }

  if (isMobile) {
    return (
      <ModalView
        open={open}
        onClose={closeModal}
        title={
          title && title.length > 25 ? `${title.substring(0, 25)}...` : title
        }
        rightButtons={[
          { title: messages[language]?.save || 'Save', onClick: onSave },
        ]}
      >
        <div>{renderSessionModalContent()}</div>
        {renderAdvancedRatingModal()}
        {renderSmokeLocationModal()}
        {renderPurchasePriceSheet()}
        {renderPurchaseLocationModal()}
        {renderDrinkPairingModal()}
        {renderFlavorNotesModal()}
        {renderGiftedFromModal()}
        {renderTaggedUsersModal()}
        {renderDurationOptions()}
        {/* {renderDurationPicker()} */}
        {renderVitolaSheet()}
        {renderVitolaSuggestions()}
        {renderMediaCropper()}
        {/* FIXME This is a hack to ensure the chips don't get covered by the bottom bar */}
        <div style={{ height: 60 }} />
        <AppBar
          position="fixed"
          color="default"
          style={{ top: 'auto', bottom: 0, zIndex: 100 }}
        >
          <Toolbar>
            <span>Post To:</span>
            {renderPostToOptions()}
          </Toolbar>
        </AppBar>
      </ModalView>
    );
  }
  return (
    <Modal
      isOpen={open}
      toggle={toggleOpen}
      style={{ maxWidth: showExtra ? 1024 : 600 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={closeModal}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <Row>
          <Col md={showExtra ? 7 : 12}>{renderSessionModalContent()}</Col>
          {showExtra && (
            <Col md={5}>
              {/* TODO Render the media here and force showExtra = true */}
              {/* {showVitolaEditorModal && ( */}
              {/*  <div> */}
              {/*    <VitolaEditor ref={(ref) => (vitolaEditor = ref)} /> */}
              {/*    <div */}
              {/*      style={{ */}
              {/*        marginTop: 10, */}
              {/*        textAlign: 'center', */}
              {/*      }} */}
              {/*    > */}
              {/*      <Button onClick={() => validateVitola()}>Add Vitola</Button> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* )} */}
              {/* {showPurchaseLocationModal && renderPurchaseLocationSearch()} */}
              {/* {showSmokeLocationModal && renderSmokeLocationSearch()} */}
              {/* {showDrinkPairingModal && renderDrinkPairingSearch()} */}
              {/* {showGiftedFromModal && renderGiftedFromSearch()} */}
              {/* {showTaggedUsersModal && ( */}
              {/*  <div> */}
              {/*    {renderTaggedUsersSearch()} */}
              {/*    <div */}
              {/*      style={{ */}
              {/*        marginTop: 10, */}
              {/*        textAlign: 'center', */}
              {/*      }} */}
              {/*    > */}
              {/*      <Button */}
              {/*        onClick={() => { */}
              {/*          // TODO How to store them locally? Notes below are rendered locally... add a callback or onChange? */}
              {/*          setShowExtra(false); */}
              {/*        }} */}
              {/*      > */}
              {/*        {'Tag Users'} */}
              {/*      </Button> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* )} */}
              {/* {showFlavorNotesModal && ( */}
              {/*  <div> */}
              {/*    {renderFlavorNotesContent()} */}
              {/*    <div */}
              {/*      style={{ */}
              {/*        marginTop: 10, */}
              {/*        textAlign: 'center', */}
              {/*      }} */}
              {/*    > */}
              {/*      <Button */}
              {/*        onClick={() => { */}
              {/*          setShowFlavorNotesChart(true); */}
              {/*          setShowFlavorNotesModal(false); */}
              {/*          setShowExtra(false); */}
              {/*        }} */}
              {/*      > */}
              {/*        {'Set Flavor Notes'} */}
              {/*      </Button> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* )} */}
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter style={{ display: 'flex' }}>
        <div style={{ float: 'left', display: 'inline-block', flex: 1 }}>
          <span>Post To:</span>
          {renderPostToOptions()}
        </div>
        <Button color="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button color="brand" onClick={onSave}>
          Save
        </Button>
      </ModalFooter>
      {/* {renderDurationPicker()} */}
      {renderPurchasePriceSheet()}
      {/* {renderMediaCropper()} */}
    </Modal>
  );
}

SessionEditor.propTypes = {
  open: PropTypes.bool,
  cigar: PropTypes.object,
  venue: PropTypes.object,
  scan: PropTypes.object,
  toggle: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default SessionEditor;
