import axios from 'axios';
import Hashids from 'hashids';

import { config } from '@/settings';
import { UserStore } from '@/stores';

const hashids = new Hashids('', 12);
const SocialManager = {
  login: (platform) => {
    const { user } = UserStore.getRawState();
    if (user && user.id) {
      const url = `${config.authEndPoint}/${platform}?path=${encodeURIComponent(
        window.location.href
      )}&userId=${hashids.encode(user.id)}`;
      window.location.href = url;
    } else {
      // TODO redirect to login? There's really no scenario this should happen since all actions kicking this off already
      //  require the user to be logged in, but this is a safe way to ensure no issues happen
    }
  },
  // TODO Include helpers to set global state to reflect their connection status for each platform?
  // params = { title, message, imageUrls, linkUrl, untappdId, foursquareId }
  createPost: async (platforms, params) => {
    try {
      console.log('Creating posts...');
      // At minimum, they need a message to attach
      if (!params.message) {
        return;
      }
      console.log('Making Axios call...');
      await axios.post(
        `${config.apiEndPoint}/social/create`,
        {
          ...params,
          userId: UserStore.getRawState().user.id,
        },
        {
          params: {
            facebook: platforms.indexOf('facebook') !== -1,
            twitter: platforms.indexOf('twitter') !== -1,
            foursquare:
              platforms.indexOf('foursquare') !== -1 &&
              typeof params.foursquareId === 'string',
            untappd:
              platforms.indexOf('untappd') !== -1 &&
              typeof params.untappdId === 'string',
          },
        }
      );
    } catch (e) {
      // This failing shouldn't affect the user's experience saving the session - may want to let them know it didn't post
      // to the social platform
      console.error(e);
    }
  },
};

export default SocialManager;
