import '@ionic/react/css/core.css';

import { IonContent, IonPage } from '@ionic/react';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { isMobile } from 'mobile-device-detect';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Freshchat from '@/components/Freshchat';
import Lightbox from '@/components/Lightbox';
import SearchModal from '@/components/SearchModal';
import UploadStatus from '@/components/UploadStatus';
import Routes from '@/Routes';
import ErrorBoundary from '@/templates/error-boundary';
import PageNavbar from '@/templates/page-navbar';
import PageTabs from '@/templates/page-tabs';

const PageWrap = () => {
  const darkMode = false; // FIXME From settings
  const { pathname } = useLocation();

  const mainTheme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#efefef',
      },
      secondary: {
        main: '#D3A966',
      },
    },
  });

  return (
    <ErrorBoundary>
      <ThemeProvider theme={mainTheme}>
        <IonPage id="main-content">
          <PageNavbar />
          <IonContent className="ion-padding">
            <Routes />
          </IonContent>
          <Freshchat />
          <SearchModal />
          <Lightbox />
          <UploadStatus />
          {isMobile &&
            (pathname === '/' ||
              pathname === '/journal' ||
              pathname === '/settings') && <PageTabs />}
        </IonPage>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default PageWrap;
