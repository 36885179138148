const env = process.env.NODE_ENV || 'development';
export const config = require('@/config/config.json')[env];
export const apiKeys = require('@/config/apiKeys.json')[env];

const settings = {
  night_mode: false,
  spotlight_mode: false,
  show_section_lines: true,
  sidebar_dark: false,
  sidebar_static: false,
  sidebar_small: false,
  sidebar_effect: 'shrink',
  nav: true,
  nav_dark: false,
  nav_logo_path: require('@/assets/images/logo.png'),
  nav_logo_white_path: require('@/assets/images/logo.png'),
  nav_logo_width: '45px',
  nav_logo_url: '/',
  nav_align: 'left',
  nav_expand: 'lg',
  nav_sticky: true,
  nav_autohide: true,
  nav_container_fluid: true,
  home_url: '/',
  img_country: {
    usa: require('@/assets/images/flags/united-states-of-america.svg'),
    china: require('@/assets/images/flags/china.svg'),
    germany: require('@/assets/images/flags/germany.svg'),
    japan: require('@/assets/images/flags/japan.svg'),
    spain: require('@/assets/images/flags/spain.svg'),
    france: require('@/assets/images/flags/france.svg'),
    canada: require('@/assets/images/flags/canada.svg'),
    netherlands: require('@/assets/images/flags/netherlands.svg'),
    italy: require('@/assets/images/flags/italy.svg'),
    russia: require('@/assets/images/flags/russia.svg'),
    czech_republic: require('@/assets/images/flags/czech-republic.svg'),
  },
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
  $_GET[name] = value;
});

Object.keys($_GET).forEach((name) => {
  const isTrue = $_GET[name] === '1';

  switch (name) {
    case 'setting-night-mode':
      settings.night_mode = isTrue;
      break;
    case 'setting-show-section-lines':
      settings.show_section_lines = isTrue;
      break;
    case 'setting-sidebar-small':
      settings.sidebar_small = isTrue;
      break;
    case 'setting-sidebar-dark':
      settings.sidebar_dark = isTrue;
      break;
    case 'setting-nav-dark':
      settings.nav_dark = isTrue;
      break;
    // no default
  }
});

export default settings;
