import { useIonLoading } from '@ionic/react';
import { Avatar } from '@mui/material';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';
import Hashids from 'hashids';
import { isIOS, isMobile } from 'mobile-device-detect';
import React, { useEffect, useRef, useState } from 'react';
import { Camera } from 'react-camera-pro';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import ActionSheet from '@/components/ActionSheet';
import BottomSheet from '@/components/BottomSheet';
import FileUploader from '@/components/FileUploader';
import HumidorEntryEditor from '@/components/HumidorEntryEditor';
import Icon from '@/components/Icon';
import useDialogAlert from '@/components/ModalDialog';
import SessionEditor from '@/components/SmokeSessionEditor';
import Cigar from '@/models/Cigar';
import { config } from '@/settings';
import { UserStore } from '@/stores';
import ErrorLogger from '@/utils/errorLogger';
import { useUploadStatus } from '@/utils/hooks/useUploadStatus';
import { base64ToBlob, uploadToS3 } from '@/utils/imageUtils';

const hashids = new Hashids('', 12);

let currentCameraIndex = 0;

const hasBandImage = (cigar) => {
  if (cigar) {
    if (cigar.images && cigar.images.length) {
      for (let i = 0; i < cigar.images.length; i++) {
        const image = cigar.images[i];
        if (image.image_type === 0 && image.image_url) {
          // 'Band' image type
          return true;
        }
      }
    }
  }
  return false;
};

const CameraModal = ({ open, closeModal }) => {
  const user = UserStore.useState((s) => s.user);
  const { showDialogAlert } = useDialogAlert();
  const { setUploadStatus } = useUploadStatus();
  const [present, dismiss] = useIonLoading();
  const [submitting, setSubmitting] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [submittedScan, setSubmittedScan] = useState(null);
  const [submittedUrl, setSubmittedUrl] = useState(null);
  const [showHumidorEntryEditor, setShowHumidorEntryEditor] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [devices, setDevices] = useState([]);
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(
    undefined
  );
  // const [flashOn, setFlashOn] = useState(false);

  const camera = useRef(null);
  const addPhoto = useRef(null);

  /*
    On iOS devices, the front facing camera is the first videoinput device in the list, and the back-facing camera is the
    second one. On Android devices, the front facing camera or cameras have the string "front" in their device.label values
    and the back facing camera or cameras have the string "back".
   */
  useEffect(() => {
    (async () => {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = allDevices.filter((i) => i.kind === 'videoinput');
      const backFacingDevices = [];
      videoDevices.forEach((device, index) => {
        if (isIOS && index !== 0) {
          backFacingDevices.push(device);
        } else if (device.label.includes('back')) {
          backFacingDevices.push(device);
        }
      });
      setDevices(backFacingDevices);
    })();
  }, []);

  function nextCamera() {
    console.log('nextCamera');
    if (devices.length === 0) return;

    currentCameraIndex = (currentCameraIndex + 1) % devices.length;
    setActiveDeviceId(devices[currentCameraIndex].deviceId);
  }

  // FIXME Clicking any of these should stop the camera - still seems to be running when the editors are open
  const actions = [
    {
      content: 'Add to Humidor',
      value: 'add',
      onClick: () => setShowHumidorEntryEditor(true),
    },
    {
      content: 'Smoke Now',
      value: 'smoke',
      onClick: () => setShowSessionModal(true),
    },
  ];

  const submitMatchRequest = (imageUrl, force) => {
    setSubmittedUrl(imageUrl);
    axios
      .post(
        `${config.apiEndPoint}/scans/v2/match`,
        {
          user_id: user.id,
          image_url: imageUrl,
        },
        {
          params: {
            force: force || undefined,
          },
        }
      )
      .then((result) => {
        setSubmitting(false);
        dismiss();
        console.log(result);
        if (result.data.length > 1) {
          setSuggestions(result.data);
          setShowSuggestions(true);
        } else {
          setSubmittedScan(result.data[0]);
          if (result.data[0].cigar_id) {
            setSubmissionMessage('What would you like to do with this cigar?');
          } else {
            setSubmissionMessage(
              "Thank you! We'll let you know as soon as we match it. What would you like to do with this cigar?"
            );
          }
          setShowActions(true);
        }
      })
      .catch((err) => {
        console.log(err);
        ErrorLogger.captureException(err);
        setSubmitting(false);
        dismiss();
        showDialogAlert({
          title: 'Unable to submit cigar details',
          message:
            'An error occurred while submitting the cigar details. If the problem persists, please contact Help & Support from the main menu.',
          buttons: [
            {
              label: 'Dismiss',
              role: 'cancel',
            },
          ],
        });
      });
  };

  const renderPhotoUploadPicker = () => (
    <FileUploader
      id="image-picker"
      accept="image/*"
      forwardedRef={addPhoto}
      files={[]}
      folder="cigars"
      filenameTemplate={() => {
        const userHash = hashids.encode(user!.id);
        return `${userHash}-${performance.now()}`;
      }}
      uploading={(isUploading) => {
        // setUploadingMedia(isUploading);
        // TODO Show progress bar while uploading?
      }}
      onUploading={async (uploadingFile, files) => {
        console.log(JSON.stringify(files));
        // TODO Show loading indicator / "matching" animation
        setSubmitting(true);
        await present({
          message: 'Checking database...',
          spinner: 'crescent',
        });
      }}
      onUploaded={(uploadedFile, files) => {
        console.log('Uploaded file:');
        console.log(uploadedFile);
        console.log('All files:');
        console.log(files);
        submitMatchRequest(uploadedFile.media_url, false);
      }}
      // TODO On error or similar? Need to hide loading indicator / "matching" animation
    />
  );

  const uploadBase64 = async (base64) => {
    const uuid = uuidv4();
    const blob = base64ToBlob(base64);
    const userHash = hashids.encode(user!.id);
    await present({
      message: 'Checking database...',
      spinner: 'crescent',
    });
    await uploadToS3(
      blob,
      'cigars',
      `${userHash}-${performance.now()}`,
      uuid,
      // Ignore this, it fires too soon - we need to wait for the upload to complete
      () => {},
      setUploadStatus
    )
      .then((res) => {
        if (res.data.media_url) {
          submitMatchRequest(res.data.media_url, false);
        } else {
          setSubmitting(false);
          dismiss();
          showDialogAlert({
            title: 'Unable to upload image',
            message:
              'An error occurred while uploading the cigar image. If the problem persists, please contact Help & Support from the main menu.',
            buttons: [
              {
                label: 'Dismiss',
                role: 'cancel',
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderSessionModal = () => {
    console.log('Rendering session modal for scan:');
    console.log(submittedScan);
    return (
      <SessionEditor
        scan={submittedScan}
        open={showSessionModal}
        toggle={() => setShowSessionModal(!showSessionModal)}
        onClose={() => {
          setSubmittedScan(null);
          setShowSessionModal(false);
          closeModal();
        }}
      />
    );
  };

  const renderHumidorEntryEditor = () => (
    <HumidorEntryEditor
      scan={submittedScan}
      open={showHumidorEntryEditor}
      toggle={() => setShowHumidorEntryEditor(!showHumidorEntryEditor)}
      onClose={() => {
        setSubmittedScan(null);
        setShowHumidorEntryEditor(false);
        closeModal();
      }}
    />
  );

  const renderActions = () => {
    if (isMobile) {
      return (
        <ActionSheet
          title={submissionMessage}
          items={actions}
          open={showActions}
          toggle={() => setShowActions(!showActions)}
        />
      );
    }
    return (
      <Modal
        isOpen={showActions}
        toggle={() => setShowActions(!showActions)}
        // Reset the cigar
        // onClosed={() => setCigar({})}
        style={{ maxWidth: 300 }}
        fade
      >
        <div className="modal-header">
          <Button
            className="close"
            color=""
            onClick={() => setShowActions(false)}
          >
            <Icon name="x" />
          </Button>
        </div>
        <ModalBody>
          <div>{submissionMessage}</div>
          <List>
            {actions.map((action) => (
              <ListItem onClick={action.onClick}>
                <ListItemText primary={action.content} />
              </ListItem>
            ))}
          </List>
        </ModalBody>
      </Modal>
    );
  };

  const renderSuggestions = () => (
    <BottomSheet
      initialBreakpoint={suggestions.length < 2 ? 0.25 : 0.5}
      breakpoints={[0, 0.25, 0.5, 1]}
      open={showSuggestions}
      toggle={() => setShowSuggestions(!showSuggestions)}
      onClose={() => setShowSuggestions(false)}
    >
      <List>
        <div style={{ margin: 16, textAlign: 'center' }}>
          {'Were you searching for one of these cigars?'}
        </div>
        {suggestions.map((scan) => (
          <ListItem
            key={scan.id}
            onClick={() => {
              setSubmittedScan(scan);
              setShowActions(true);
              setShowSuggestions(false);
            }}
            secondaryAction={<Icon name="chevron-right" />}
          >
            <ListItemAvatar style={{ marginLeft: 16 }}>
              <Avatar
                alt={scan.cigar?.full_name}
                src={
                  hasBandImage(scan.cigar)
                    ? Cigar.getBandImage(scan.cigar)
                    : null
                }
                className="avatar-contained"
              >
                <img alt="" src={Cigar.getBandImage(scan.cigar)} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={scan.cigar?.full_name} />
          </ListItem>
        ))}
        <ListItem
          onClick={() => {
            // Force submit so it can be matched manually by admins
            submitMatchRequest(submittedUrl, true);
            setShowSuggestions(false);
          }}
          secondaryAction={<Icon name="chevron-right" />}
        >
          <ListItemText primary="No, none of these. Continue..." />
        </ListItem>
      </List>
    </BottomSheet>
  );

  if (isMobile) {
    return (
      <BottomSheet open={open} showFrom="right" onClose={closeModal}>
        <Camera
          ref={camera}
          facingMode="environment"
          videoSourceDeviceId={activeDeviceId}
        />
        <div
          style={{
            position: 'absolute',
            zIndex: 1002,
            height: '100%',
            width: '100%',
          }}
        >
          <Fab
            size="small"
            color="secondary"
            aria-label="close"
            style={{
              position: 'fixed',
              top: 20,
              left: 20,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            }}
            onClick={closeModal}
          >
            <Icon name="x" style={{ color: 'white' }} />
          </Fab>
          {/* FIXME I don't think the flash can be toggled from the browser */}
          {/* <Fab */}
          {/*  size="small" */}
          {/*  color="secondary" */}
          {/*  aria-label="toggle flash" */}
          {/*  style={{ */}
          {/*    position: 'fixed', */}
          {/*    top: 20, */}
          {/*    right: 20, */}
          {/*    backgroundColor: 'rgba(0, 0, 0, 0.4)', */}
          {/*  }} */}
          {/*  onClick={() => { */}
          {/*    setFlashOn(!flashOn); */}
          {/*    // TODO Toggle flash? Is it possible? */}
          {/*    // camera.current.toggleFlash(); */}
          {/*  }} */}
          {/* > */}
          {/*  <Icon */}
          {/*    name={flashOn ? 'zap' : 'zap-off'} */}
          {/*    style={{ color: 'white' }} */}
          {/*  /> */}
          {/* </Fab> */}
          <Fab
            color="primary"
            aria-label="add"
            className="fab dark"
            style={{
              position: 'fixed',
              bottom: 20,
              left: 20,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              border: '1px white solid',
            }}
            onClick={() => addPhoto.current!.click()}
          >
            <Icon
              name="image"
              style={{ height: 20, width: 20, color: 'white' }}
            />
          </Fab>
          {/* SEE https://stackoverflow.com/questions/14387690/how-can-i-show-only-corner-borders for corners only */}
          <div
            onClick={nextCamera}
            style={{
              position: 'absolute',
              zIndex: 1001,
              top: 100,
              left: 'calc(50% - 25vw)',
              width: '50vw',
              height: '100vh',
              border: '4px white solid',
              borderRadius: '15vw',
            }}
          />
          <Fab
            color="primary"
            aria-label="add"
            className="fab dark"
            style={{
              position: 'fixed',
              bottom: 20,
              left: 'calc(50% - 28px)',
              backgroundColor: '#d5c290',
              border: '1px solid black',
              boxShadow: '0 0 0 3px #d5c290',
            }}
            onClick={() => {
              // TODO "Flash" white animation
              // INFO This is the base64 encoded image
              const image = camera.current!.takePhoto();
              console.log('Got image data!');
              console.log(image);
              uploadBase64(image);
            }}
          />
        </div>
        {/* FIXME Maybe as a "toast" above the bottom buttons? Vivino does it as a bottom sheet, which could also work */}
        {/* <div style={{ height: 150 }}> */}
        {/*  <p>For best results, align the cigar within the outline</p> */}
        {/* </div> */}
        {renderPhotoUploadPicker()}
        {renderActions()}
        {renderSuggestions()}
        {renderSessionModal()}
        {renderHumidorEntryEditor()}
      </BottomSheet>
    );
  }
  return (
    <Modal isOpen={open} style={{ maxWidth: 600 }} fade>
      <div className="modal-header">
        <Button className="close" color="" onClick={closeModal}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {/* TODO Upload form or option for webcam? */}
        {
          'Coming soon! Please use a mobile device to use this feature in the meantime.'
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button color="primary">Submit</Button>
      </ModalFooter>
      {renderPhotoUploadPicker()}
      {renderActions()}
      {renderSuggestions()}
      {renderSessionModal()}
      {renderHumidorEntryEditor()}
    </Modal>
  );
};

export default CameraModal;
