import algoliasearch from 'algoliasearch/lite';
import axios from 'axios';

import { SearchStore } from '@/stores';

const searchClient = algoliasearch(
  'AF1BA1VMD5',
  '1495b8744c3eabd369359513f8b1707e'
);

let cancelToken;
export const search = async (query, { cigars = true, products = true }) => {
  // Check if there are any previous pending requests
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  // Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();

  if (cigars) {
    SearchStore.update((s) => {
      s.cigarsLoading = true;
    });
    const index = searchClient.initIndex('pre_cigars');
    try {
      const { hits } = await index.search(query);
      console.log('Got search results:');
      console.log(hits);
      SearchStore.update((s) => {
        s.cigars = hits;
        s.cigarsLoading = false;
      });
    } catch (error) {
      console.error(error);
      SearchStore.update((s) => {
        s.cigarsLoading = false;
      });
    }
  }

  if (products) {
    const index = searchClient.initIndex('pre_products');
    try {
      const { hits } = await index.search(query);
      console.log('Got search results:');
      console.log(hits);
      SearchStore.update((s) => {
        s.products = hits;
        s.productsLoading = false;
      });
    } catch (error) {
      console.error(error);
      SearchStore.update((s) => {
        s.productsLoading = false;
      });
    }
  }
};
