import './style.scss';

import {
  IonButtons,
  IonHeader,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { AppBar, Box, createTheme, Toolbar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/styles';
import ls from 'local-storage';
import { isAndroid, isMobile } from 'mobile-device-detect';
import React, { useEffect } from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';
import Placeholder from '@/config/placeholder.config';
import PlaceholderDark from '@/config/placeholder-dark.config';
import settings, { config } from '@/settings';
import { AppStore, SettingsStore, UserStore } from '@/stores';

const darkTheme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: '#17191d',
    },
  },
});

export const backArrow = (action: (() => void) | undefined = undefined) => {
  if (isAndroid) {
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Go Back"
        onClick={() =>
          typeof action === 'function' ? action() : window.history.back()
        }
      >
        <Icon
          name="arrow-left"
          size={22}
          style={{ marginLeft: 12, height: 22, width: 22, color: '#efefef' }}
        />
      </IconButton>
    );
  }
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="Go Back"
      onClick={() =>
        typeof action === 'function' ? action() : window.history.back()
      }
    >
      <Icon
        name="chevron-left"
        size={28}
        style={{ height: 28, width: 28, color: '#efefef' }}
      />
      <span style={{ fontSize: 16 }}>Back</span>
    </IconButton>
  );
};

function PageNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const toolbar = AppStore.useState((s) => s.toolbar);
  const currentUser = UserStore.useState((s) => s.user);
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const darkNavbar = SettingsStore.useState((s) => s.darkNavbar);

  useEffect(() => {
    // logOut = logOut.bind(this);
    // renderSubmenus = renderSubmenus.bind(this);
    // renderRightMenuItems = renderRightMenuItems.bind(this);
    // $(document).on('keydown.rui-navbar', (e) => {
    //   if (mobileMenuShow && e.keyCode === 27) {
    //     setMobileMenuShow(!mobileMenuShow);
    //   }
    // });
    // return () => {
    //   $(document).off('keydown.rui-navbar');
    // };
  }, []);

  const logOut = () => {
    // signOutUser(() => {
    //   firebaseApp
    //     .auth()
    //     .signOut()
    //     .then(() => {
    //       window.location.replace(
    //         `${config.authEndPoint}?action=sign_out&path=${
    //           window.location.href.split('?')[0]
    //         }`
    //       )
    //     })
    //     .catch((err) => {
    //       console.error(err)
    //       window.location.replace(
    //         `${config.authEndPoint}?action=sign_out&path=${
    //           window.location.href.split('?')[0]
    //         }`
    //       )
    //     })
    // })
  };

  const mobileToggleStyle = () => ({
    color: isMobile || darkNavbar || darkMode ? '#c7d1db' : 'initial',
  });

  const renderMenuItems = () => (
    <>
      <li>
        <a
          href={`${config.appEndPoint}/profile`}
          className="nav-link"
          target="_blank"
        >
          <Icon name="user" />
          <span style={{ flex: 1 }}>View Your Profile</span>
          <Icon name="external-link" />
        </a>
      </li>
      <li>
        <a
          href="https://help.boxpressd.com/support/solutions/150000047760"
          target="_blank"
          className="nav-link"
        >
          <Icon name="help-circle" />
          <span>Help & Support</span>
          <span className="rui-nav-circle" />
        </a>
      </li>
      <hr />
      <li>
        <a
          id="toggle-feedback"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (window.fcWidget) {
              const fcFrame = document.getElementById('fc_frame');
              if (fcFrame) {
                fcFrame.style.display = 'block';
                window.fcWidget.open({ name: 'Feedback' });
              }
            }
          }}
          className="nav-link"
        >
          <Icon name="edit" />
          <span style={{ maxHeight: 25 }}>
            {'Leave Feedback'}
            <br />
            <p style={{ fontSize: 10 }}>
              Help us improve your experience with Boxpressd
            </p>
          </span>
          <span className="rui-nav-circle" />
        </a>
      </li>
      <hr />
      <li className="dropdown-menu-label" style={{ opacity: 0.5 }}>
        Display Options
      </li>
      <li style={{ display: 'flex', alignItems: 'center' }}>
        <label className="nav-link" style={{ flex: 1 }}>
          <Icon name="moon" style={isMobile ? mobileToggleStyle() : {}} />
          <span style={isMobile ? mobileToggleStyle() : {}}>Dark Mode</span>
          <span className="rui-dropdown-circle" />
        </label>
        <IonToggle
          color="success"
          checked={darkMode}
          onIonChange={() => {
            SettingsStore.update((s) => {
              ls('dark_mode', !darkMode);
              s.darkMode = !darkMode;
            });
          }}
          style={{ marginRight: 30 }}
        />
      </li>
      <li style={{ display: 'flex', alignItems: 'center' }}>
        <label className="nav-link" style={{ flex: 1 }}>
          <Icon name="menu" style={isMobile ? mobileToggleStyle() : {}} />
          <span style={isMobile ? mobileToggleStyle() : {}}>Dark NavBar</span>
        </label>
        <IonToggle
          color="success"
          checked={darkNavbar}
          onIonChange={() => {
            SettingsStore.update((s) => {
              s.darkNavbar = !darkNavbar;
            });
          }}
          style={{ marginRight: 30 }}
        />
      </li>
      <hr />
      <li>
        <Link to="#" className="nav-link" onClick={logOut}>
          <Icon name="log-out" />
          <span>Log Out</span>
          <span className="rui-nav-circle" />
        </Link>
      </li>
    </>
  );

  const renderRightMenuItems = () => {
    const countriesDropdown = [
      {
        name: 'USA',
        img: settings.img_country.usa,
      },
      {
        name: 'China',
        img: settings.img_country.china,
      },
      {
        name: 'Germany',
        img: settings.img_country.germany,
      },
      {
        name: 'Japan',
        img: settings.img_country.japan,
      },
      {
        name: 'Spain',
        img: settings.img_country.spain,
      },
    ];

    return (
      <>
        {/* FIXME Add this back later */}
        {/* <Dropdown tag="li" direction="up" openOnHover={!isMobile} showTriangle> */}
        {/*  <Dropdown.Toggle */}
        {/*    tag="a" */}
        {/*    href="#" */}
        {/*    className={classnames('dropdown-item', isMobile ? 'nav-link' : '')} */}
        {/*  > */}
        {/*    {isMobile ? ( */}
        {/*      <> */}
        {/*        <Icon name="bell" /> */}
        {/*        <span>Notifications</span> */}
        {/*        <span className="badge badge-circle badge-brand">3</span> */}
        {/*      </> */}
        {/*    ) : ( */}
        {/*      <span className="btn btn-custom-round"> */}
        {/*        <Icon name="bell" className="mr-0" /> */}
        {/*        <span className="badge badge-circle badge-brand">3</span> */}
        {/*      </span> */}
        {/*    )} */}
        {/*  </Dropdown.Toggle> */}
        {/*  <Dropdown.Menu */}
        {/*    tag="ul" */}
        {/*    className="nav dropdown-menu rui-navbar-dropdown-notice" */}
        {/*    modifiers={{ offset: { offset: '0,12' } }} */}
        {/*  > */}
        {/*    <li className="rui-navbar-dropdown-title mb-10"> */}
        {/*      <div className="d-flex align-items-center"> */}
        {/*        <h2 className="h4 mb-0 mr-auto">Notifications</h2> */}
        {/*        <Link to="/" className="btn btn-custom-round"> */}
        {/*          <Icon name="link2" /> */}
        {/*        </Link> */}
        {/*      </div> */}
        {/*    </li> */}
        {/*    <li> */}
        {/*      <div className="media media-success media-filled mnl-30 mnr-30"> */}
        {/*        <Link to="/profile" className="media-link"> */}
        {/*          <span className="media-img"> */}
        {/*            <img src={settings.users[4].img} alt="" /> */}
        {/*          </span> */}
        {/*          <span className="media-body"> */}
        {/*            <span className="media-title"> */}
        {/*              {settings.users[4].name} */}
        {/*            </span> */}
        {/*            <small className="media-subtitle"> */}
        {/*              Bring abundantly creature great... */}
        {/*            </small> */}
        {/*          </span> */}
        {/*        </Link> */}
        {/*        <Link to="#" className="media-icon"> */}
        {/*          <Icon name="x" /> */}
        {/*        </Link> */}
        {/*      </div> */}
        {/*    </li> */}
        {/*    <li> */}
        {/*      <div className="media media-filled mnl-30 mnr-30"> */}
        {/*        <Link to="/profile" className="media-link"> */}
        {/*          <span className="media-img">C</span> */}
        {/*          <span className="media-body"> */}
        {/*            <span className="media-title">Change Design</span> */}
        {/*            <small className="media-subtitle">Design</small> */}
        {/*          </span> */}
        {/*        </Link> */}
        {/*        <Link to="#" className="media-icon"> */}
        {/*          <Icon name="x" /> */}
        {/*        </Link> */}
        {/*      </div> */}
        {/*    </li> */}
        {/*    <li> */}
        {/*      <div className="media media-filled mnl-30 mnr-30"> */}
        {/*        <Link to="/profile" className="media-link"> */}
        {/*          <span className="media-img bg-transparent"> */}
        {/*            <img */}
        {/*              src={settings.img_file.zip} */}
        {/*              className="icon-file" */}
        {/*              alt="" */}
        {/*            /> */}
        {/*          </span> */}
        {/*          <span className="media-body"> */}
        {/*            <span className="media-title">Added banner archive</span> */}
        {/*            <small className="media-subtitle">Commerce</small> */}
        {/*          </span> */}
        {/*        </Link> */}
        {/*        <Link to="#" className="media-icon"> */}
        {/*          <Icon name="x" /> */}
        {/*        </Link> */}
        {/*      </div> */}
        {/*    </li> */}
        {/*  </Dropdown.Menu> */}
        {/* </Dropdown> */}
        {!isMobile && (
          <Box sx={{ flexGrow: 0 }}>
            <Dropdown
              tag="li"
              direction="up"
              openOnHover={false}
              showTriangle={false}
            >
              <Dropdown.Toggle
                tag="a"
                href="#"
                className="dropdown-item rui-navbar-avatar mnr-6"
              >
                <IconButton onClick={() => {}} sx={{ p: 0 }}>
                  <Avatar
                    alt={currentUser ? currentUser.full_name : ''}
                    src={
                      // eslint-disable-next-line no-nested-ternary
                      currentUser && currentUser.image_url
                        ? currentUser.image_url
                        : darkMode
                        ? PlaceholderDark.avatar
                        : Placeholder.avatar
                    }
                  >
                    <img
                      alt={currentUser ? currentUser.full_name : ''}
                      src={
                        darkMode ? PlaceholderDark.avatar : Placeholder.avatar
                      }
                      style={{ height: 40, width: 40 }}
                    />
                  </Avatar>
                </IconButton>
              </Dropdown.Toggle>
              <Dropdown.Menu
                tag="ul"
                className="nav dropdown-menu"
                style={{ width: 350, padding: 12 }}
              >
                {renderMenuItems()}
                <li>
                  <div
                    style={{
                      textAlign: 'center',
                      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                      marginTop: 14,
                      paddingTop: 14,
                    }}
                  >
                    <div className="bottom-links" style={{ fontSize: 13 }}>
                      <a href="https://boxpressd.com/legal/privacy">
                        Privacy Policy
                      </a>
                      {' · '}
                      <a href="https://boxpressd.com/legal/terms">
                        Terms of Service
                      </a>
                      {' · '}
                      <a href="https://boxpressd.com/ads">Advertising</a>
                    </div>
                    <span className="copyright" style={{ fontSize: 13 }}>
                      Boxpressd &copy; {new Date().getFullYear()}
                    </span>
                  </div>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          </Box>
        )}
      </>
    );
  };

  if (pathname === '/install') {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <IonHeader>
          {toolbar || (
            <IonToolbar color="dark">
              {window.location.pathname !== '/' &&
                window.location.pathname !== '/journal' &&
                window.location.pathname !== '/settings' && (
                  <IonButtons slot="start">{backArrow()}</IonButtons>
                )}
              <IonTitle>
                <img
                  onClick={() => navigate('/')}
                  src="https://cdn.boxpressd.com/assets/img/logo-v2.svg"
                  alt="Boxpressd Cigar App"
                  width={130}
                  height={30}
                />
              </IonTitle>
            </IonToolbar>
          )}
        </IonHeader>
      </>
    );
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static" color="primary" style={{ zIndex: 12000 }}>
        <Toolbar variant="dense">
          <Box sx={{ flex: 0 }}>
            <div style={{ width: 240 }}>
              <Link to="/">
                <img
                  className="navbar-logo"
                  src={settings.nav_logo_path}
                  alt="HumidX by Boxpressd"
                  width="125"
                />
              </Link>
            </div>
          </Box>
          <div style={{ flex: 1 }} />
          {renderRightMenuItems()}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default PageNavbar;
