import './style.scss';

import {
  IonButton,
  IonButtons,
  IonIcon,
  IonModal,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react';
import { Avatar, ListItemSecondaryAction } from '@mui/material';
import Badge from '@mui/material/Badge';
import MaterialButton from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  arrowBack,
  barcodeOutline,
  chevronBack,
  optionsOutline,
  qrCodeOutline,
} from 'ionicons/icons';
import { isIOS, isMobile } from 'mobile-device-detect';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Rating from 'react-rating';

import BarcodeScanModal from '@/components/BarcodeScanModal';
import CameraModal from '@/components/CameraModal';
import CigarEditor from '@/components/CigarEditor';
import CigarModal from '@/components/CigarModal';
import Icon from '@/components/Icon';
import useDialogAlert from '@/components/ModalDialog';
import Placeholder from '@/config/placeholder.config';
import PlaceholderDark from '@/config/placeholder-dark.config';
import Product from '@/models/Product';
import Venue from '@/models/Venue';
import { config } from '@/settings';
import { AppStore, SearchStore, SettingsStore, UserStore } from '@/stores';
import { search } from '@/utils/actions/search.actions';
import { Resize } from '@/utils/imageUtils';
import { redirectAuth } from '@/utils/redirect';

const resultTypes = [
  {
    value: 'cigars', // INFO These need to match the route
    name: 'Cigars',
  },
  {
    value: 'products',
    name: 'Products',
  },
];

// FIXME Maybe rename this - it's not a modal on desktop
const SearchModal = () => {
  const { showDialogAlert } = useDialogAlert();

  const user = UserStore.useState((s) => s.user);
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const open = AppStore.useState((s) => s.showSearchModal);
  const nextAction = AppStore.useState((s) => s.searchNextAction);
  const hideTabs = AppStore.useState((s) => s.searchHideTabs);
  const hideFilters = AppStore.useState((s) => s.searchHideFilters);
  const visibleTabs = AppStore.useState((s) => s.searchVisibleTabs);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedResults, setSelectedResults] = useState(
    visibleTabs && visibleTabs.length ? visibleTabs[0] : 'cigars'
  );

  const cigars = SearchStore.useState((s) => s.cigars);
  const venues = SearchStore.useState((s) => s.venues);
  const brands = SearchStore.useState((s) => s.brands);
  const groups = SearchStore.useState((s) => s.groups);
  const users = SearchStore.useState((s) => s.users);
  const products = SearchStore.useState((s) => s.products);
  const articles = SearchStore.useState((s) => s.articles);

  const cigarsLoading = SearchStore.useState((s) => s.cigarsLoading);
  const venuesLoading = SearchStore.useState((s) => s.venuesLoading);
  const brandsLoading = SearchStore.useState((s) => s.brandsLoading);
  const groupsLoading = SearchStore.useState((s) => s.groupsLoading);
  const usersLoading = SearchStore.useState((s) => s.usersLoading);
  const productsLoading = SearchStore.useState((s) => s.productsLoading);
  const articlesLoading = SearchStore.useState((s) => s.articlesLoading);

  const [showCigarModal, setShowCigarModal] = useState(false);
  const [showCigarEditor, setShowCigarEditor] = useState(false);
  const [selectedCigars, setSelectedCigars] = useState([]);
  const [helpMatch, setHelpMatch] = useState(false);
  const [showVenueModal, setShowVenueModal] = useState(false);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);

  const [inputHasFocus, setInputHasFocus] = React.useState(false);

  const searchHolder = {
    cigars,
    venues,
    brands,
    groups,
    users,
    products,
    articles,
    cigarsLoading,
    venuesLoading,
    brandsLoading,
    groupsLoading,
    usersLoading,
    productsLoading,
    articlesLoading,
  };

  const searchbarRef = useRef();

  useEffect(() => {
    // INFO Timeout is needed to ensure the searchbar ref is active
    setTimeout(() => {
      searchbarRef.current?.setFocus();
    }, 100);
  }, [searchbarRef, open]);

  useEffect(() => {
    if (visibleTabs && visibleTabs.length > 0) {
      setSelectedResults(visibleTabs[0]);
    }
  }, [visibleTabs]);

  const getImage = (item) => {
    const placeholderCigar = darkMode ? PlaceholderDark.band : Placeholder.band;
    const placeholderAvatar = darkMode
      ? PlaceholderDark.avatar
      : Placeholder.avatar;
    // TODO Use a helper class like the model classes for this
    // console.log(item);
    if (selectedResults === 'cigars') {
      return item.image_url
        ? Resize.size(item.image_url, {
            height: 100,
            width: 100,
            cropType: 'fit',
          })
        : null;
    }
    if (selectedResults === 'brands') {
      // return Resize.size(Brand.getImage(item), { height: 100, width: 100, cropType: 'fit' });
      return Resize.size(item.logo_image_url, {
        height: 100,
        width: 100,
        cropType: 'fit',
      });
    }
    if (selectedResults === 'products') {
      return Resize.size(Product.getImage(item), {
        height: 100,
        width: 100,
        cropType: 'crop',
      });
    }
    if (selectedResults === 'users') {
      if (item.image_url) {
        return Resize.size(item.image_url);
      }
      return placeholderAvatar.replace(
        '/placeholder/',
        '/placeholder/100x100/'
      );
    }
    if (selectedResults === 'venues') {
      return Resize.size(Venue.getImage(item), {
        height: 100,
        width: 100,
        cropType: 'crop',
      });
    }
    if (selectedResults === 'articles') {
      if (item.image) {
        return Resize.size(item.image, {
          height: 100,
          width: 100,
          cropType: 'crop',
        });
      }
      if (item.featured_image) {
        return Resize.size(item.featured_image.url, {
          height: 100,
          width: 100,
          cropType: 'crop',
        });
      }
    }
    return placeholderCigar.replace('/placeholder/', '/placeholder/100x100/');
  };

  const onClick = (item: any, params: any | undefined = undefined) => {
    console.log('Clicked item:');
    console.log(item);
    if (typeof nextAction === 'function') {
      console.log(nextAction);
      // @ts-ignore
      nextAction({ ...item, id: item.id || item.objectID }, params);
    }
    AppStore.update((s) => {
      s.showSearchModal = false;
      s.searchHideTabs = false;
      s.searchVisibleTabs = ['cigars', 'products'];
      s.searchNextAction = null;
    });
  };

  // FIXME Get this from the Product model
  const productTitle = (product) => {
    if (
      product.packaging &&
      product.contents &&
      product.contents.length === 1 &&
      product.contents[0].vitola
    ) {
      return `${product.name} ${product.contents[0].vitola.formatted_name} - ${product.packaging.type} of ${product.packaging.quantity}`;
    }
    if (product.packaging) {
      let qty = parseInt(product.packaging.quantity);
      if (product.packaging.type === 'Sampler') {
        if (product.contents) {
          for (let i = 0; i < product.contents.length; i++) {
            qty += product.contents[i].quantity;
          }
        }
      }
      if (qty > 0) {
        return `${product.name} - ${product.packaging.type} of ${qty}`;
      }
      return `${product.name} - ${product.packaging.type}`;
    }
    return product.name;
  };

  const resultStyle = isMobile
    ? { borderBottom: `1px solid ${darkMode ? '#2b2b2b' : '#dfdfdf'}` }
    : {
        margin: '10px auto',
        backgroundColor: darkMode ? '#212529' : '#ffffff',
        borderRadius: 10,
        maxWidth: 658,
        cursor: 'pointer',
      };

  const searchBarcode = (code, result) => {
    console.log('Scanned barcode:', code);
    axios
      .get(`${config.apiEndPoint}/cigars/search/barcodes?q=${code}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.length === 1) {
          // FIXME Include the vitola if available
          onClick(response.data[0].cigar);
        } else if (response.data.length > 1) {
          setSelectedCigars(response.data);
          setShowCigarModal(true);
        } else {
          // TODO Let them search to pick and link it? Upload image directly here?
          showDialogAlert({
            title: 'Unable to find cigar',
            message:
              "We're working hard to update our barcode database. If you're seeing this, feel free to submit a picture of the barcode and the name of the cigar along with the size/shape information to us at support@boxpressd.com. Thank you for your help and support!",
            buttons: [
              {
                label: 'Dismiss',
                role: 'cancel',
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderCigarRow = (item) => (
    <div style={resultStyle}>
      <ListItem key={`cigar-item-${item.id}`} onClick={() => onClick(item)}>
        <Avatar
          src={getImage(item)}
          className="avatar-contained"
          style={{ marginRight: 10 }}
          alt={item.full_name}
        >
          <img
            src={Placeholder.cigar}
            style={{ height: '100%', width: '100%' }}
          />
        </Avatar>
        <ListItemText
          primary={
            <span className="padded-multiline" style={{ marginLeft: 10 }}>
              {item.full_name}
            </span>
          }
          secondary={
            <div>
              <Rating
                initialRating={item.avg_rating ? item.avg_rating / 20 : 0}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{
                  color: '#D3A966',
                  marginBottom: 10,
                  marginLeft: 10,
                }}
              />
              <span style={{ marginLeft: 10 }}>
                {`${item.total_ratings || 0} ratings`}
              </span>
            </div>
          }
        />
        <ListItemSecondaryAction>
          <span style={{ fontSize: 22 }}>
            {item.avg_rating ? parseInt(item.avg_rating) : ''}
          </span>
        </ListItemSecondaryAction>
      </ListItem>
      {item.vitolas && item.vitolas.length > 0 && (
        <div style={{ overflowX: 'scroll', padding: 2, display: 'flex' }}>
          {item.vitolas.map((vitola) => (
            <Chip
              key={vitola.id}
              label={vitola.formatted_name}
              style={{ margin: 4 }}
              onClick={() => {
                onClick(item, { vitola });
              }}
            />
          ))}
        </div>
      )}
    </div>
  );

  const renderProductRow = (item) => (
    <div style={resultStyle}>
      <ListItem key={`product-item-${item.id}`} onClick={() => onClick(item)}>
        <Avatar
          src={getImage(item)}
          style={{ marginRight: 10 }}
          alt={productTitle(item)}
        >
          <img
            alt="no cigar"
            src={Placeholder.cigar}
            style={{ height: '100%', width: '100%' }}
          />
        </Avatar>
        <ListItemText
          style={{ paddingRight: 36 }}
          primary={
            <span className="padded-multiline" style={{ marginLeft: 10 }}>
              {productTitle(item)}
            </span>
          }
          secondary={
            <div>
              <div>
                <Rating
                  initialRating={item.avg_rating ? item.avg_rating / 20 : 0}
                  emptySymbol="far fa-star"
                  fullSymbol="fas fa-star"
                  fractions={2}
                  readonly
                  style={{
                    color: '#D3A966',
                    marginBottom: 10,
                    marginLeft: 10,
                  }}
                />
                <span style={{ marginLeft: 10 }}>
                  {`${item.total_ratings || 0} ratings`}
                </span>
              </div>
              <div style={{ marginLeft: 10, display: 'flex' }}>
                <Avatar
                  src={item.venue?.profile_image_url || Placeholder.venue}
                  style={{ height: 20, width: 20, marginRight: 6 }}
                />{' '}
                Sold by {item.venue?.name}
              </div>
            </div>
          }
        />
        <ListItemSecondaryAction>
          <MaterialButton
            variant="text"
            onClick={() => {
              alert('Coming soon!');
              // TODO Allow the user to quickly save to list or humidor
            }}
          >
            <Icon name="more-horizontal" />
          </MaterialButton>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );

  const renderLoading = () => {
    if (searchHolder[`${selectedResults}Loading`]) {
      return (
        <div style={{ textAlign: 'center' }}>{/* <LoadingIndicator /> */}</div>
      );
    }
    return null;
  };

  const renderNoResults = () => {
    if (!searchHolder[selectedResults]) {
      if (selectedResults === 'cigars') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (user) {
                  setHelpMatch(true);
                } else {
                  redirectAuth(window.location.href); // TODO Include action in query params to show the cigar modal?
                }
              }}
            >
              <span style={{ marginLeft: 8 }}>
                {'Not sure what your cigar is?'}
                {isMobile ? (
                  <button
                    style={{ marginLeft: 10 }}
                    className="btn btn-outline-brand"
                  >
                    {'Add Image'}
                  </button>
                ) : (
                  <span style={{ color: '#d6c290', marginLeft: 10 }}>
                    {'Click here to match'}
                  </span>
                )}
              </span>
              {/* TODO When we allow band and barcode scanning, add these in */}
              {/* <div> */}
              {/*  <button style={{ marginLeft: 10 }} className="btn btn-outline-grey"><Icon name={['fas', 'barcode']} vendor="fa" style={{ marginRight: 8 }} />Scan Barcode</button> */}
              {/*  <button style={{ marginLeft: 10 }} className="btn btn-outline-grey"><Icon name="image" style={{ marginRight: 8 }} />Search By Band Image</button> */}
              {/* </div> */}
            </div>
          </div>
        );
      }
      if (selectedResults === 'products') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>Search products by name, SKU, or barcode</div>
          </div>
        );
      }
    }
    if (
      searchHolder[selectedResults] &&
      !searchHolder[selectedResults].length
    ) {
      if (selectedResults === 'cigars') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>No cigars found</div>
          </div>
        );
      }
      if (selectedResults === 'products') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>No products found</div>
          </div>
        );
      }
    }
    return null;
  };

  const renderNotFoundRow = () => {
    if (selectedResults === 'cigars') {
      return (
        <ListItem
          key="cigar-item-not-found"
          onClick={() => {
            if (user) {
              setShowCigarEditor(true);
            } else {
              redirectAuth(window.location.href); // TODO Include action in query params to show the cigar modal?
            }
          }}
        >
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              padding: 12,
              cursor: 'pointer',
            }}
          >
            <span>
              {"Can't find your cigar?"}
              {isMobile ? (
                <button
                  style={{ marginLeft: 10 }}
                  className="btn btn-outline-brand"
                >
                  Add New Cigar
                </button>
              ) : (
                <span style={{ color: '#d6c290', marginLeft: 8 }}>
                  Click here to add
                </span>
              )}
            </span>
          </div>
        </ListItem>
      );
    }
    return null;
  };

  const renderList = () => (
    <List
      style={{
        flex: 1,
        overflowY: 'scroll',
        // paddingBottom: isIOS ? 60 : 'inherit',
        // height: isMobile ? 'auto' : (props.height || 'calc(100vh - 128px)'),
      }}
    >
      <div>
        {renderLoading()}
        {!searchHolder[`${selectedResults}Loading`] && renderNoResults()}
        {!searchHolder[`${selectedResults}Loading`] &&
          searchHolder[selectedResults] &&
          searchHolder[selectedResults].map((item) => {
            if (selectedResults === 'cigars') {
              return renderCigarRow(item);
            }
            if (selectedResults === 'products') {
              return renderProductRow(item);
            }
            return null;
          })}
        {searchHolder[selectedResults] && renderNotFoundRow()}
      </div>
    </List>
  );

  const renderBarcodeScanner = useCallback(
    () => (
      <BarcodeScanModal
        type={
          ['users', 'venues', 'brands'].indexOf(selectedResults) !== -1
            ? 'qr'
            : 'ean13'
        }
        open={showBarcodeScanner}
        closeModal={() => setShowBarcodeScanner(false)}
        onResult={searchBarcode}
      />
    ),
    [showBarcodeScanner]
  );

  if (isMobile) {
    return (
      <IonModal
        isOpen={open}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        onDidDismiss={() => {
          AppStore.update((s) => {
            s.showSearchModal = false;
            s.searchHideTabs = false;
            s.searchVisibleTabs = ['cigars', 'products'];
            s.searchNextAction = null;
          });
        }}
      >
        <IonToolbar style={{ marginTop: 24 }} className="mobile-search-toolbar">
          <IonButtons slot="secondary">
            <IonButton
              onClick={() => {
                AppStore.update((s) => {
                  s.showSearchModal = false;
                });
              }}
            >
              <IonIcon
                slot="icon-only"
                icon={isIOS ? chevronBack : arrowBack}
                // FIXME Get this working - I actually may keep a dark navbar for both modes
                // color={darkMode ? 'dark' : 'light'}
                color={'dark'}
              />
            </IonButton>
          </IonButtons>
          <IonSearchbar
            mode="ios"
            // @ts-ignore
            ref={searchbarRef}
            slot="secondary"
            placeholder={'Search...'}
            debounce={250}
            onIonInput={(e) => {
              // FIXME Whether or not to search the index depends on props
              search(e.target.value, {
                cigars: true,
                venues: true,
                brands: true,
                products: true,
                users: true,
                groups: true,
                articles: true,
              });
            }}
            // FIXME Since it has focus by default, this doesn't make for good UX
            // onFocus={() => setInputHasFocus(true)}
            // onBlur={() => setInputHasFocus(false)}
          />

          {!inputHasFocus && (
            <IonButtons slot="primary">
              {(selectedResults === 'cigars' ||
                selectedResults === 'products') && (
                <IonButton onClick={() => setShowBarcodeScanner(true)}>
                  <IonIcon icon={barcodeOutline} color="dark"></IonIcon>
                </IonButton>
              )}
              {['users', 'venues', 'brands'].indexOf(selectedResults) !==
                -1 && (
                <IonButton onClick={() => setShowBarcodeScanner(true)}>
                  <IonIcon icon={qrCodeOutline} color="dark"></IonIcon>
                </IonButton>
              )}
              {!hideFilters && selectedResults === 'products' && (
                <IonButton
                  onClick={() => {
                    // TODO Filter
                    alert('Coming soon!');
                  }}
                >
                  <IonIcon icon={optionsOutline} color="dark"></IonIcon>
                </IonButton>
              )}
            </IonButtons>
          )}
        </IonToolbar>
        {/* FIXME Add this back - it's just for cigars and products here, no other types but still need it */}
        {/* {!hideTabs && ( */}
        {/*  <SearchTabs */}
        {/*    value={activeTab} */}
        {/*    onClick={(type, index) => { */}
        {/*      setSelectedResults(type.value); */}
        {/*      setActiveTab(index); */}
        {/*    }} */}
        {/*  /> */}
        {/* )} */}
        {renderList()}
        <CigarEditor
          open={showCigarEditor}
          onClose={() => setShowCigarEditor(false)}
        />
        <CigarModal
          title="Select Cigar"
          open={showCigarModal}
          onClose={() => setShowCigarModal(false)}
          cigarProducts={selectedCigars}
          // TODO How to know if the next action will be to add to humidor? That's the only time this will be true
          // allowAddAll={}
          onSelect={(item) => {
            setShowCigarModal(false);
            // FIXME Include the vitola, if available
            onClick(item.cigar);
          }}
        />
        <CameraModal open={helpMatch} closeModal={() => setHelpMatch(false)} />
        {renderBarcodeScanner()}
      </IonModal>
    );
  }
  return (
    <div className="search-results-container">
      <div style={{ display: 'flex' }}>
        {/* FIXME Why is the maxWidth here required? It extends too far otherwise */}
        <div style={{ flex: 1, maxWidth: 'calc(100vw - 560px)' }}>
          {renderList()}
        </div>
        {/* FIXME Why doesn't "sticky" work on any of these pages in the main container? */}
        {!hideTabs && (
          <div
            style={{
              width: 280,
              position: 'sticky',
              top: 0,
              borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
            }}
          >
            <List className="transparent-background">
              {resultTypes.map((type, index) => (
                <ListItem
                  key={`search-tab-${type.value}`}
                  selected={selectedResults === type.value}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedResults(type.value);
                    setActiveTab(index);
                  }}
                >
                  <ListItemText primary={type.name} />
                  <ListItemSecondaryAction>
                    <Badge
                      style={{ marginRight: 10 }}
                      badgeContent={
                        searchHolder[type.value] &&
                        searchHolder[type.value].length
                      }
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </div>

      <CigarEditor
        // cigar={selectedCigar}
        open={showCigarModal}
        helpMatch={helpMatch}
        toggle={() => setShowCigarModal(!showCigarModal)}
        onClose={() => setShowCigarModal(false)}
      />

      <BarcodeScanModal
        open={showBarcodeScanner}
        closeModal={() => setShowBarcodeScanner(false)}
        onResult={searchBarcode}
      />
    </div>
  );
};

export default SearchModal;
