import { useIonAlert } from '@ionic/react';
import { isMobile } from 'mobile-device-detect';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Custom hook
const useDialogAlert = () => {
  const [presentAlert] = useIonAlert();
  const MySwal = withReactContent(Swal);

  const showDialogAlert = (options) => {
    if (isMobile) {
      // For mobile devices
      presentAlert({
        header: options.title,
        subHeader: options.subtitle,
        message: options.message,
        buttons: (options.buttons || []).map((button) => ({
          text: button.label,
          role: button.role || 'confirm', // cancel, confirm, destructive
          handler: () => {
            if (button.onClick) {
              button.onClick();
            }
          },
        })),
      });
    } else {
      MySwal.fire({
        title: options.title,
        html: options.message ? `<p>${options.message}</p>` : undefined,
        // confirmButtonText
        // denyButtonText
        // cancelButtonText
        // footer: TODO If custom buttons?
      });
    }
  };

  return { showDialogAlert };
};

export default useDialogAlert;
