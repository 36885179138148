import '@/assets/scss/bootstrap-custom.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles.css';

import { setupIonicReact } from '@ionic/react';
import { Logtail } from '@logtail/browser';
import * as Sentry from '@sentry/react';
import { isMobile } from 'mobile-device-detect';
import React from 'react';
import ReactDOM from 'react-dom/client';

// FIXME Can't tell, but this started causing problems that I'm only seeing on my latest Samsung device
// import { ToastContainer } from 'react-toastify';
import packageJson from '../package.json';
import App from './App';

const logtail = new Logtail('CCG8QNq477BsKgxuLyEvZhbA');

if (process.env.NODE_ENV === 'production') {
  console.log = logtail.log.bind(logtail);
  console.error = logtail.error.bind(logtail);
  console.warn = logtail.warn.bind(logtail);
  console.debug = logtail.debug.bind(logtail);
}

if (!isMobile) {
  // @ts-ignore
  import('react-toastify/dist/ReactToastify.css');
}

setupIonicReact();

Sentry.init({
  dsn: 'https://56b76ad349b7be60d9971b5c66b480a1@o351878.ingest.us.sentry.io/4507250319163392',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: packageJson.version,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
    {/* {!isMobile && <ToastContainer />} */}
  </React.StrictMode>
);
