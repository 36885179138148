import './App.css';

import { IonApp } from '@ionic/react';
import { createTheme, ThemeProvider } from '@mui/material';
import axios from 'axios';
import ls from 'local-storage';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import CacheBuster from 'react-cache-buster';
import { BrowserRouter as Router } from 'react-router-dom';

import firebaseApp from '@/config/firebase.config';
import { SettingsStore, UserStore } from '@/stores';
import PageWrap from '@/templates/page-wrap';
import { initAxiosInterceptor, initAxiosRetry } from '@/utils/axiosAuthHelper';
import { UploadProvider } from '@/utils/hooks/useUploadStatus';

import packageJson from '../package.json';

const env = process.env.NODE_ENV || 'development';

const App: FC = () => {
  const darkMode = SettingsStore.useState((s) => s.darkMode);

  const mainTheme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#efefef',
      },
      secondary: {
        main: '#D3A966',
      },
    },
  });

  const initializeFirebaseAuthListener = () => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('User is logged in!');
        UserStore.update((s) => {
          s.firebaseUser = user;
        });
        firebaseApp
          .auth()
          .currentUser?.getIdToken()
          .then((tokenId) => {
            console.log('Got new token ID from App.jsx:');
            console.log(tokenId);
            ls('boxpressd_token_id', tokenId);
            axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log('User is logged out!');
        // FIXME This doesn't account for users that never actually signed in
        // this.props.signOutUser(() => {
        //   window.location.replace(`${config.authEndPoint}/login?path=${window.location.href.split('?')[0]}&expired=true`);
        // });
      }
    });
  };

  const initBranch = () => {
    // FIXME See Boxpressd Shop code?
    // if (branch) {
    //   branch.init(config.branch.api_key);
    // }
  };

  useEffect(() => {
    initializeFirebaseAuthListener();
    initAxiosInterceptor();
    initAxiosRetry();
    initBranch();
  }, []);

  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={env !== 'dev'}
      isVerboseMode={false}
    >
      <UploadProvider>
        <Router>
          <ThemeProvider theme={mainTheme}>
            <IonApp>
              <PageWrap />
            </IonApp>
          </ThemeProvider>
        </Router>
      </UploadProvider>
    </CacheBuster>
  );
};

export default App;
