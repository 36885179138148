import './style.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Chip from '@mui/material/Chip';
import React, { useCallback, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Col, Container, Row } from 'reactstrap';

import Icon from '@/components/Icon';
import VideoSource from '@/components/VideoSource';
import Placeholder from '@/config/placeholder.config';
import PlaceholderDark from '@/config/placeholder-dark.config';
import { MediaStore, SettingsStore } from '@/stores';
import { openLightbox } from '@/utils/lightboxHelper';

// FIXME If withEditor flag is true, also allow drag and drop for ordering - https://github.com/atlassian/react-beautiful-dnd
function MediaGrid(props) {
  const mediaMap = MediaStore.useState((s) => s.mediaMap);
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const [media, setMedia] = useState([]);
  const [thumbnailMedia, setThumbnailMedia] = useState([]);
  const [mediaToShow, setMediaToShow] = useState([]);
  const [countFrom, setCountFrom] = useState(5);

  const cigarPlaceholder = darkMode ? PlaceholderDark.cigar : Placeholder.cigar;

  const openLightboxOnSlide = (number: number, type: string) => {
    console.log('openLightboxOnSlide', media, number, type);
    openLightbox(media, number, type);
  };

  useEffect(() => {
    // console.log('Media thumbnails:');
    // console.log(props.thumbnailMedia);
    setThumbnailMedia(props.thumbnailMedia);
  }, [props.thumbnailMedia]);

  useEffect(() => {
    // console.log('Media grid items:');
    // console.log(props.media);

    if (Array.isArray(props.media)) {
      setMedia(props.media);
      setMediaToShow([...props.media]);
    }

    if (countFrom && props.media.length > countFrom) {
      mediaToShow.length = countFrom;
    }
  }, [props.media]);

  useEffect(() => {
    if (props.countFrom) {
      if (props.countFrom <= 0 || props.countFrom > 5) {
        console.warn('countFrom is limited to 5!');
        setCountFrom(5);
      } else {
        setCountFrom(props.countFrom);
      }
    }
  }, [props.countFrom]);

  const getImageSrc = (img) => img && img.src;
  const getVideoSrc = (video) => {
    if (video) {
      const localVideo = mediaMap[video.src];
      if (localVideo) {
        // console.log('Has local video:');
        // console.log(localVideo);
        // TODO Need to test that the video is still there - they may have deleted it for some reason...
        //  I really don't think we want a big map of Base64 versions of the video, that'd be way too much data cached
        //  so these need to be URIs... for now, the base64 is all we have to work with so I guess just ensure they don't
        //  persist. They will clear when the app is closed
        return localVideo;
      }
      return video.src;
    }
    return null;
  };

  const renderInnerContent = (source, placeholder, alt = '') => {
    // console.log('Source:');
    // console.log(source);
    if (source) {
      if (source.type === 'video') {
        // FIXME Consider just showing the image thumbnail and open with lightbox only - don't render the video
        return (
          <VideoSource
            videoSrc={getVideoSrc(source)}
            scaleWidth="100%"
            scaleHeight="100%"
            objectFit="cover"
            onClick={() => {
              // TODO Show fullscreen rather than play in place
            }}
          />
        );
      }
      const imgSrc = getImageSrc(source);
      if (imgSrc && imgSrc.indexOf('base64') !== -1) {
        return (
          <div
            style={{
              backgroundImage: `url(${imgSrc})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            className="main-img"
          />
        );
      }
      return (
        <LazyLoadImage
          className="main-img"
          src={imgSrc}
          alt={alt}
          effect="blur"
        />
      );
    }
    return null;
  };

  const blockStyle = (source, heightPercentage) => {
    if (source) {
      if (source.type === 'video') {
        return { paddingTop: 0, overflow: 'hidden' };
      }
      return { paddingTop: `${heightPercentage}%`, overflow: 'hidden' };
    }
    return {};
  };

  const onImageClick = (m) => {
    if (m.type === 'image' && typeof props.onImageClick === 'function') {
      props.onImageClick(m);
    }
    if (props.fancyBox) {
      openLightboxOnSlide(
        media.map((file) => file.src).indexOf(m.src) + 1,
        m.type || 'image'
      );
    }
  };

  const renderOverlay = useCallback(
    (index, showContainer = true) => {
      const { hideOverlay, overlayBackgroundColor, withEditor } = props;
      // console.log('Render Overlay Index: ', index);
      // console.log(media);
      const file = media[index];
      // console.log('File: ');
      // console.log(file);
      if (file) {
        return (
          <div>
            {withEditor && (
              <span
                style={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                }}
                onClick={() => {
                  if (typeof props.onChange === 'function') {
                    // console.log(`Editing ${file}`);
                    props.onEdit(index, file);
                  }
                }}
              >
                <Chip
                  label={<span>Edit</span>}
                  style={{
                    backgroundColor: 'rgba(239, 239, 239, 0.8)',
                    color: '#2b2b2b',
                  }}
                />
              </span>
            )}
            {withEditor && (
              <Chip
                className="circle-chip"
                style={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(239, 239, 239, 0.8)',
                  color: '#2b2b2b',
                }}
                onClick={() => {
                  if (typeof props.onChange === 'function') {
                    props.onChange(media.filter((e) => e !== file));
                  }
                  if (typeof props.onRemoved === 'function') {
                    props.onRemoved(file);
                  }
                }}
                label={<Icon name="x" size={18} />}
              />
            )}
          </div>
        );
      }

      if (hideOverlay) {
        return false;
      }

      // return [
      //   <div
      //     key={`cover-text-${index}`}
      //     className="cover-text visible slide animate-text"
      //     style={{ fontSize: '100%' }}
      //   >
      //     {typeof props.renderOverlay === 'function'
      //       ? props.renderOverlay(index)
      //       : ''}
      //   </div>,
      // ];
    },
    [media]
  );

  const renderCountOverlay = (more) => {
    // console.log('Media length:', media.length);
    // console.log('Count from:', countFrom);
    const extra = media.length - (countFrom && countFrom > 5 ? 5 : countFrom);
    return (
      more && (
        <div
          key="count-sub"
          className="cover-text visible"
          style={{ fontSize: '200%' }}
        >
          <p>{`+${extra}`}</p>
        </div>
      )
    );
  };

  const renderOne = (offset = 0, heightPercentage = 50) => {
    const { lazyImages } = props;
    const overlay =
      media.length > countFrom && countFrom === 1
        ? renderCountOverlay(true)
        : renderOverlay(offset);
    const imageClasses = 'height-one';
    let videoClasses = 'video-one';

    if (offset > 0) {
      videoClasses = 'video-one-half';
    }

    return (
      <Container>
        <Row>
          <Col
            xs={12}
            md={12}
            className={
              media.length && media[offset].type === 'video'
                ? videoClasses
                : imageClasses
            }
            style={blockStyle(thumbnailMedia[offset], heightPercentage)}
            onClick={() => onImageClick(media[offset])}
          >
            {renderInnerContent(
              thumbnailMedia[offset],
              lazyImages ? lazyImages[offset] : null
            )}
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  };

  const renderTwo = (offset = 0) => {
    const { lazyImages, direction } = props;
    const imageClasses = 'height-one';
    let videoClasses = 'video-two';

    const overlay =
      media.length > countFrom && [2 + offset, 3 + offset].includes(+countFrom)
        ? renderCountOverlay(true)
        : renderOverlay(offset + 1);
    // console.log('Offset: ', offset);
    if (direction === 'horizontal' && offset > 1) {
      videoClasses = 'video-two-half';
    }

    return (
      <Container>
        <Row>
          <Col
            xs={6}
            className={
              media[offset].type === 'video' ? videoClasses : imageClasses
            }
            onClick={() => onImageClick(media[offset])}
          >
            {renderInnerContent(
              thumbnailMedia[offset],
              lazyImages ? lazyImages[offset] : null
            )}
            {renderOverlay(offset)}
          </Col>
          <Col
            xs={6}
            className={
              media[offset + 1].type === 'video' ? videoClasses : imageClasses
            }
            onClick={() => onImageClick(media[offset + 1])}
          >
            {renderInnerContent(
              thumbnailMedia[1 + offset],
              lazyImages ? lazyImages[1 + offset] : null
            )}
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  };

  const renderThree = (offset = 0) => {
    const { lazyImages } = props;
    const overlay =
      !countFrom ||
      countFrom > 5 ||
      (media.length > countFrom && [4, 5].includes(+countFrom))
        ? renderCountOverlay(true)
        : renderOverlay(offset + 2);
    const imageClasses = 'height-three';
    const videoClasses = 'video-three';

    // TODO Handle startIndex
    // FIXME xs may been to be 6 for horizontal

    return (
      <Container>
        <Row>
          <Col
            xs={4}
            md={4}
            className={
              media[offset].type === 'video' ? videoClasses : imageClasses
            }
            onClick={() => onImageClick(media[offset])}
          >
            {renderInnerContent(
              thumbnailMedia[offset],
              lazyImages ? lazyImages[offset] : null
            )}
            {renderOverlay(offset)}
          </Col>
          <Col
            xs={4}
            md={4}
            className={
              media[offset + 1].type === 'video' ? videoClasses : imageClasses
            }
            onClick={() => onImageClick(media[offset + 1])}
          >
            {renderInnerContent(
              thumbnailMedia[offset + 1],
              lazyImages ? lazyImages[offset + 1] : null
            )}
            {renderOverlay(offset + 1)}
          </Col>
          <Col
            xs={4}
            md={4}
            className={
              media[offset + 2].type === 'video' ? videoClasses : imageClasses
            }
            onClick={() => onImageClick(media[offset + 2])}
          >
            {renderInnerContent(
              thumbnailMedia[offset + 2],
              lazyImages ? lazyImages[offset + 2] : null
            )}
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  };

  const renderRemainingOverlays = () =>
    media
      .slice(countFrom + 1)
      .map((_, index) => renderOverlay(index + countFrom, false));
  const { direction } = props;

  if (!Array.isArray(props.media) || props.media.length === 0) {
    return null;
  }

  if (direction === 'horizontal') {
    return (
      <div onClick={props.onClick} style={props.style}>
        <Row style={{ margin: 0 }}>
          <Col
            style={{
              padding: 0,
              maxWidth: media.length > 1 ? '50%' : 'inherit',
            }}
          >
            {renderOne(0, mediaToShow.length === 1 ? 80 : 100)}
          </Col>
          <Col
            style={{
              padding: 0,
              maxWidth: media.length > 1 ? '50%' : 'inherit',
            }}
          >
            {mediaToShow.length >= 2 &&
              renderOne(1, mediaToShow.length === 2 ? 100 : 50)}
            {mediaToShow.length === 3 && renderOne(2, 50)}
            {mediaToShow.length >= 4 && renderTwo(2)}
            {media.length > 5 && renderRemainingOverlays()}
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div className="grid-container" onClick={props.onClick} style={props.style}>
      {[1, 3].includes(mediaToShow.length) &&
        renderOne(0, mediaToShow.length === 1 ? 80 : 50)}
      {/* FIXME withEditor mode should only allow 5 max images */}
      {([2, 4].includes(mediaToShow.length) || mediaToShow.length >= 5) &&
        renderTwo()}
      {mediaToShow.length === 3 && renderTwo(1)}
      {mediaToShow.length === 4 && renderTwo(2)}
      {mediaToShow.length > 4 && renderThree(2)}
      {media.length > 5 && renderRemainingOverlays()}
    </div>
  );
}

export default MediaGrid;
