import { Chip, lighten } from '@mui/material';
import React, { useState } from 'react';

import {
  flavorColors,
  flavorNotes,
  sectionForFlavor,
} from '@/components/FlavorNotesChart';
import ModalView from '@/components/ModalView';

const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

const FlavorNotesModal = ({
  title,
  open,
  onClose,
  initialFlavorNotes = {},
  onSelect = () => {},
}) => {
  const [selectedFlavorNotes, setSelectedFlavorNotes] =
    useState(initialFlavorNotes);

  const handleChipChange = (value) => () => {
    console.log(value);
    console.log(selectedFlavorNotes[value] ? 0 : 1);
    setSelectedFlavorNotes({
      ...selectedFlavorNotes,
      [value]: selectedFlavorNotes[value] ? 0 : 1,
    });
    console.log(selectedFlavorNotes);
  };

  return (
    <ModalView
      title={title}
      open={open}
      onClose={onClose}
      // TODO Right buttons for "Next" to pass back to the parent component
      rightButtons={[
        {
          onClick: () => {
            // @ts-ignore
            onSelect(selectedFlavorNotes);
          },
          title: 'Next',
        },
      ]}
    >
      <div style={{ padding: 16 }}>
        {Object.entries(flavorNotes).map(([key, options]) => (
          <div>
            <h5 style={{ marginTop: 20 }}>{key}</h5>
            {/* FIXME Would be cool to be able to apply the color of the section based on the selection */}
            {/* @ts-ignore */}
            {options.map((option) => (
              <Chip
                onClick={handleChipChange(option.value)}
                style={{
                  ...styles.chip,
                  backgroundColor:
                    selectedFlavorNotes[option.value] === 1
                      ? flavorColors[sectionForFlavor(option.value)]
                          .backgroundColor
                      : lighten(
                          flavorColors[sectionForFlavor(option.value)]
                            .backgroundColor,
                          0.5
                        ),
                  color:
                    selectedFlavorNotes[option.value] === 1
                      ? flavorColors[sectionForFlavor(option.value)].color
                      : '#2b2b2b',
                }}
                label={<span>{option.label}</span>}
              />
            ))}
          </div>
        ))}
      </div>
    </ModalView>
  );
};

export default FlavorNotesModal;
