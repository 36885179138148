import './style.scss';

import { IonButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import { List } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { isMobile } from 'mobile-device-detect';
import React from 'react';
import {
  Button,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import BottomSheet from '@/components/BottomSheet';
import Icon from '@/components/Icon';
import TouchSpin from '@/components/TouchSpin';
import { language, messages } from '@/utils/localeUtils';

export default function PurchasePriceSheet(props) {
  const renderButtons = () => (
    <div>
      <Button
        color="secondary"
        onClick={props.onReset}
        style={{ marginRight: 10 }}
      >
        {messages[language]?.reset || 'Reset'}
      </Button>
      <Button color="primary" onClick={props.onApply}>
        {messages[language]?.apply || 'Apply'}
      </Button>
    </div>
  );

  const renderInnerContent = () => (
    <div>
      <div>
        <InputGroup>
          {/* <InputGroupAddon addonType="prepend">$</InputGroupAddon> */}
          <Input
            autoFocus
            placeholder="Price"
            type="number"
            value={props.price}
            onChange={props.onChangePrice}
          />
        </InputGroup>
      </div>
      <div style={{ marginTop: 14 }}>
        <span>Package Type</span>
        <div>
          <ToggleButtonGroup
            value={props.packageType}
            exclusive
            onChange={(event, value) => {
              if (value === 'Single') {
                props.onChangeQuantity(1);
              } else if (value === 'Bundle') {
                props.onChangeQuantity(5);
              } else if (value === 'Box') {
                props.onChangeQuantity(20);
              } else if (value === 'Sampler') {
                props.onChangeQuantity(10);
              }
              props.onChangePackageType(event, value);
            }}
            aria-label="Package details"
          >
            <ToggleButton value="Single" aria-label="Single">
              {messages[language]?.single_packaging || 'Single'}
            </ToggleButton>
            <ToggleButton value="Bundle" aria-label="Bundle / Pack">
              {messages[language]?.bundle_packaging || 'Pack'}
            </ToggleButton>
            <ToggleButton value="Box" aria-label="Box">
              {messages[language]?.box_packaging || 'Box'}
            </ToggleButton>
            <ToggleButton value="Sampler" aria-label="Sampler">
              {messages[language]?.sampler_packaging || 'Sampler'}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      {props.packageType !== 'Single' ? (
        <div style={{ marginTop: 14 }}>
          <span>Quantity</span>
          <div>
            <TouchSpin
              max={100}
              min={1}
              step={1}
              value={props.quantity}
              onChange={props.onChangeQuantity}
            />
          </div>
        </div>
      ) : null}
    </div>
  );

  if (isMobile) {
    return (
      <BottomSheet
        className="auto-height-sheet"
        open={props.open}
        onClose={props.onClose}
      >
        <List>
          <IonToolbar mode="ios" className="modal-toolbar">
            <IonTitle>Price</IonTitle>
            <IonButtons slot="secondary">
              <IonButton onClick={props.onReset}>
                {messages[language]?.reset || 'Reset'}
              </IonButton>
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton onClick={props.onApply}>
                {messages[language]?.apply || 'Apply'}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <div className="block" style={{ padding: 24 }}>
            {renderInnerContent()}
          </div>
        </List>
      </BottomSheet>
    );
  }
  return (
    <Modal
      isOpen={props.open}
      toggle={props.onClose}
      style={{ maxWidth: 320 }}
      fade
    >
      <div className="modal-header" style={{ padding: 12 }}>
        <h3>{messages[language]?.purchase_price || 'Purchase Price'}</h3>
        <Button className="close" color="" onClick={props.onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody style={{ padding: 16 }}>{renderInnerContent()}</ModalBody>
      <ModalFooter style={{ padding: 12 }}>{renderButtons()}</ModalFooter>
    </Modal>
  );
}
