import './style.scss';

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import type { ReactNode } from 'react';
import React from 'react';

interface RightButton {
  title?: string;
  component?: ReactNode;
  onClick: () => void;
}

interface ModalViewProps {
  children: ReactNode;
  additionalHeaderItems?: ReactNode;
  title?: string;
  open: boolean;
  onClose: () => void;
  rightButtons?: RightButton[];
}

const ModalView: React.FC<ModalViewProps> = ({
  children,
  title,
  open,
  onClose,
  rightButtons = [],
  additionalHeaderItems = null,
  ...props
}) => (
  <IonModal isOpen={open} {...props}>
    <IonHeader>
      <IonToolbar mode="ios" color="light">
        <IonButtons slot="start">
          <IonButton onClick={onClose}>{'Cancel'}</IonButton>
        </IonButtons>
        <IonTitle>{title || ''}</IonTitle>
        <IonButtons slot="end">
          {rightButtons.map((button, index) => (
            <IonButton key={index} strong={true} onClick={button.onClick}>
              {button.title || button.component}
            </IonButton>
          ))}
        </IonButtons>
      </IonToolbar>
      {additionalHeaderItems}
    </IonHeader>
    <IonContent className="ion-padding">{children}</IonContent>
  </IonModal>
);

export default ModalView;
